import styled from 'styled-components/macro'

const ColorPreviewUi = styled.div`
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAwAAAAMCAIAAADZF8uwAAAAGUlEQVQYV2M4gwH+YwCGIasIUwhT25BVBADtzYNYrHvv4gAAAABJRU5ErkJggg==);
  position: relative;
  height: 18px;
  width: 18px;
  border-radius: 3px;
`

export default ColorPreviewUi
