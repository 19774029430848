import { AppProps } from '../App'
import { Dispatch, SetStateAction, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useErrorHandler } from 'react-error-boundary'
import { useRollbar } from '@rollbar/react'
import * as api from '../api/emailApi'
import * as emailActions from '../store/email/actions'
import * as managementActions from '../store/management/actions'
import { InternalError } from '../errors/InternalError'
import { NotFound } from '../errors/NotFound'
import { useDispatch } from 'react-redux'
import { FetchEmailResponse, FetchEmailTemplateResponse } from '../api/emailApi'

export interface UseFetchEmailInterface extends AppProps {
  setExitUrl: Dispatch<SetStateAction<string>>
}

const isTemplateData = (
  data: FetchEmailTemplateResponse | FetchEmailResponse,
): data is FetchEmailTemplateResponse => {
  return !!data.email
}

export const useFetchEmail = ({
  id,
  setExitUrl,
  isTemplate,
}: UseFetchEmailInterface) => {
  const { i18n } = useTranslation()
  const dispatch = useDispatch()
  const handleError = useErrorHandler()
  const rollbar = useRollbar()

  useEffect(() => {
    ;(async function fetchEmail() {
      try {
        const { data } = await api.fetchEmail(id, isTemplate)
        if (isTemplateData(data)) {
          dispatch(
            emailActions.loadEmail({
              id: data.email.id,
              content: data.email.content,
              files: data.email.files,
            }),
          )
        } else {
          dispatch(
            emailActions.loadEmail({
              id: data.mailing.id,
              content: data.mailing.currentEmail.content,
              files: data.mailing.currentEmail.files,
            }),
          )
        }
        if (data.contactFields) {
          dispatch(emailActions.loadContactFields(data.contactFields))
        }
        if (data.user) {
          i18n.changeLanguage(data.user.locale)
        }
        setExitUrl(data.exitUrl)
        dispatch(managementActions.setFetched())
      } catch (error) {
        if (error instanceof InternalError) {
          rollbar.error('Internal server error')
          alert('Something went wrong. Please contact support')
          return
          // handleError(error)
        } else if (error instanceof NotFound) {
          alert('Page not found')
          window.location.assign(process.env.REACT_APP_API_HOST)
        }
        // handleError 500 only
        // handleError(error)
      }
    })()
  }, [handleError, dispatch, id, isTemplate, i18n, setExitUrl, rollbar])
}
