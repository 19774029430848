import { request } from '../utils/request'
import { emailTypes } from '@sio/ui'
import { ContactField } from '@sio/ui/build/types/emailTypes'

interface FieldsEmailResponse {
  contactFields?: ContactField[]
  exitUrl: string
  user?: {
    locale: string
    email: string
    id: number
  }
}

export interface FetchEmailTemplateResponse extends FieldsEmailResponse {
  email: emailTypes.Email
  mailing?: never
}

export interface FetchEmailResponse extends FieldsEmailResponse {
  mailing: {
    id: number
    currentEmail: emailTypes.Email
  }
  email?: never
}

export function fetchEmail(id: number, isTemplate: boolean) {
  return isTemplate
    ? request.get<FetchEmailTemplateResponse>(`/email/template/${id}/data`)
    : request.get<FetchEmailResponse>(`/mailing/${id}/data`)
}

export function save(email: emailTypes.Email, isTemplate: boolean) {
  return request.post<emailTypes.Email>(
    isTemplate
      ? `/email/template/${email.id}/save`
      : `/mailing/${email.id}/save`,
    {
      editor_api_email_content: {
        content: JSON.stringify(email.content),
        files: email.files.map(file => file.id),
      },
    },
  )
}
