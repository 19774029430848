import React, { useState } from 'react'
import { fileTypes } from '@sio/ui'
import Option from '../../components/Option'
import FileUploadInputUi from './ui/FileUploadInputUi'
import FileUploadRemoveFileIconUi from './ui/FileUploadRemoveFileIconUi'
import FileUploadButtonUi from './ui/FileUploadButtonUi'
import FileManager from 'editor/src/components/core/FileManager'
import FileUploadUi from './ui/FileUploadUi'
import { mimeTypesVariants } from '@sio/file-manager/src/enums/mimeTypes'

type FileUploadProps = {
  label: string
  path: string
  updateFile: (value: fileTypes.File) => void
  removeFile: () => void
}

function FileUpload({ label, path, updateFile, removeFile }: FileUploadProps) {
  const [opened, setOpened] = useState(false)

  function openFileManage() {
    setOpened(true)
  }

  function closeFileManage() {
    setOpened(false)
  }

  function handleInsert(file: fileTypes.File) {
    setOpened(false)
    updateFile(file)
  }

  return (
    <Option label={label}>
      <FileUploadUi>
        <FileUploadInputUi value={path} readOnly />
        {path && (
          <FileUploadRemoveFileIconUi
            className="fa fa-times"
            onClick={removeFile}
          />
        )}
        <FileUploadButtonUi onClick={openFileManage}>
          <i style={{ fontSize: 16 }} className="fas fa-cloud-upload-alt" />
        </FileUploadButtonUi>
        {opened && (
          <FileManager
            mimeTypesGroup={mimeTypesVariants.image}
            close={closeFileManage}
            chooseFile={handleInsert}
          />
        )}
      </FileUploadUi>
    </Option>
  )
}

export default FileUpload
