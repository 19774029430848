import { useDispatch } from 'react-redux'
import { BaseEntityInterface } from '@sio/ui'
import * as emailActions from '../../../../../store/email/actions'

export function useUpdateProp<T extends BaseEntityInterface>(entity: T) {
  const dispatch = useDispatch()
  return function <K extends keyof T>(prop: K) {
    return function (value: T[K]) {
      dispatch(
        emailActions.updateEntity<T>({
          ...entity,
          [prop]: value,
        }),
      )
    }
  }
}

export function useUpdateNestedProp<T extends BaseEntityInterface>(obj: T) {
  const dispatch = useDispatch()
  return function <K1 extends keyof T>(firstLevelPropKey: K1) {
    return function <K2 extends keyof T[K1]>(secondLevelPropKey: K2) {
      return function (value: T[K1][K2]) {
        let updatedEntity = {
          ...obj,
          [firstLevelPropKey]: {
            ...obj[firstLevelPropKey],
            [secondLevelPropKey]: value,
          },
        }
        dispatch(emailActions.updateEntity<T>(updatedEntity))
      }
    }
  }
}

export function useUpdateNestedProps<T extends BaseEntityInterface>(obj: T) {
  const dispatch = useDispatch()
  return function <P extends keyof T>(prop: P) {
    return function <
      K1 extends keyof T[P],
      K2 extends keyof T[P],
      K3 extends keyof T[P],
      K4 extends keyof T[P]
    >(key1: K1, key2: K2, key3: K3, key4: K4) {
      return function (value: T[P][K1]) {
        let updatedEntity = {
          ...obj,
          [prop]: {
            ...obj[prop],
            [key1]: value,
            [key2]: value,
            [key3]: value,
            [key4]: value,
          },
        }
        dispatch(emailActions.updateEntity<T>(updatedEntity))
      }
    }
  }
}
