import Dexie from 'dexie'
import { emailsSchema, EmailsTableType } from './tables/emails'

type DexieTablesType = EmailsTableType

type DexieType<T extends any = DexieTablesType> = Dexie & T

const database = new Dexie('database') as DexieType

const schema = { ...emailsSchema }

database.version(2).stores(schema)

export default database
