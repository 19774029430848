import React, { useState } from 'react'
import { useDrag } from 'react-dnd'
import { EntityTypes, BaseEntityWithChildIds } from '@sio/ui'
import { useDispatch } from 'react-redux'
import EntityMoverUi from './ui/EntityMoverUi'
import EntityMoveButton from './ui/EntityMoveButtonUi'
import EntityMoverOverlayUi from './ui/EntityMoverOverlayUi'
import { emailSelectors, useTypedSelector } from '../../../store'
import * as emailActions from '../../../store/email/actions'

type EntityMoverProps = {
  type: keyof typeof EntityTypes
  id: string
  backgroundColor: string
  parentId: string
  position: number
}

const EntityMover = ({
  type,
  id,
  backgroundColor,
  parentId,
  position,
}: EntityMoverProps) => {
  const dispatch = useDispatch()
  const parentEntity = useTypedSelector(state =>
    emailSelectors.getEntityById(state.email, parentId),
  ) as BaseEntityWithChildIds
  const [upDownVisible, setUpDownVisible] = useState(false)
  const [, dragRef] = useDrag({
    item: { type, id },
    type,
  })

  const possibleMoveDown = position + 1 < parentEntity.childIds.length
  const possibleMoveUp = position > 0

  function showUpDownButtons(event: React.SyntheticEvent) {
    event.stopPropagation()
    setUpDownVisible(true)
  }

  function hideUpDownButtons(event: React.SyntheticEvent) {
    event.stopPropagation()
    setUpDownVisible(false)
  }

  function moveUp(event: React.SyntheticEvent) {
    event.stopPropagation()
    dispatch(emailActions.moveEntity(id, parentId, position - 1))
  }

  function moveDown(event: React.SyntheticEvent) {
    event.stopPropagation()
    dispatch(emailActions.moveEntity(id, parentId, position + 1))
  }

  return (
    <>
      <EntityMoverUi
        ref={dragRef}
        backgroundColor={backgroundColor}
        onMouseOver={showUpDownButtons}
      >
        <span className="fas fa-arrows-alt" />
      </EntityMoverUi>
      <EntityMoverOverlayUi
        visible={upDownVisible}
        onMouseLeave={hideUpDownButtons}
      >
        {possibleMoveUp && (
          <EntityMoveButton
            backgroundColor={backgroundColor}
            visible={upDownVisible}
            onClick={moveUp}
          >
            <i className="fas fa-chevron-up" />
          </EntityMoveButton>
        )}
        {possibleMoveDown && (
          <EntityMoveButton
            backgroundColor={backgroundColor}
            visible={upDownVisible}
            onClick={moveDown}
          >
            <i className="fas fa-chevron-down" />
          </EntityMoveButton>
        )}
      </EntityMoverOverlayUi>
    </>
  )
}

export default EntityMover
