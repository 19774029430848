import styled, { css } from 'styled-components/macro'

type MenuUiProps = {
  isMobile: boolean
  fixed: boolean
}

const MenuUi = styled.div<MenuUiProps>`
  ${p =>
    p.fixed
      ? css`
          position: sticky;
          top: 0;
        `
      : css`
          position: absolute;
          top: ${p.isMobile ? '-64px' : '-32px'};
          right: 50%;
          transform: translateX(50%);
        `}
  display: flex;
  justify-content: start;
  border-radius: 5px;
  background-color: rgb(20, 158, 252);
  z-index: 110;
  cursor: default;
  height: ${p => (p.isMobile ? '60px' : '30px')};
  min-width: ${p => (p.isMobile ? '250px' : '556px')};
  flex-wrap: wrap;
`

export default MenuUi
