import { File } from './reducer'

export const LOAD_FILES = 'LOAD_FILES'
export const REMOVE_FILE = 'REMOVE_FILE'
export const ADD_FILE = 'ADD_FILE'

export type LoadFilesAction = {
  type: typeof LOAD_FILES
  payload: File[]
}

export type RemoveFileAction = {
  type: typeof REMOVE_FILE
  payload: File
}

export type AddFileAction = {
  type: typeof ADD_FILE
  payload: File
}
