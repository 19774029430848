import styled from 'styled-components/macro'

const EntityMenuUi = styled.div`
  position: absolute;
  top: 5px;
  right: 0;
  z-index: 100;
  display: flex;
`

export default EntityMenuUi
