import React from 'react'
import { useDispatch } from 'react-redux'
import * as managementActions from '../../../store/management/actions'
import DesktopOverlayUi from './ui/DesktopOverlayUi'
import { managementSelectors, useTypedSelector } from '../../../store'

function ContentOverlay() {
  const dispatch = useDispatch()
  const editingEntityId = useTypedSelector(({ management }) =>
    managementSelectors.getEditingEntityId(management),
  )
  const isGlobalSettingsEditing = useTypedSelector(({ management }) =>
    managementSelectors.isGlobalSettingsEditing(management),
  )
  const isOverlayVisible = editingEntityId || isGlobalSettingsEditing

  const closeSettings = () => {
    if (isGlobalSettingsEditing) {
      dispatch(managementActions.setGlobalSettingsEditing(false))
    } else if (editingEntityId) {
      dispatch(managementActions.setEditingEntityId(null))
    }
  }

  return isOverlayVisible ? <DesktopOverlayUi onClick={closeSettings} /> : null
}

export default ContentOverlay
