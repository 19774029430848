import styled, { css } from 'styled-components/macro'
import { styleTypes } from '@sio/ui'

type TextEditorUiProps = {
  isEditing: boolean
  fontSize: number
  paddingTop: number
  paddingRight: number
  paddingBottom: number
  paddingLeft: number
  textAlign: styleTypes.TextAlign
  lineHeight?: number
  linkColor?: string
  fontFamily?: string
}

const TextEditorUi = styled.div<TextEditorUiProps>`
  word-break: break-word;
  text-align: ${p => p.textAlign};
  line-height: ${p => p.lineHeight};
  font-family: ${p => p.fontFamily};
  font-size: ${p => `${p.fontSize}px`};
  padding: ${p =>
    `${p.paddingTop}px ${p.paddingRight}px ${p.paddingBottom}px ${p.paddingLeft}px`};
  ${p =>
    p.isEditing
      ? css`
          cursor: text;
          position: relative;
          z-index: 100;
        `
      : css`
          &::before {
            position: absolute;
            content: ' ';
            z-index: 10;
            height: 100%;
            width: 100%;
            top: 0;
            left: 0;
          }
          cursor: pointer;
          z-index: 10;
        `};
  & li.disk {
    list-style-type: disc;
  }
  & li.square {
    list-style-type: square;
  }
  ${p =>
    p.linkColor &&
    css`
      // otherwise global link color will apply text-decoration color on descendants
      & a > span {
        color: ${p.linkColor};
        text-decoration: underline;
      }
      & a {
        text-decoration: none;
      }
    `}
`

export default TextEditorUi
