import React from 'react'
import {
  emailSelectors,
  useEmail,
  useManagement,
  managementSelectors,
} from '../../store'
import { Body as BodyUi, EntityTypes, BodyInterface } from '@sio/ui'
import ChildrenEntities from '../core/ChildrenEntities'
import { generateId } from '../../store/email/utils'

const Body = () => {
  const isDesktop = useManagement(managementSelectors.isDesktop)
  const rootEntity = useEmail(emailSelectors.getRootEntity) as BodyInterface
  const globalSettings = useEmail(emailSelectors.getSettings)

  if (!rootEntity) {
    return null
  }

  return (
    <BodyUi
      maxWidth={isDesktop ? globalSettings.maxWidth : '100%'}
      backgroundColor={globalSettings.backgroundColor}
      contentBackgroundColor={globalSettings.contentBackgroundColor}
    >
      <ChildrenEntities parentEntity={rootEntity} />
    </BodyUi>
  )
}

export function createBody(): BodyInterface {
  return {
    id: generateId(),
    childIds: [],
    type: EntityTypes.Body,
  }
}

export default Body
