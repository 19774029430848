import { Configuration } from 'rollbar'
import { ignoredMessages } from './constants'
import { checkIgnore } from './utils'

const rollbarConfig: Configuration = {
  enabled: process.env.NODE_ENV === 'production',
  accessToken: process.env.REACT_APP_ROLLBAR_ACCESS_TOKEN,
  hostSafeList: ['email-editor.systeme.io', 'systeme.io'],
  ignoredMessages,
  captureUncaught: true,
  captureUnhandledRejections: true,
  checkIgnore,
  payload: {
    environment: 'production',
  },
}

export default rollbarConfig
