import React from 'react'
import PopupLibraryItemUi from './ui/PopupLibraryItemUi'
import PopupLibraryItemIconUi from './ui/PopupLibraryItemIconUi'
import { LibraryElementTypes } from '../../../enums/libraryElementTypes'
import { useTranslation } from 'react-i18next'
import libraryElements from '../Sidebar/Library/libraryElements'

type PopupLibraryItemProps = {
  type: keyof typeof LibraryElementTypes
  handleClick: (event: React.SyntheticEvent) => void
}

const PopupLibraryItem = ({ type, handleClick }: PopupLibraryItemProps) => {
  const { t } = useTranslation()
  const libraryElement = libraryElements[type]

  return (
    <PopupLibraryItemUi onClick={handleClick}>
      <PopupLibraryItemIconUi className={libraryElement.className} />
      {'title' in libraryElement && t(libraryElement.title)}
    </PopupLibraryItemUi>
  )
}

export default PopupLibraryItem
