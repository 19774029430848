import styled from 'styled-components/macro'

const ColorHexUi = styled.div`
  cursor: text;
  width: 62px;
  color: var(--default-text-color);
  font-size: 14px;
  margin-left: 5px;
`

export default ColorHexUi
