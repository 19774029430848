import React from 'react'
import Option from '../Option'
import TextFieldInputUi from './ui/TextFieldInputUi'

type TextFieldProps = {
  value?: string
  update: (value: string) => void
  label: string
}

function TextField({ label, value = '', update }: TextFieldProps) {
  function setTextValue(event: React.ChangeEvent<HTMLInputElement>) {
    update(event.target.value)
  }

  return (
    <Option label={label}>
      <TextFieldInputUi value={value} onChange={setTextValue} />
    </Option>
  )
}

export default TextField
