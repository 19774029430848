import styled from 'styled-components/macro'

const MoreUi = styled.label`
  display: flex;
  align-items: center;
  cursor: pointer;
  font-weight: normal;
  color: var(--settings-text-color);
  > span {
    margin-right: 3px;
  }
`

export default MoreUi
