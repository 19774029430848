import React from 'react'
import { Section as SectionUi, EntityTypes, SectionInterface } from '@sio/ui'
import BaseEntity, { stateColor } from '../core/BaseEntity/BaseEntity'
import { generateId } from '../../store/email/utils'
import { emailSelectors, useTypedSelector } from '../../store'
import createEntityComponent from '../../utils/createEntityComponent'

type SectionProps = {
  entity: SectionInterface
  position: number
  parentId: string
}

function Section({ entity, position, parentId }: SectionProps) {
  const entities = useTypedSelector(state =>
    emailSelectors.getChildrenEntities(state.email, entity.childIds),
  )

  return (
    <BaseEntity
      parentId={parentId}
      entity={entity}
      hoverColor={stateColor.green}
      position={position}
    >
      <SectionUi
        backgroundColor={entity.backgroundColor}
        padding={entity.padding}
      >
        {entities.map((child, index) =>
          createEntityComponent(child, index, entity.id),
        )}
      </SectionUi>
    </BaseEntity>
  )
}

export const defaultProps: Partial<SectionInterface> = {
  padding: {
    paddingTop: 10,
    paddingRight: 10,
    paddingBottom: 10,
    paddingLeft: 10,
  },
}

export function createSection(): SectionInterface {
  return {
    id: generateId(),
    childIds: [],
    type: EntityTypes.Section,
    padding: {
      paddingTop: 10,
      paddingRight: 10,
      paddingBottom: 10,
      paddingLeft: 10,
    },
    backgroundColor: 'rgba(255, 255, 255, 1)',
  }
}

export default Section
