import React, { useState } from 'react'
import { EntityInterfaceWithChildIds } from '@sio/ui'
import DropBoxUi from './ui/DropBoxUi'
import useDropRef from '../../../hooks/useDropRef'
import useDropBox from './useDropBox'

const isInTopHalfOfRect = (clientX: number, clientY: number, rect: DOMRect) =>
  clientX > rect.x &&
  clientX < rect.x + rect.width &&
  clientY > rect.y &&
  clientY < rect.y + rect.height / 2

type DropBoxEntityProps = {
  children: React.ReactNode
  parentEntity: EntityInterfaceWithChildIds
  position: number
}

const DropBoxEntity = ({
  children,
  parentEntity,
  position,
}: DropBoxEntityProps) => {
  const [dropTop, setDropTop] = useState(false)
  const [dropBottom, setDropBottom] = useState(false)
  const calculatedPosition = dropTop ? position : position + 1
  const { connect, canDrop } = useDropBox(parentEntity, calculatedPosition)

  const enableDropTop = () => {
    setDropTop(true)
    setDropBottom(false)
  }

  const enableDropBottom = () => {
    setDropBottom(true)
    setDropTop(false)
  }

  const onDragOver = ({ clientX, clientY, target }: React.DragEvent) => {
    if (canDrop) {
      const node = target as HTMLElement
      const rect = node.getBoundingClientRect()
      if (isInTopHalfOfRect(clientX, clientY, rect)) {
        enableDropTop()
      } else {
        enableDropBottom()
      }
    }
  }

  const [setRef] = useDropRef(connect, onDragOver)

  return (
    <DropBoxUi
      ref={setRef}
      canDrop={canDrop}
      dropTop={dropTop}
      dropBottom={dropBottom}
    >
      {children}
    </DropBoxUi>
  )
}

export default DropBoxEntity
