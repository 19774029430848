import styled from 'styled-components/macro'

const BorderRowUi = styled.div`
  &:nth-of-type(1) {
    margin-top: 14px;
  }
  display: flex;
  justify-content: space-between;
  margin-bottom: 5px;
`

export default BorderRowUi
