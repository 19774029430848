import styled from 'styled-components/macro'

type TextAlignButtonUiProps = {
  active: boolean
}

const TextAlignButtonUi = styled.button<TextAlignButtonUiProps>`
  width: 25%;
  float: left;
  margin-bottom: 0;
  padding: 4px;
  font-size: 12px;
  text-align: center;
  cursor: pointer;
  outline: none;
  border: none;
  transition: background-color 0.2s ease;
  color: ${p => (p.active ? '#707e8d' : '#777')};
  background-color: ${p => (p.active ? '#ebeef1' : '#f9f9f9')};
  &:first-child {
    border-radius: 3px 0 0 3px;
  }
  &:last-child {
    border-radius: 0 3px 3px 0;
  }
  &:hover {
    background-color: #ebeef1;
    color: #707e8d;
  }
`

export default TextAlignButtonUi
