import React from 'react'
import { ModalCloseLinkUi, ModalCloseIconUi } from './ui/ModalCloseUi'

type ModalCloseProps = {
  handleClose: (event: React.SyntheticEvent) => void
}

const ModalClose = ({ handleClose }: ModalCloseProps) => {
  return (
    <ModalCloseLinkUi onClick={handleClose}>
      <ModalCloseIconUi>&times;</ModalCloseIconUi>
    </ModalCloseLinkUi>
  )
}

export default ModalClose
