import React from 'react'
import { managementSelectors, useTypedSelector } from '../../../store'
import Library from './Library'
import EntitySettings from './settings/EntitySettings'
import SidebarUi from './ui/SidebarUi'
import SidebarContainerUi from './ui/SidebarContainerUi'
import GlobalSettings from './settings/GlobalSettings'
import SettingsOverlayProvider from './settings/SettingsOverlayContext'
import SidebarFooterUi from './settings/ui/SidebarFooterUi'
import LanguageSwitcher from './LanguageSwitcher'
import DeviceSwitcher from './DeviceSwitcher'

type SidebarProps = {}

const Sidebar = (props: SidebarProps) => {
  const editingEntityId = useTypedSelector(({ management }) =>
    managementSelectors.getEditingEntityId(management),
  )
  const isGlobalSettingsEditing = useTypedSelector(({ management }) =>
    managementSelectors.isGlobalSettingsEditing(management),
  )

  return (
    <SidebarUi>
      <SidebarContainerUi>
        {!editingEntityId && !isGlobalSettingsEditing && <Library />}
        {editingEntityId && (
          <SettingsOverlayProvider>
            <EntitySettings entityId={editingEntityId} />
          </SettingsOverlayProvider>
        )}
        {isGlobalSettingsEditing && (
          <SettingsOverlayProvider>
            <GlobalSettings />
          </SettingsOverlayProvider>
        )}
      </SidebarContainerUi>
      <SidebarFooterUi>
        <LanguageSwitcher />
        <DeviceSwitcher />
      </SidebarFooterUi>
    </SidebarUi>
  )
}

export default Sidebar
