export enum LibraryElementTypes {
  LibraryImage = 'LibraryImage',
  LibraryText = 'LibraryText',
  LibraryBulletList = 'LibraryBulletList',
  LibraryButton = 'LibraryButton',
  LibraryHorizontalLine = 'LibraryHorizontalLine',
  LibraryLayout_12 = 'LibraryLayout_12',
  LibraryLayout_6_6 = 'LibraryLayout_6_6',
  LibraryLayout_4_4_4 = 'LibraryLayout_4_4_4',
  LibraryLayout_3_3_3_3 = 'LibraryLayout_3_3_3_3',
}

const layoutTypes = [
  LibraryElementTypes.LibraryLayout_12,
  LibraryElementTypes.LibraryLayout_6_6,
  LibraryElementTypes.LibraryLayout_4_4_4,
  LibraryElementTypes.LibraryLayout_3_3_3_3,
]

export function isLayoutType(type: LibraryElementTypes) {
  return layoutTypes.includes(type)
}
