import styled from 'styled-components/macro'

const SettingsTitleUi = styled.div`
  display: flex;
  align-items: center;
  height: 45px;
  background-color: #f7f9fa;
  box-shadow: 0 1px 1px 0 #ebedf0;
  padding: 12px 25px 13px 25px;
  font-size: 14px;
  font-weight: 500;
  text-align: left;
  color: #414355;
`

export default SettingsTitleUi
