import React from 'react'
import { useDispatch } from 'react-redux'
import Modal from '../Modal'
import { managementSelectors, useTypedSelector } from '../../../store'
import * as managementActions from '../../../store/management/actions'
import { useTranslation } from 'react-i18next'
import PopupLibraryContent from './PopupLibraryContent'

function PopupLibrary() {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const { entityId, position } = useTypedSelector(({ management }) =>
    managementSelectors.getInsertToForPopupLibrary(management),
  )
  const hideOverlay = () => {
    dispatch(managementActions.setInsertToForPopupLibrary(null))
  }

  return (
    <Modal
      header={t('core.popup_library.title')}
      handleClose={hideOverlay}
      isOpen={Boolean(entityId)}
      maxWidth={442}
      closeVisible
    >
      {entityId && (
        <PopupLibraryContent
          parentId={entityId}
          position={position}
          onAdd={hideOverlay}
        />
      )}
    </Modal>
  )
}

export default PopupLibrary
