import React from 'react'
import { useTranslation } from 'react-i18next'
import NumberUi from './ui/NumberUi'
import NumberLabelUi from './ui/NumberLabelUi'
import NumberControlButtonUi from './ui/NumberControlButtonUi'
import NumberInputUi from './ui/NumberInputUi'

type NumberFieldProps = {
  value?: number
  update: (value: number) => void
  label?: string
}

function NumberField({ value = 0, update, label }: NumberFieldProps) {
  const { t } = useTranslation()
  function setNumberValue(event: React.ChangeEvent<HTMLInputElement>) {
    update(Number(event.target.value))
  }

  const decrease = () => update(value - 1)
  const increase = () => update(value + 1)

  return (
    <NumberUi>
      <NumberLabelUi>{label && t(label)}</NumberLabelUi>
      <div>
        <NumberControlButtonUi onClick={decrease} position="left">
          -
        </NumberControlButtonUi>
        <NumberInputUi onChange={setNumberValue} value={value} />
        <NumberControlButtonUi onClick={increase} position="right">
          +
        </NumberControlButtonUi>
      </div>
    </NumberUi>
  )
}

export default NumberField
