import React from 'react'
import Option from '../../components/Option'
import Select from '../../components/Select'

type ListStyleType = {
  label?: string
  update: (value: string) => void
  value: string
}

const choices = {
  square: 'Square',
  disk: 'Disk',
  circle: 'Circle',
  decimal: 'Decimal',
}

function ListStyle({
  label = 'settings_option.list_style.type',
  update,
  value,
}: ListStyleType) {
  return (
    <Option label={label}>
      <Select choices={choices} update={update} value={value} />
    </Option>
  )
}

export default ListStyle
