import styled from 'styled-components/macro'

type ColorPreviewInnerUiProps = {
  color: string
}

const ColorPreviewInnerUi = styled.div<ColorPreviewInnerUiProps>`
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  transition: background-color 0.2s ease;
  background-color: ${p => p.color};
  box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.2);
  border-radius: 3px;
`

export default ColorPreviewInnerUi
