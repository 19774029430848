import React from 'react'
import Select, { ValueType } from 'react-select'

type FontSizeProps = {
  toggleFontSize: (fsKey: string) => void
  fontSize: any
}

const customStyles = {
  input: (styles: any) => ({ ...styles, color: '#ffffff' }),
  container: (styles: any) => ({ ...styles, width: 70 }),
  singleValue: (styles: any) => ({
    ...styles,
    color: '#ffffff',
    marginLeft: 0,

    // padding: '3px 5px',
    // borderRadius: '3px',
    // backgroundColor: 'rgba(0,0,0,0.1)',
  }),
  dropdownIndicator: (styles: any) => {
    const { padding, ...restStyles } = styles
    delete restStyles[':hover']
    return { ...restStyles, color: '#ffffff' }
  },
  indicatorSeparator: (styles: any) => ({ ...styles, display: 'none' }),
  menu: (styles: any) => {
    const { marginTop, borderRadius, ...restStyles } = styles
    return restStyles
  },
  menuList: (styles: any) => {
    const { paddingTop, paddingBottom, ...restStyles } = styles
    return restStyles
  },
  control: (styles: any) => {
    const {
      backgroundColor,
      minHeight,
      borderWidth,
      borderStyle,
      boxShadow,
      ...restStyles
    } = styles

    return { ...restStyles, cursor: 'pointer' }
  },
  option: (styles: any, state: any) => ({
    ...styles,
    color: !state.isSelected ? '#ffffff' : 'var(--default-text-color)',
    backgroundColor: state.isSelected ? '#ffffff' : 'var(--default-blue-color)',
    '&:hover': {
      backgroundColor: '#ffffff',
      color: 'var(--default-text-color)',
    },
  }),
  indicatorsContainer: (styles: any) => ({ ...styles, padding: '3px' }),
}

export const fontSizes = [
  8,
  9,
  10,
  11,
  12,
  13,
  14,
  15,
  16,
  17,
  18,
  20,
  22,
  24,
  26,
  28,
  30,
  34,
  38,
  42,
  46,
  50,
  58,
  64,
  72,
  80,
  88,
  96,
]

export const STYLE_KEY = 'FONT_SIZE_'

type Option = { label: string; value: string }

const makeOption = (value: number | string) => ({
  value: STYLE_KEY + value,
  label: `${value}px`,
})

function FontSize({ toggleFontSize, fontSize }: FontSizeProps) {
  function handleChange(option: ValueType<Option, boolean>) {
    toggleFontSize((option as Option).value)
  }

  return (
    <Select
      onChange={handleChange}
      styles={customStyles}
      options={fontSizes.map(makeOption)}
      value={makeOption(fontSize)}
    />
  )
}

export default FontSize
