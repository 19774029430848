import React from 'react'
import { useDispatch } from 'react-redux'
import Immutable from 'immutable'
import { DefaultDraftBlockRenderMap } from 'draft-js'
import { EntityTypes, TextInterface } from '@sio/ui'
import BaseEntity from '../core/BaseEntity/BaseEntity'
import { generateId } from '../../store/email/utils'
import TextEditor from '../core/TextEditor'
import {
  emailSelectors,
  managementSelectors,
  useEmail,
  useManagement,
} from '../../store'
import * as emailActions from '../../store/email/actions'

type TextProps = {
  entity: TextInterface
  position: number
  parentId: string
}

type ListProps = {
  children?: React.ReactChildren
  listStyleType: string
  markerColor: string
}

function List({ children, listStyleType, markerColor }: ListProps) {
  return (
    <ul style={{ textAlign: 'left', listStyleType, color: markerColor }}>
      {children}
    </ul>
  )
}

const Text = ({ entity, position, parentId }: TextProps) => {
  const dispatch = useDispatch()
  const isEditing = useManagement(state =>
    managementSelectors.isEditing(state, entity.id),
  )
  const linkColor = useEmail(emailSelectors.getLinkColor)

  const blockRenderMap = Immutable.Map({
    'unordered-list-item': {
      element: 'li',
      wrapper: (
        <List
          listStyleType={entity.listStyleType}
          markerColor={entity.markerColor}
        />
      ),
    },
  })

  function updateText(rawContentState: string) {
    dispatch(
      emailActions.updateEntity({
        ...entity,
        rawContentState,
      }),
    )
  }

  return (
    <BaseEntity entity={entity} position={position} parentId={parentId}>
      <TextEditor
        update={updateText}
        blockRenderMap={DefaultDraftBlockRenderMap.merge(blockRenderMap)}
        isEditing={isEditing}
        rawContentState={entity.rawContentState}
        padding={entity.padding}
        textAlign={entity.textAlign}
        lineHeight={entity.lineHeight}
        linkColor={linkColor}
        fontFamily={entity.fontFamily}
      />
    </BaseEntity>
  )
}

export function createText(): TextInterface {
  return {
    id: generateId(),
    type: EntityTypes.Text,
    rawContentState:
      '{"entityMap":{},"blocks":[{"key":"7gjsu",' +
      '"text":"Lorem ipsum dolor sit amet, consectetur adipisicing elit. Autem dolore, alias, ' +
      'numquam enim ab voluptate id quam harum ducimus cupiditate similique quisquam et deserunt, ' +
      'recusandae.","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}}]}',
    padding: {
      paddingTop: 15,
      paddingRight: 15,
      paddingBottom: 15,
      paddingLeft: 15,
    },
    fontSize: 14,
    lineHeight: 1.3,
    listStyleType: 'disc',
    markerColor: 'rgba(0, 0, 0, 1)',
    textAlign: 'center',
  }
}

export default Text
