import styled from 'styled-components/macro'

const PickerPopupUi = styled.div`
  position: absolute;
  left: -225px;
  top: -160px;
  z-index: 1;
`

export default PickerPopupUi
