import {
  ADD_FILE,
  AddFileAction,
  LOAD_FILES,
  LoadFilesAction,
  REMOVE_FILE,
  RemoveFileAction,
} from './actionTypes'
import { File } from './reducer'

export function loadFiles(files: File[]): LoadFilesAction {
  return {
    type: LOAD_FILES,
    payload: files,
  }
}

export function removeFile(file: File): RemoveFileAction {
  return {
    type: REMOVE_FILE,
    payload: file,
  }
}

export function addFile(file: File): AddFileAction {
  return {
    type: ADD_FILE,
    payload: file,
  }
}

export type FilesActions = LoadFilesAction | RemoveFileAction | AddFileAction
