import React from 'react'
import StyleControls from './StyleControls'
import { DraftInlineStyle } from 'draft-js'

const INLINE_STYLES = [
  { label: 'B', style: 'BOLD' },
  { label: 'I', style: 'ITALIC' },
  { label: 'U', style: 'UNDERLINE' },
  { label: '<strike>S</strike> ', style: 'STRIKETHROUGH' },
]

type InlineStyleControlsProps = {
  currentStyle: DraftInlineStyle
  toggleStyle: (value: any) => void
}

const InlineStyleControls = ({
  currentStyle,
  toggleStyle,
}: InlineStyleControlsProps) => (
  <StyleControls
    checkActive={style => currentStyle.has(style)}
    controls={INLINE_STYLES}
    handleClick={toggleStyle}
  />
)

export default InlineStyleControls
