import styled from 'styled-components/macro'

type MobilePhoneUiProps = {
  isVisible: boolean
}

const MobilePhoneUi = styled.div<MobilePhoneUiProps>`
  display: ${p => (p.isVisible ? 'block' : 'contents')};
  height: 660px;
  width: 375px;
  position: absolute;
  left: 50%;
  top: 20px;
  transform: translate(-50%);
  border: 10px solid #23282d;
  border-top-width: 30px;
  border-bottom-width: 50px;
  border-radius: 20px;
`

export default MobilePhoneUi
