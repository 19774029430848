import React, { useEffect, useState } from 'react'
import { fileTypes } from '@sio/ui'
import MenuLinkUi from './ui/MenuLinkUi'
import MenuInputGroupUi from './ui/MenuInputGroupUi'
import MenuLinkInputUi from './ui/MenuLinkInputUi'
import MenuConfirmButtonUi from './ui/MenuConfirmButtonUi'
import MenuButtonUi from '../ui/MenuButtonUi'
import { useTranslation } from 'react-i18next'
import MenuConfirmButtonHaveChangesUi from './ui/MenuConfirmButtonHaveChangesUi'
import FileManager from '../../../FileManager'
import MenuSelectUi from '../ui/MenuSelectUi'
import { mimeTypesVariants } from '@sio/file-manager/src/enums/mimeTypes'

export type LinkType = {
  url: string
}

type LinkProps = {
  currentLink: LinkType
  update: (link: LinkType) => void
  remove: () => void
  close: () => void
}

const schemesValues = {
  https: 'https://',
  http: 'http://',
  other: '',
}

const schemesNames = {
  https: 'https',
  http: 'http',
  other: 'other',
}

function detectScheme(url: string) {
  if (url.startsWith(schemesValues.https)) {
    return schemesValues.https
  } else if (url.startsWith(schemesValues.http)) {
    return schemesValues.http
  }

  return schemesValues.other
}

function stripScheme(url: string) {
  return url.replace(/(^\w+:|^)\/\//, '')
}

function Link({ currentLink, update, remove, close }: LinkProps) {
  const { t } = useTranslation()
  const [link, setLink] = useState(currentLink)
  const initialScheme = link.url ? detectScheme(link.url) : schemesValues.https
  const [scheme, setScheme] = useState(initialScheme)
  const [showFileManager, setShowFileManager] = useState(false)
  const isSchemeChanged = initialScheme !== scheme
  const isLinkReadyForSave = currentLink.url !== link.url || isSchemeChanged

  useEffect(() => {
    setLink(currentLink)
  }, [currentLink])

  function handleURLChange(event: React.ChangeEvent<HTMLInputElement>) {
    event.preventDefault()
    setLink({
      ...link,
      url: event.target.value,
    })
  }

  function closeOnEnterKey(event: React.KeyboardEvent<HTMLInputElement>) {
    if (event.key === 'Enter' && isLinkReadyForSave) {
      update(link)
      setTimeout(close, 500)
    }
  }

  function save() {
    update({ url: `${scheme}${stripScheme(link.url.trim())}` })
    setTimeout(close, 500)
  }

  function toggleShowFileManager() {
    setShowFileManager(!showFileManager)
  }

  function setFileToLink(file: fileTypes.File) {
    setLink({
      ...link,
      url: file.path,
    })
    setShowFileManager(false)
  }

  return (
    <MenuLinkUi>
      <MenuInputGroupUi>
        <MenuSelectUi onChange={e => setScheme(e.target.value)} value={scheme}>
          {Object.keys(schemesNames).map(schemeKey => (
            <option value={schemesValues[schemeKey]} key={schemeKey}>
              {schemeKey}
            </option>
          ))}
        </MenuSelectUi>
        <MenuLinkInputUi
          onChange={handleURLChange}
          onKeyDown={closeOnEnterKey}
          placeholder={t('core.text_editor.link_menu.placeholder')}
          value={stripScheme(link.url)}
        />
        <MenuConfirmButtonUi
          onClick={save}
          title={t('core.text_editor.link_menu.confirm')}
          disabled={!isLinkReadyForSave}
        >
          <span className="fas fa-save" />
          {isLinkReadyForSave && <MenuConfirmButtonHaveChangesUi />}
        </MenuConfirmButtonUi>
      </MenuInputGroupUi>
      <MenuButtonUi
        onClick={toggleShowFileManager}
        title={t('components.core.text_editor.link_menu.use_file_as_link')}
      >
        <span className="fa fa-download" />
      </MenuButtonUi>
      {showFileManager && (
        <FileManager
          mimeTypesGroup={mimeTypesVariants.all}
          close={toggleShowFileManager}
          chooseFile={setFileToLink}
        />
      )}
      <span style={{ color: '#fff', borderRight: '1xp solid #fff' }} />
      <MenuButtonUi
        onClick={remove}
        title={t('components.core.text_editor.link_menu.unlink')}
      >
        <span className="fas fa-unlink" />
      </MenuButtonUi>
      <MenuButtonUi
        onClick={close}
        marginLeft="auto"
        title={t('components.core.text_editor.link_menu.close_menu')}
      >
        <span className="fa fa-times" />
      </MenuButtonUi>
    </MenuLinkUi>
  )
}

export default Link
