import styled from 'styled-components/macro'

const SelectUi = styled.select`
  height: 32px;
  background-color: var(--settings-option-item-background-color);
  color: var(--default-text-color);
  border-radius: 3px;
  padding: 5px 10px;
  font-size: 14px;
  border: none;
  outline: none;

  -webkit-appearance: none;
  -moz-appearance: none;
  //background-image: url("data:image/svg+xml;utf8,<svg fill='6b7c93' height='24' viewBox='0 0 24 24' width='24' xmlns='http://www.w3.org/2000/svg'><path d='M7 10l5 5 5-5z'/><path d='M0 0h24v24H0z' fill='none'/></svg>");
  //background-repeat: no-repeat;
  //background-position-x: 100%;
  //background-position-y: 5px;
  option {
    color: black;
    background: white;
    display: flex;
    white-space: pre;
    min-height: 20px;
    padding: 0 2px 1px;
  }
`

export default SelectUi
