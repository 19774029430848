import BorderRowUi from './ui/BorderRowUi'
import NumberField from '../../components/NumberField'
import Select from '../../components/Select'
import SmallColorPicker from '../SmallColorPicker'
import React from 'react'
import { borderStyles } from './index'
import { styleTypes } from '@sio/ui'

export default function BorderRow(props: {
  width: number
  style: styleTypes.BorderStyle
  color: string
  updateWidth: (value: number) => void
  updateStyle: (value: styleTypes.BorderStyle) => void
  updateColor: (value: string) => void
}) {
  return (
    <BorderRowUi>
      <NumberField value={props.width} update={props.updateWidth} />
      <Select
        choices={borderStyles}
        update={props.updateStyle}
        value={props.style}
      />
      <SmallColorPicker color={props.color} update={props.updateColor} />
    </BorderRowUi>
  )
}
