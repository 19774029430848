export enum ReplacementsEnum {
  SubstitutionTagAffiliateId = 'affiliate_id',
  SubstitutionTagAffiliateDashboardUrl = 'affiliate_dashboard',
}

export const replacementsTitles = {
  [ReplacementsEnum.SubstitutionTagAffiliateId]:
    'text_replacements.affiliate_id',
  [ReplacementsEnum.SubstitutionTagAffiliateDashboardUrl]:
    'text_replacements.affiliate_dashboard',
}
