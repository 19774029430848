import React from 'react'
import DialogTitleUi from './ui/DialogTitleUi'
import { Trans } from 'react-i18next'
import DialogContentUi from './ui/DialogContentUi'
import TransparentButton from './ui/WhiteButtonUi'
import BlueButton from './ui/BlueButtonUi'
import Modal from './index'

type ModalConfirmationProps = {
  isOpen: boolean
  handleClose: () => void
  header?: string
  maxWidth?: number
  height?: number
  closeVisible?: boolean
  title: string
  handleExit: () => void
  isLoading?: boolean
  handleSave: () => void
}

const ModalConfirmation = ({
  height = 250,
  handleClose,
  closeVisible = true,
  isOpen,
  header,
  maxWidth = 570,
  title,
  handleExit,
  isLoading,
  handleSave,
}: ModalConfirmationProps) => (
  <Modal
    header={header || ''}
    handleClose={handleClose}
    isOpen={isOpen}
    maxWidth={maxWidth}
    height={height}
    closeVisible={closeVisible}
  >
    <DialogTitleUi>
      <Trans parent="span">{title}</Trans>
    </DialogTitleUi>
    <DialogContentUi>
      <TransparentButton onClick={handleExit} disabled={isLoading} shadow wide>
        <Trans parent="span">No</Trans>
      </TransparentButton>
      <BlueButton onClick={handleSave} disabled={isLoading} shadow wide>
        <Trans>Yes</Trans>
      </BlueButton>
    </DialogContentUi>
  </Modal>
)

export default ModalConfirmation
