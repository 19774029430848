import React from 'react'
import ReactDOM from 'react-dom'
import './index.css'
import App from './App'
import * as serviceWorker from './serviceWorker'
import { Provider } from 'react-redux'
import { configureStore } from './store'
import './i18'
import getIsTemplateFromUrl from './utils/templateDetector'
import getIdFromUrl from './utils/getIdFromUrl'

// Start the mocking conditionally.
// if (process.env.NODE_ENV === 'development') {
//   const { worker } = require('./mocks/browser')
//   worker.start()
// }

const isTemplate = getIsTemplateFromUrl()
const id = getIdFromUrl()

if (!id) {
  ReactDOM.render(<div>Not found</div>, document.getElementById('root'))
} else {
  ReactDOM.render(
    <React.StrictMode>
      <React.Suspense fallback="loading">
        <Provider store={configureStore()}>
          <App id={id} isTemplate={isTemplate} />
        </Provider>
      </React.Suspense>
    </React.StrictMode>,
    document.getElementById('root'),
  )
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
