import styled from 'styled-components/macro'

const TextFieldInputUi = styled.input`
  height: 32px;
  width: 100%;
  text-indent: 10px;
  border: 1px solid #dee3ec;
  border-radius: 4px;
`

export default TextFieldInputUi
