import { ManagementState } from './type'
import {
  ManagementActionTypes,
  SET_EDITING_ENTITY_ID,
  SET_FETCHED,
  SET_GLOBAL_SETTINGS_EDITING,
  SET_INSERT_TO_FOR_POPUP_LIBRARY,
  SET_IS_DESKTOP,
  TOGGLE_GLOBAL_SETTINGS_EDITING,
} from './actionTypes'

const initialState: ManagementState = {
  editingEntityId: null,
  isGlobalSettingsEditing: false,
  isFetching: true,
  entityIdForPopupLibrary: null,
  insertToFromPopupLibrary: {
    entityId: null,
    position: 0,
  },
  isDesktop: true,
}

export function managementReducer(
  state = initialState,
  action: ManagementActionTypes,
) {
  switch (action.type) {
    case SET_EDITING_ENTITY_ID:
      return {
        ...state,
        editingEntityId: action.payload,
      }
    case SET_FETCHED:
      return {
        ...state,
        isFetching: false,
      }
    case SET_INSERT_TO_FOR_POPUP_LIBRARY:
      return {
        ...state,
        insertToFromPopupLibrary: action.payload,
      }
    case SET_GLOBAL_SETTINGS_EDITING:
      return {
        ...state,
        isGlobalSettingsEditing: action.payload,
      }
    case TOGGLE_GLOBAL_SETTINGS_EDITING:
      return {
        ...state,
        isGlobalSettingsEditing: !state.isGlobalSettingsEditing,
        editingEntityId: state.isGlobalSettingsEditing
          ? state.editingEntityId
          : null,
      }
    case SET_IS_DESKTOP:
      return {
        ...state,
        isDesktop: action.payload,
      }
    default:
      return state
  }
}

function getEditingEntityId(state: ManagementState) {
  return state.editingEntityId
}

function isGlobalSettingsEditing(state: ManagementState) {
  return state.isGlobalSettingsEditing
}

function isFetching(state: ManagementState) {
  return state.isFetching
}

function getEntityIdForPopupLibrary(state: ManagementState) {
  return state.entityIdForPopupLibrary
}

function getInsertToForPopupLibrary(state: ManagementState) {
  return state.insertToFromPopupLibrary
}

function isEditing(state: ManagementState, entityId: string) {
  return state.editingEntityId === entityId
}

function isSomethingEditing(state: ManagementState) {
  return Boolean(state.editingEntityId)
}

function isDesktop(state: ManagementState) {
  return state.isDesktop
}

function isMobile(state: ManagementState) {
  return !state.isDesktop
}

export const selectors = {
  isMobile,
  isDesktop,
  isEditing,
  isSomethingEditing,
  getEditingEntityId,
  isGlobalSettingsEditing,
  isFetching,
  getEntityIdForPopupLibrary,
  getInsertToForPopupLibrary,
}
