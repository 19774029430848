import { fileTypes } from '@sio/ui'
import { request, fileUploadHttpClient } from '../utils/request'
import { mimeTypesVariants } from '@sio/file-manager/src/enums/mimeTypes'
interface FetchFilesResponse {
  files: fileTypes.File[]
  total: number
}

export function fetchFiles(
  isTemplate: boolean,
  mimeTypesGroup: string,
  pageId: number = 1,
  limit: number = 9,
) {
  const fileTypeGetParameter =
    mimeTypesGroup === mimeTypesVariants.all ? '' : `?type=${mimeTypesGroup}`

  return request.get<FetchFilesResponse>(
    isTemplate
      ? `/common/files/${pageId}/${limit}${fileTypeGetParameter}`
      : `/user/files/${pageId}/${limit}${fileTypeGetParameter}`,
  )
}

export function validateAndGetUploadOptions(
  file: File,
  isCommon: boolean = false,
) {
  return fileUploadHttpClient.post('/files/validate-and-get-upload-options', {
    editor_api_data_file: {
      fileName: file.name,
      fileSize: file.size,
      mimeType: file.type,
      isCommon,
    },
  })
}

export function activateFile(fileId: number) {
  return request.post<fileTypes.File>(`/files/${fileId}/activate`)
}

type UploadOptions = {
  key: string
  acl: string
  credential: string
  algorithm: string
  date: string
  policy: string
  signature: string
  contentType: string
  cacheControl: string
  url: string
}

export async function uploadFile(
  file: File,
  uploadOptions: UploadOptions,
  progressHandler: (progressEvent: ProgressEvent) => void,
) {
  const formData = new FormData()
  formData.append('key', uploadOptions.key)
  formData.append('acl', uploadOptions.acl)
  formData.append('X-Amz-Credential', uploadOptions.credential)
  formData.append('X-Amz-Algorithm', uploadOptions.algorithm)
  formData.append('X-Amz-Date', uploadOptions.date)
  formData.append('Policy', uploadOptions.policy)
  formData.append('X-Amz-Signature', uploadOptions.signature)
  formData.append('Content-Type', uploadOptions.contentType)
  formData.append('Cache-Control', uploadOptions.cacheControl)
  formData.append('file', file)

  await fileUploadHttpClient.post(uploadOptions.url, formData, {
    onUploadProgress: progressHandler,
  })
}

export function removeFile(fileId: number) {
  return request.delete(`/files/${fileId}`)
}
