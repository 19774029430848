import React from 'react'
import Select from 'react-select'
import Option from '../../components/Option'
import {
  findFontWeightAndStyleKeyByValue,
  fontWeightAndStyleKeys,
  fontWeightAndStyleLabels,
  fontWeightAndStyleValues,
} from '@sio/ui/build/utils/fontWeightAndStyleUtils'

const options = Object.keys(fontWeightAndStyleKeys).map(fontKey => ({
  value: fontWeightAndStyleValues[fontKey],
  label: fontWeightAndStyleLabels[fontKey],
}))

type FontWeightAndStyleProps = {
  label?: string
  update: (value: string) => void
  value?: string
}

const getFontOption = (fontKey: string) => ({
  value: fontWeightAndStyleValues[fontKey],
  label: fontWeightAndStyleLabels[fontKey],
})

function FontWeightAndStyle({
  label = 'settings_options.font_weight.label',
  update,
  value,
}: FontWeightAndStyleProps) {
  const fontKey = value && findFontWeightAndStyleKeyByValue(value)

  return (
    <Option label={label}>
      <Select
        value={fontKey ? getFontOption(fontKey) : undefined}
        options={options}
        onChange={option => {
          if (option) {
            update(option.value)
          }
        }}
        styles={{
          singleValue: (styles, { data }) => ({
            ...styles,
            fontWeightAndStyle: data.value,
          }),
          option: (styles, { data }) => ({
            ...styles,
            fontWeightAndStyle: data.value,
          }),
        }}
      />
    </Option>
  )
}

export default FontWeightAndStyle
