import styled from 'styled-components/macro'

type DeviceButtonUiProps = {
  active: boolean
}

const DeviceButtonUi = styled.button<DeviceButtonUiProps>`
  margin-right: 2px;
  margin-left: 2px;
  border: none;
  background-color: transparent;
  color: ${({ active }) => (active ? '#149efc' : '#5c687e')};
  font-weight: 400;
  font-size: 14px;
  text-align: center;
  padding: 6px 10px;
  cursor: pointer;
  line-height: 1.42857143;
  outline: none;
  transition: background-color 0.25s ease;
  &:hover {
    color: #149efc;
  }
`
export default DeviceButtonUi
