import styled from 'styled-components/macro'

type DropBoxEmptyPlaceholderUiProps = {
  canDrop: boolean
}

const DropBoxEmptyPlaceholderUi = styled.td<DropBoxEmptyPlaceholderUiProps>`
  display: flex;
  min-height: 50px;
  flex-direction: column;
  font-size: 16px;
  //color: #d0d7de;
  //text-transform: uppercase;
  justify-content: center;
  align-items: center;
  //border: 1px dashed #969fa6;
  flex: 1;
  transition: background-color 0.3s ease 0s, color 0.3s ease 0s;
  border: 1px dashed rgb(20, 158, 252);
  background-color: rgba(20, 158, 252, ${p => (p.canDrop ? 0.29 : 0.09)});
  color: ${p => (p.canDrop ? '#006CB8' : '#149efc')};
`

export default DropBoxEmptyPlaceholderUi
