import { LibraryElementTypes } from '../../../../enums/libraryElementTypes'

const libraryElements = {
  [LibraryElementTypes.LibraryImage]: {
    className: 'far fa-image',
    title: 'library.entities.image',
  },
  [LibraryElementTypes.LibraryBulletList]: {
    className: 'fas fa-list-ul',
    title: 'library.entities.bullet_list',
  },
  [LibraryElementTypes.LibraryText]: {
    className: 'fas fa-font',
    title: 'library.entities.text',
  },
  [LibraryElementTypes.LibraryButton]: {
    className: 'far fa-hand-point-up',
    title: 'library.entities.button',
  },
  [LibraryElementTypes.LibraryHorizontalLine]: {
    className: 'fas fa-divide',
    title: 'library.entities.horizontal-line',
  },
  [LibraryElementTypes.LibraryLayout_6_6]: {
    className: 'column column-6-6',
    title: 'library.entities.2-columns',
  },
  [LibraryElementTypes.LibraryLayout_12]: {
    className: 'column column-row',
    title: 'library.entities.section',
  },
  [LibraryElementTypes.LibraryLayout_4_4_4]: {
    className: 'column column-4-4-4',
    title: 'library.entities.3-columns',
  },
  [LibraryElementTypes.LibraryLayout_3_3_3_3]: {
    className: 'column column-3-3-3-3',
    title: 'library.entities.4-columns',
  },
}

export default libraryElements
