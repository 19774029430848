export const SET_EDITING_ENTITY_ID = 'SET_EDITING_ENTITY_ID'
export const SET_FETCHED = 'SET_FETCHED'
export const SET_GLOBAL_SETTINGS_EDITING = 'SET_GLOBAL_SETTINGS_EDITING'
export const TOGGLE_GLOBAL_SETTINGS_EDITING = 'TOGGLE_GLOBAL_SETTINGS_EDITING'
export const SET_INSERT_TO_FOR_POPUP_LIBRARY = 'SET_INSERT_TO_FOR_POPUP_LIBRARY'
export const SET_IS_DESKTOP = 'SET_IS_DESKTOP'
export const TOGGLE_FILE_MANAGER = 'TOGGLE_FILE_MANAGER'

export interface SetEditingEntityIdAction {
  type: typeof SET_EDITING_ENTITY_ID
  payload: string | null
}

export interface SetFetchedAction {
  type: typeof SET_FETCHED
}

export interface SetGlobalSettingsEditingAction {
  type: typeof SET_GLOBAL_SETTINGS_EDITING
  payload: boolean
}

export interface ToggleGlobalSettingsEditingAction {
  type: typeof TOGGLE_GLOBAL_SETTINGS_EDITING
}

export interface SetInsertToForPopupLibraryAction {
  type: typeof SET_INSERT_TO_FOR_POPUP_LIBRARY
  payload: {
    entityId: string | null
    position: number
  }
}

export interface SetIsDesktop {
  type: typeof SET_IS_DESKTOP
  payload: boolean
}

export interface ToggleFileManager {
  type: typeof TOGGLE_FILE_MANAGER
  payload: boolean
}

export type ManagementActionTypes =
  | SetEditingEntityIdAction
  | SetFetchedAction
  | SetGlobalSettingsEditingAction
  | SetInsertToForPopupLibraryAction
  | ToggleGlobalSettingsEditingAction
  | SetIsDesktop
