import React from 'react'
import EntityMenuItem from './EntityMenuItem'
import EntityMenuUi from './ui/EntityMenuUi'
import { useTranslation } from 'react-i18next'

type EntityMenuProps = {
  toggleSettings: (event: React.SyntheticEvent) => void
  remove: () => void
  copy: (event: React.SyntheticEvent) => void
  backgroundColor: string
}

const EntityMenu = ({
  toggleSettings,
  backgroundColor,
  remove,
  copy,
}: EntityMenuProps) => {
  const { t } = useTranslation()
  const safeRemove = (e: React.SyntheticEvent) => {
    e.stopPropagation()
    window.confirm(t('core.base_entity.action_box.safe_remove')) && remove()
  }

  return (
    <EntityMenuUi>
      <EntityMenuItem
        backgroundColor={backgroundColor}
        className="fas fa-cog"
        onClick={toggleSettings}
      />
      <EntityMenuItem
        backgroundColor={backgroundColor}
        className="fas fa-copy"
        onClick={copy}
      />
      <EntityMenuItem
        backgroundColor={backgroundColor}
        className="far fa-trash-alt"
        onClick={safeRemove}
      />
    </EntityMenuUi>
  )
}

export default EntityMenu
