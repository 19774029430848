import React from 'react'
// import { useDispatch } from 'react-redux'
import { HTML5Backend } from 'react-dnd-html5-backend'
import { DndProvider } from 'react-dnd'
import LayoutUi from './ui/LayoutUi'
// import { managementSelectors, useTypedSelector } from '../../../store'
// import * as managementActions from '../../../store/management/actions'
// import LayoutOverlayUi from './ui/LayoutOverlayUi'

type LayoutPropsType = {
  children: React.ReactNode
}

function Layout(props: LayoutPropsType) {
  // const dispatch = useDispatch()
  // const entityId = useTypedSelector(({ management }) =>
  //   managementSelectors.getEntityIdForPopupLibrary(management),
  // )
  // const hideOverlay = () => {
  //   dispatch(managementActions.setPopupLibraryForEntityId(null))
  // }
  return (
    <DndProvider backend={HTML5Backend}>
      <LayoutUi sidebarVisible>{props.children}</LayoutUi>
      {/*<LayoutOverlayUi visible={Boolean(entityId)} onClick={hideOverlay} />*/}
    </DndProvider>
  )
}

export default Layout
