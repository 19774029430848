import React from 'react'
import { styleTypes } from '@sio/ui'
import NumberField from '../../components/NumberField'
import Option from '../../components/Option'
import PaddingRowUi from './ui/PaddingRowUi'

type PaddingKeyType = keyof styleTypes.PaddingType

export type PaddingUpdater = (key: PaddingKeyType) => (value: number) => void

export type PaddingsUpdater = (
  key1: PaddingKeyType,
  key2: PaddingKeyType,
  key3: PaddingKeyType,
  key4: PaddingKeyType,
) => (value: number) => void

type PaddingProps = {
  padding: styleTypes.PaddingType
  update: PaddingUpdater
  updatePaddings: PaddingsUpdater
}

function allEqual(arr: any[]) {
  return arr.every(v => v === arr[0])
}

function Padding({ padding, update, updatePaddings }: PaddingProps) {
  const allPropsEqual = allEqual(Object.values(padding))

  return (
    <Option
      label="settings_options.padding.label"
      more
      moreChecked={!allPropsEqual}
    >
      {checked =>
        checked ? (
          <>
            <PaddingRowUi>
              <NumberField
                label="settings_options.padding.top.label"
                value={padding.paddingTop}
                update={update('paddingTop')}
              />
              <NumberField
                label="settings_options.padding.right.label"
                value={padding.paddingRight}
                update={update('paddingRight')}
              />
            </PaddingRowUi>
            <PaddingRowUi>
              <NumberField
                label="settings_options.padding.bottom.label"
                value={padding.paddingBottom}
                update={update('paddingBottom')}
              />
              <NumberField
                label="settings_options.padding.left.label"
                value={padding.paddingLeft}
                update={update('paddingLeft')}
              />
            </PaddingRowUi>
          </>
        ) : (
          <NumberField
            value={padding.paddingLeft}
            update={updatePaddings(
              'paddingTop',
              'paddingRight',
              'paddingBottom',
              'paddingLeft',
            )}
          />
        )
      }
    </Option>
  )
}

export default Padding
