import React from 'react'
import { useTranslation } from 'react-i18next'
import Option from '../../components/Option'
import BorderRowLabelUi from './ui/BorderRowLabelUi'
import { styleTypes } from '@sio/ui'
import BorderRow from './BorderRow'

export const borderStyles = {
  none: 'None',
  dotted: 'Dotted',
  dashed: 'Dashed',
  solid: 'Solid',
}

function allEqual(arr: any[]) {
  return arr.every(v => v === arr[0])
}

export type BorderKey =
  | keyof styleTypes.BorderWidthType
  | keyof styleTypes.BorderStyleType
  | keyof styleTypes.BorderColorType

export type BorderUpdater = (
  prop: 'borderStyle' | 'borderWidth' | 'borderColor',
) => (key: BorderKey) => (value: string | number) => void

export type BorderBatchUpdater = (
  prop: 'borderStyle' | 'borderWidth' | 'borderColor',
) => (
  key1: BorderKey,
  key2: BorderKey,
  key3: BorderKey,
  key4: BorderKey,
) => (value: string | number) => void

type BorderProps = {
  width: styleTypes.BorderWidthType
  style: styleTypes.BorderStyleType
  color: styleTypes.BorderColorType
  update: BorderUpdater
  batchUpdate: BorderBatchUpdater
}

function Border({ width, color, style, update, batchUpdate }: BorderProps) {
  const { t } = useTranslation()
  const allPropsEqual =
    allEqual(Object.values(width)) ||
    allEqual(Object.values(color)) ||
    allEqual(Object.values(style))

  return (
    <Option
      label="settings_options.border.label"
      more
      moreChecked={!allPropsEqual}
    >
      {checked =>
        checked ? (
          <>
            <BorderRowLabelUi>
              {t('settings_options.border_top.label')}
            </BorderRowLabelUi>
            <BorderRow
              color={color.borderTopColor}
              style={style.borderTopStyle}
              width={width.borderTopWidth}
              updateWidth={update('borderWidth')('borderTopWidth')}
              updateStyle={update('borderStyle')('borderTopStyle')}
              updateColor={update('borderColor')('borderTopColor')}
            />
            <BorderRowLabelUi>
              {t('settings_options.border_right.label')}
            </BorderRowLabelUi>
            <BorderRow
              color={color.borderRightColor}
              style={style.borderRightStyle}
              width={width.borderRightWidth}
              updateWidth={update('borderWidth')('borderRightWidth')}
              updateStyle={update('borderStyle')('borderRightStyle')}
              updateColor={update('borderColor')('borderRightColor')}
            />
            <BorderRowLabelUi>
              {t('settings_options.border_bottom.label')}
            </BorderRowLabelUi>
            <BorderRow
              color={color.borderBottomColor}
              style={style.borderBottomStyle}
              width={width.borderBottomWidth}
              updateWidth={update('borderWidth')('borderBottomWidth')}
              updateStyle={update('borderStyle')('borderBottomStyle')}
              updateColor={update('borderColor')('borderBottomColor')}
            />
            <BorderRowLabelUi>
              {t('settings_options.border_left.label')}
            </BorderRowLabelUi>
            <BorderRow
              color={color.borderLeftColor}
              style={style.borderLeftStyle}
              width={width.borderLeftWidth}
              updateWidth={update('borderWidth')('borderLeftWidth')}
              updateStyle={update('borderStyle')('borderLeftStyle')}
              updateColor={update('borderColor')('borderLeftColor')}
            />
          </>
        ) : (
          <BorderRow
            color={color.borderTopColor}
            style={style.borderTopStyle}
            width={width.borderTopWidth}
            updateWidth={batchUpdate('borderWidth')(
              'borderTopWidth',
              'borderRightWidth',
              'borderBottomWidth',
              'borderLeftWidth',
            )}
            updateStyle={batchUpdate('borderStyle')(
              'borderTopStyle',
              'borderRightStyle',
              'borderBottomStyle',
              'borderLeftStyle',
            )}
            updateColor={batchUpdate('borderColor')(
              'borderTopColor',
              'borderRightColor',
              'borderBottomColor',
              'borderLeftColor',
            )}
          />
        )
      }
    </Option>
  )
}

export default Border
