import React from 'react'
import { SectionInterface } from '@sio/ui'
import ColorPicker from '../options/ColorPicker'
// import Columns from '../options/Columns'
import {
  useUpdateNestedProp,
  useUpdateNestedProps,
  useUpdateProp,
} from '../hooks/useUpdaters'
import Padding from '../options/Padding'

type SectionSettingsProps = {
  entity: SectionInterface
}

const SectionSettings = ({ entity }: SectionSettingsProps) => {
  const updateProp = useUpdateProp(entity)
  const updateNestedProp = useUpdateNestedProp(entity)
  const updateNestedProps = useUpdateNestedProps(entity)
  return (
    <>
      <Padding
        padding={entity.padding}
        update={updateNestedProp('padding')}
        updatePaddings={updateNestedProps('padding')}
      />
      <ColorPicker
        color={entity.backgroundColor}
        label="entity_settings.section.background_color.label"
        update={updateProp('backgroundColor')}
      />
      {/*<Columns ids={entity.childIds} />*/}
    </>
  )
}

export default SectionSettings
