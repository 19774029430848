import React from 'react'
import MaxWidthUi from './ui/MaxWidthUi'
import Option from '../../components/Option'

type MaxWidthProps = {
  maxWidth: number
  label?: string
  update: (value: number) => void
}

const MaxWidth = ({
  maxWidth,
  label = 'settings_options.max_width.label',
  update,
}: MaxWidthProps) => {
  function updateMaxWidth(event: React.ChangeEvent<HTMLInputElement>) {
    update(Number(event.target.value))
  }

  return (
    <Option label={label}>
      <MaxWidthUi value={maxWidth} onChange={updateMaxWidth} />
    </Option>
  )
}

export default MaxWidth
