import styled from 'styled-components'

const MenuSelectUi = styled.select`
  background-color: transparent;
  color: #fff;
  border-color: transparent;
  outline: none;
  margin-left: 2px;
  height: 25px;
  option {
    color: #000;
  }
`

export default MenuSelectUi
