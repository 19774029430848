import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import HeaderButtonUi from './ui/HeaderButtonUi'
import { useExitUrlContext } from '../../../context/ExitUrlContext'
import { emailSelectors, useEmail } from '../../../store'
import * as api from '../../../api/emailApi'
import { useIsTemplateContext } from '../../../context/isTemplateContext'
import { InternalError } from '../../../errors/InternalError'
import { BadRequest } from '../../../errors/BadRequest'
import { NotFound } from '../../../errors/NotFound'
import { useErrorHandler } from 'react-error-boundary'
import { EntitiesTypes } from '@sio/ui'
import * as emailActions from '../../../store/email/actions'
import { useDispatch } from 'react-redux'
import ModalConfirmation from '../Modal/ModalConfirmation'
import { useUnsavedData } from '../../../hooks/use-unsaved-data'
import { useRollbar } from '@rollbar/react'

function ExitButton() {
  const { t } = useTranslation()
  const exitUrl = useExitUrlContext()
  const [isDialogOpen, openDialog] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const isTemplate = useIsTemplateContext()
  const isEmailModified = useEmail(emailSelectors.isEmailModified)
  const settings = useEmail(emailSelectors.getSettings)
  const entities = useEmail(emailSelectors.getEntities)
  const files = useEmail(emailSelectors.getFiles)
  const id = useEmail(emailSelectors.getId)
  const handleError = useErrorHandler()
  const dispatch = useDispatch()

  const rollbar = useRollbar()

  const { deleteDbRecord } = useUnsavedData({ notExecute: true })

  async function handleSave() {
    try {
      setIsLoading(true)
      await api.save(
        {
          id: id,
          content: { settings, entities },
          files,
        },
        isTemplate,
      )
      await deleteDbRecord()
      dispatch(emailActions.updateHash(entities as EntitiesTypes[]))
      setIsLoading(false)
    } catch (error) {
      if (error instanceof InternalError) {
        rollbar.error('Internal server error')
        handleError(error)
      } else if (error instanceof NotFound) {
        alert('Page not found')
        window.location.assign(process.env.REACT_APP_API_HOST)
        // handleError(error)
      } else if (error instanceof BadRequest) {
        console.log(error.messages)
      }
    }
  }

  const handleExit = () => {
    window.location.assign(exitUrl)
  }

  const checkAndExit = () => {
    if (isEmailModified) {
      openDialog(true)
    } else {
      window.location.assign(exitUrl)
    }
  }

  const saveAndExit = async () => {
    await handleSave()
    closeDialog()
    handleExit()
  }

  const closeDialog = () => openDialog(false)

  return (
    <>
      <ModalConfirmation
        handleSave={saveAndExit}
        handleClose={closeDialog}
        handleExit={handleExit}
        isLoading={isLoading}
        isOpen={isDialogOpen}
        title={'core.header.exit_confirmation_title'}
      />

      <HeaderButtonUi title={t('core.header.exit')} onClick={checkAndExit}>
        {t('core.header.exit')}
      </HeaderButtonUi>
    </>
  )
}

export default ExitButton
