import React from 'react'
import SpacingButtonUi from './ui/SpacingButtonUi'
import Option from '../../components/Option'

export type LineHeightUpdater = (value: number) => void

type SpacingProps = {
  lineHeight?: number
  update: LineHeightUpdater
}

function Spacing({ lineHeight, update }: SpacingProps) {
  function createUpdater(lineHeight: number) {
    return (e: React.SyntheticEvent) => update(lineHeight)
  }
  return (
    <Option label="settings_options.spacing.label">
      <SpacingButtonUi active={lineHeight === 1.3} onClick={createUpdater(1.3)}>
        <span className="fas fa-align-justify" />
      </SpacingButtonUi>
      <SpacingButtonUi active={lineHeight === 1.6} onClick={createUpdater(1.6)}>
        <span className="fas fa-align-justify" />
      </SpacingButtonUi>
      <SpacingButtonUi active={lineHeight === 1.9} onClick={createUpdater(1.9)}>
        <span className="fas fa-align-justify" />
      </SpacingButtonUi>
      <SpacingButtonUi active={lineHeight === 2.2} onClick={createUpdater(2.2)}>
        <span className="fas fa-align-justify" />
      </SpacingButtonUi>
    </Option>
  )
}

export default Spacing
