import { fileTypes } from '@sio/ui'
import { FilesActions } from './actions'
import { ADD_FILE, LOAD_FILES, REMOVE_FILE } from './actionTypes'

export type File = fileTypes.File
export type FilesState = { [key: string]: File }

const initialState: FilesState = {}

function removeFile(files: FilesState, removableFile: fileTypes.File) {
  return Object.values(files)
    .filter(file => file.id !== removableFile.id)
    .reduce((acc, file) => ({ ...acc, [file.id]: file }), {} as FilesState)
}

export function filesReducer(state = initialState, action: FilesActions) {
  switch (action.type) {
    case LOAD_FILES:
      return {
        ...state,
        ...action.payload.reduce(
          (acc, file) => ({
            ...acc,
            [file.id]: file,
          }),
          {},
        ),
      }
    case REMOVE_FILE:
      return removeFile(state, action.payload)
    case ADD_FILE:
      return {
        ...state,
        [action.payload.id]: action.payload,
      }
    default:
      return state
  }
}

function getFiles(state: FilesState): File[] {
  return Object.values(state)
}

export const selectors = {
  getFiles,
}
