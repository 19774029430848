import styled from 'styled-components/macro'

type EntityMoveButtonUiProps = {
  backgroundColor: string
  visible: boolean
}

const EntityMoveButtonUi = styled.div<EntityMoveButtonUiProps>`
  justify-content: center;
  align-items: center;
  display: flex;
  background-color: ${p => p.backgroundColor};
  z-index: ${p => (p.visible ? 1 : -1)};
  opacity: ${p => (p.visible ? 1 : 0)};
  width: 24px;
  height: 24px;
  color: #fff;
  cursor: pointer;
  padding: 5px;
  border-radius: 15px;
  transition: left, opacity 250ms ease-in-out;
`

export default EntityMoveButtonUi
