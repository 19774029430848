import styled from 'styled-components/macro'

const SidebarFooterUi = styled.div`
  background-color: #fff;
  border-top: 1px solid #ebedf0;
  height: 50px;
  display: flex;
  justify-content: space-between;
  width: 100%;
  overflow: hidden;
`

export default SidebarFooterUi
