import styled from 'styled-components/macro'

const ColorPickerUi = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  height: 100%;
  padding: 3px;
  border: 1px solid transparent;
  background-color: var(--settings-option-item-background-color);
  border-radius: 3px;
`

export default ColorPickerUi
