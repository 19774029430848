import React from 'react'
import AddEntityUi from './ui/AddEntityUi'

type AddEntityProps = {
  position: 'top' | 'bottom'
  backgroundColor: string
  handleClick: (event: React.SyntheticEvent) => void
}

const AddEntity = ({
  position,
  backgroundColor,
  handleClick,
}: AddEntityProps) => {
  return (
    <AddEntityUi
      position={position}
      backgroundColor={backgroundColor}
      onClick={handleClick}
    >
      <span className="fas fa-plus" />
    </AddEntityUi>
  )
}

export default AddEntity
