import styled from 'styled-components/macro'

const HeaderButtonLoaderUi = styled.span`
  float: left;
  margin-right: 8px;
  margin-top: 2px;
  display: inline-block;
  border: 2px solid #f3f3f3; /* Light grey */
  border-top: 2px solid var(--default-blue-color);
  border-radius: 50%;
  width: 14px;
  height: 14px;
  animation: spin 1s linear infinite;
  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`

export default HeaderButtonLoaderUi
