import styled, { css } from 'styled-components/macro'

const dropColor = 'rgba(75, 182, 255, 0.8)'

type ExpandedDropZonesProps = {
  dropTop?: boolean
  canDrop?: boolean
  expanded?: boolean
  dropBottom?: boolean
  dropCenter?: boolean
}

const expandedDropZones = (p: ExpandedDropZonesProps) =>
  p.expanded &&
  css`
    &::before {
      content: ' ';
      position: absolute;
      width: 100%;
      height: 5px;
      margin-top: -5px;
      background-color: rgba(20, 158, 252, 0.2);
      ${(p: ExpandedDropZonesProps) =>
        p.dropTop &&
        p.canDrop &&
        css`
          background-color: rgba(20, 158, 252, 0.8);
        `}
    }
    border-bottom: 5px solid
      rgba(20, 158, 252, ${p.canDrop && p.dropBottom ? 0.8 : 0.2});
    // ::after breaks scroll on drag
    padding-top: 5px;
  `

const commonDropZones = (p: ExpandedDropZonesProps) =>
  !p.expanded &&
  p.canDrop &&
  css`
    outline-offset: -1px;
    outline: 1px dotted #149efc;
    background-color: rgba(75, 182, 255, 0.1);
    ${(props: ExpandedDropZonesProps) =>
      props.dropTop &&
      css`
        box-shadow: ${`0px -3px 0px 0px ${dropColor}`};
      `}
    ${props =>
      props.dropCenter &&
      css`
        background-image: ${`repeating-linear-gradient(
        180deg,
        transparent,
        transparent 50%,
        ${dropColor} calc(50% - 5px),
        ${dropColor} calc(50% + 5px),
        transparent calc(50% + 5px),
        transparent
       )`};
        display: inherit; // because it just a middleware in the entity tree
      `}
    ${props =>
      props.dropBottom &&
      css`
        box-shadow: ${`0px 3px 0px 0px ${dropColor}`};
      `}
  `

const DropBoxUi = styled.table`
  width: 100%;
  transition: box-shadow 0.2s ease, background-image 0.2s ease,
    padding-top 0.2s ease, background-color 0.2s ease;
  ${expandedDropZones}
  ${commonDropZones}
`

export default DropBoxUi
