import React, { useEffect, useState } from 'react'
import { ColorResult, RGBColor, SketchPicker } from 'react-color'
import { colorUtils } from '@sio/ui'
import ColorPreviewUi from './ui/ColorPreviewUi'
import ColorPickerUi from './ui/ColorPickerUi'
import ColorRemoverWrapperUi from './ui/ColorRemoverWrapperUi'
import ColorPreviewInnerUi from './ui/ColorPreviewInnerUi'
import {
  useOverlayActionsContext,
  useOverlayVisibilityContext,
} from '../../SettingsOverlayContext'
import ColorRemoverUi from './ui/ColorRemoverUi'
import PickerPopupUi from './ui/PickerPopupUi'
import Option from '../../components/Option'

type ColorPickerProps = {
  color: string
  label?: string
  update: (value: string) => void
}

function ColorPicker({
  color,
  label = 'settings_options.color_picker.label',
  update,
}: ColorPickerProps) {
  const colorObj = colorUtils.parseToObject(color)
  const isOverlayVisible = useOverlayVisibilityContext()
  const overlayActions = useOverlayActionsContext()
  const [prevIsOverlayVisible, setPrevOverlayVisible] = useState<
    boolean | null
  >(null)
  const [isPickerVisible, setPickerVisible] = useState(false)

  useEffect(() => {
    if (prevIsOverlayVisible === true && !isOverlayVisible) {
      setPickerVisible(false)
    }

    if (isOverlayVisible && prevIsOverlayVisible === null) {
      setPrevOverlayVisible(true)
    }
  }, [prevIsOverlayVisible, isOverlayVisible])

  const showPicker = () => {
    overlayActions?.show()
    setPickerVisible(true)
  }

  const isRgbChanged = (newColor: RGBColor) => {
    const { r, g, b } = colorObj
    return newColor.r !== r || newColor.g !== g || newColor.b !== b
  }

  const handleChangeComplete = (newColor: ColorResult) => {
    const rgb = { ...newColor.rgb }
    const prevA = colorObj.a

    if (isRgbChanged(rgb) && prevA === 0) {
      rgb.a = 1
    }
    update(colorUtils.getColorAsString(rgb))
  }

  const resetTransparency = () => {
    const colorObj = {
      ...colorUtils.parseToObject(color),
      a: 0,
    }
    update(colorUtils.getColorAsString(colorObj))
  }

  return (
    <Option label={label}>
      <ColorPickerUi>
        <ColorPreviewUi>
          <ColorPreviewInnerUi color={color} onClick={showPicker} />
        </ColorPreviewUi>
        {isPickerVisible && isOverlayVisible && (
          <PickerPopupUi>
            <SketchPicker
              color={colorObj}
              disableAlpha
              onChangeComplete={handleChangeComplete}
            />
          </PickerPopupUi>
        )}
        <ColorRemoverWrapperUi>
          <ColorRemoverUi className="fa fa-ban" onClick={resetTransparency} />
        </ColorRemoverWrapperUi>
      </ColorPickerUi>
    </Option>
  )
}

export default ColorPicker
