import { ConnectDropTarget, useDrop } from 'react-dnd'
import { useDispatch } from 'react-redux'
import { EntityTypes, EntitiesTypes } from '@sio/ui'
import {
  isLayoutType,
  LibraryElementTypes,
} from '../../../enums/libraryElementTypes'
import createEntity from '../../../utils/createEntity'
import createLayout from '../../../utils/createLayout'
import * as emailActions from '../../../store/email/actions'
import * as utils from '../../../store/email/utils'

type MoveEntity = {
  id: string
  type: EntityTypes
}

type LibraryElement = {
  type: LibraryElementTypes
}

type DropItem = LibraryElement | MoveEntity

type DropBoxReturnType = {
  canDrop: boolean
  connect: ConnectDropTarget
}

function isDropItemIsExistingEntity(
  dropItem: DropItem,
): dropItem is MoveEntity {
  return 'id' in dropItem
}

export default function useDropBox(
  parentEntity: EntitiesTypes,
  position: number = 0,
): DropBoxReturnType {
  const dispatch = useDispatch()
  const [{ canDrop }, connect] = useDrop({
    accept: Object.keys(LibraryElementTypes).concat(Object.keys(EntityTypes)),
    drop: (dropItem: DropItem, monitor) => {
      if (monitor.didDrop() || !parentEntity) {
        return
      }

      if (isDropItemIsExistingEntity(dropItem)) {
        if (
          dropItem.type !== EntityTypes.Section &&
          parentEntity.type === EntityTypes.Body
        ) {
          const { entities, ascendantId } = createLayout(
            LibraryElementTypes.LibraryLayout_12,
          )
          dispatch(
            emailActions.addEntities(
              entities,
              ascendantId,
              parentEntity.id,
              position,
            ),
          )
          dispatch(emailActions.moveEntity(dropItem.id, ascendantId, 0))
        } else {
          dispatch(
            emailActions.moveEntity(dropItem.id, parentEntity.id, position),
          )
        }
      } else {
        if (isLayoutType(dropItem.type)) {
          const { ascendantId, entities } = createLayout(dropItem.type)
          dispatch(
            emailActions.addEntities(
              entities,
              ascendantId,
              parentEntity.id,
              position,
            ),
          )
        } else {
          const entity = createEntity(dropItem.type)
          if (parentEntity.type === EntityTypes.Body) {
            const { ascendantId, entities } = createLayout(
              LibraryElementTypes.LibraryLayout_12,
            )
            const column = entities.find(
              entity => entity.type === EntityTypes.Column,
            )

            if (!column) {
              throw new Error(
                `Could not find ascendant of layout ${JSON.stringify(
                  entities,
                )}`,
              )
            }

            column.childIds.push(entity.id)
            const updatedEntities = utils.updateEntity(entities, column)
            dispatch(
              emailActions.addEntities(
                // @ts-ignore
                [...updatedEntities, entity],
                ascendantId,
                parentEntity.id,
                position,
              ),
            )
          } else {
            dispatch(emailActions.addEntity(entity, parentEntity.id, position))
          }
        }
      }
    },
    collect: monitor => ({
      canDrop: monitor.isOver({ shallow: true }) && monitor.canDrop(),
    }),
    canDrop: dropItem => {
      if (isDropItemIsExistingEntity(dropItem)) {
        if (
          dropItem.type === EntityTypes.Section &&
          parentEntity.type !== EntityTypes.Body
        ) {
          return false
        }
      } else {
        if (
          isLayoutType(dropItem.type) &&
          parentEntity.type !== EntityTypes.Body
        ) {
          return false
        }
      }
      return true
    },
  })

  return { canDrop, connect }
}
