import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import { useDragLayer } from 'react-dnd'
import { EntitiesTypes } from '@sio/ui'
import * as managementActions from '../../../store/management/actions'
import { removeEntity, copyEntity } from '../../../store/email/actions'
import BaseEntityUi from './ui/BaseEntityUi'
import EntityMenu from './EntityMenu'
import EntityMover from './EntityMover'
import AddEntity from './AddEntity'
import { useManagement, managementSelectors } from '../../../store'

type BaseEntityPropsType = {
  entity: EntitiesTypes
  children: any
  hoverColor?: string
  position: number
  parentId: string
  marginTop?: number
  marginLeft?: number
  marginBottom?: number
  marginRight?: number
}

export const stateColor = {
  blue: '#149EFC',
  green: '#56C098',
  orange: '#F78828',
  gray: '#5A6E73',
}

function BaseEntity({
  entity,
  children,
  hoverColor = stateColor.orange,
  position,
  parentId,
  marginTop,
  marginRight,
  marginBottom,
  marginLeft,
}: BaseEntityPropsType) {
  const [showMenu, setShowMenu] = useState(false)
  const dispatch = useDispatch()
  const { isSomethingDragging } = useDragLayer(monitor => ({
    isSomethingDragging: monitor.isDragging(),
  }))
  const isEditing = useManagement(state =>
    managementSelectors.isEditing(state, entity.id),
  )
  function hover(e: React.SyntheticEvent) {
    if (isEditing) {
      e.stopPropagation()
      return
    }
    setShowMenu(true)
  }

  function unHover() {
    setShowMenu(false)
  }

  function getStateColor() {
    if (showMenu && !isEditing) {
      return hoverColor
    }

    return null
  }

  function remove() {
    dispatch(removeEntity(entity))
  }

  function copy(e: React.SyntheticEvent) {
    e.stopPropagation()
    dispatch(copyEntity(entity.id))
  }

  function showPopupLibrary(position: number) {
    return function (event: React.SyntheticEvent) {
      event.stopPropagation()
      dispatch(managementActions.setInsertToForPopupLibrary(parentId, position))
    }
  }

  function enableSettings(event: React.SyntheticEvent) {
    if (event.target) {
      if ((event.target as Element).tagName !== 'INPUT') {
        event.preventDefault()
      }
    }
    event.stopPropagation()
    if (!isEditing) {
      dispatch(managementActions.setEditingEntityId(entity.id))
    }
  }

  return (
    <tbody>
      <tr>
        <BaseEntityUi
          isSomethingDragging={isSomethingDragging}
          isEditing={isEditing}
          onMouseEnter={hover}
          stateColor={getStateColor()}
          onMouseLeave={unHover}
          onClick={enableSettings}
          marginTop={marginTop}
          marginRight={marginRight}
          marginBottom={marginBottom}
          marginLeft={marginLeft}
          id={`entity-${entity.id}`}
        >
          {/*<tbody style={{ display: 'contents' }}>*/}
          {/* tbody breaks image align, but the absence of it throw warnings */}
          {showMenu && !isEditing && (
            <>
              <AddEntity
                backgroundColor={hoverColor}
                position={'top'}
                handleClick={showPopupLibrary(position)}
              />
              <EntityMenu
                backgroundColor={hoverColor}
                toggleSettings={enableSettings}
                remove={remove}
                copy={copy}
              />
              <EntityMover
                backgroundColor={hoverColor}
                type={entity.type}
                id={entity.id}
                parentId={parentId}
                position={position}
              />
              <AddEntity
                backgroundColor={hoverColor}
                position={'bottom'}
                handleClick={showPopupLibrary(position + 1)}
              />
            </>
          )}
          {children}
          {/*</tbody>*/}
        </BaseEntityUi>
      </tr>
    </tbody>
  )
}

export default BaseEntity
