import React from 'react'
import { BulletListInterface } from '@sio/ui'
import ColorPicker from '../options/ColorPicker'
import Padding, { PaddingsUpdater, PaddingUpdater } from '../options/Padding'
import {
  useUpdateNestedProp,
  useUpdateNestedProps,
  useUpdateProp,
} from '../hooks/useUpdaters'
import ListStyle from '../options/ListStyle'

type BulletListSettingsProps = {
  entity: BulletListInterface
}

const BulletListSettings = ({ entity }: BulletListSettingsProps) => {
  const updateProp = useUpdateProp(entity)
  const updateNestedProp = useUpdateNestedProp(entity)
  const updateNestedProps = useUpdateNestedProps(entity)
  return (
    <>
      <ListStyle
        value={entity.listStyleType}
        update={updateProp('listStyleType')}
      />
      <ColorPicker
        color={entity.markerColor}
        update={updateProp('markerColor')}
        label="entity_settings.text.marker_color.label"
      />
      <Padding
        padding={entity.padding}
        update={updateNestedProp('padding') as PaddingUpdater}
        updatePaddings={updateNestedProps('padding') as PaddingsUpdater}
      />
    </>
  )
}

export default BulletListSettings
