import React, { useState, createContext, useContext } from 'react'

type OverlayToggleContextProps = {
  close: () => void
  show: () => void
  toggle: (val: boolean) => void
}

const OverlayVisibilityContext = createContext<boolean>(false)
const OverlayToggleContext = createContext<OverlayToggleContextProps | null>(
  null,
)

export const useOverlayVisibilityContext = () =>
  useContext(OverlayVisibilityContext)
export const useOverlayActionsContext = () => useContext(OverlayToggleContext)

type SettingsOverlayContextProviderProps = {
  children: React.ReactNode
}

const OverlayContextProvider = ({
  children,
}: SettingsOverlayContextProviderProps) => {
  const [isOverlayVisible, setOverlayVisible] = useState(false)

  return (
    <OverlayVisibilityContext.Provider value={isOverlayVisible}>
      <OverlayToggleContext.Provider
        value={{
          close: () => setOverlayVisible(false),
          show: () => setOverlayVisible(true),
          toggle: (val: boolean) => setOverlayVisible(val),
        }}
      >
        {children}
      </OverlayToggleContext.Provider>
    </OverlayVisibilityContext.Provider>
  )
}

export default OverlayContextProvider
