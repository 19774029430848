import React from 'react'
import { useDispatch } from 'react-redux'
import { EntityTypes } from '@sio/ui'
import {
  isLayoutType,
  LibraryElementTypes,
} from '../../../enums/libraryElementTypes'
import createEntity from '../../../utils/createEntity'
import createLayout from '../../../utils/createLayout'
import * as emailActions from '../../../store/email/actions'
import { emailSelectors, useTypedSelector } from '../../../store'
import LayoutElementsLibrary from './LayoutElementsLibrary'
import SimpleElementsLibrary from './SimpleElementsLibrary'
import { useTranslation } from 'react-i18next'
import { useRollbar } from '@rollbar/react'

type PopupLibraryContentProps = {
  parentId: string
  position: number
  onAdd: () => void
}

function PopupLibraryContent({
  parentId,
  position,
  onAdd,
}: PopupLibraryContentProps) {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const parentEntity = useTypedSelector(({ email }) =>
    emailSelectors.getEntityById(email, parentId),
  )

  const rollbar = useRollbar()

  if (!parentEntity) {
    return null
  }

  function addEntity(type: LibraryElementTypes) {
    return function () {
      try {
        if (isLayoutType(type)) {
          const { ascendantId, entities } = createLayout(type)
          dispatch(
            emailActions.addEntities(entities, ascendantId, parentId, position),
          )
        } else {
          const entity = createEntity(type)
          dispatch(emailActions.addEntity(entity, parentId, position))
        }
        onAdd()
      } catch (error) {
        rollbar.error(error as any)
        alert(t('core.errors.something_went_wrong'))
      }
    }
  }

  return (
    <>
      {parentEntity.type === EntityTypes.Body ? (
        <LayoutElementsLibrary addEntity={addEntity} />
      ) : (
        <SimpleElementsLibrary addEntity={addEntity} />
      )}
    </>
  )
}

export default PopupLibraryContent
