import React, { useRef, useState, useCallback } from 'react'
import MenuUi from './ui/MenuUi'
import { managementSelectors, useManagement } from '../../../../store'

type MenuProps = {
  children: React.ReactNode
  calculateWidth: boolean
}

export const NARROW_WIDTH = 158
export const DEFAULT_WIDTH = 385
export const WIDE_WIDTH = 408

function useRefCallback() {
  const ref = useRef<HTMLDivElement | null>(null)
  const [isMenuFixed, setMenuFixed] = useState(false)

  const setRef = useCallback((node: HTMLDivElement) => {
    if (node && node.getBoundingClientRect().y < 40) {
      setMenuFixed(true)
    }

    // Save a reference to the node
    ref.current = node
  }, [])

  return [setRef, isMenuFixed]
}

const Menu = ({ children }: MenuProps) => {
  const isMobile = useManagement(managementSelectors.isMobile)
  const [ref, isMenuFixed] = useRefCallback()

  return (
    // @ts-ignore
    <MenuUi ref={ref} isMobile={isMobile} fixed={isMenuFixed}>
      {children}
    </MenuUi>
  )
}

export default Menu
