import styled from 'styled-components/macro'

const LinkEditOverlayUi = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 10;
`

export default LinkEditOverlayUi
