import React from 'react'
import LibraryItemIconUi from './ui/LibraryItemIconUi'
import libraryElements from './libraryElements'
import LibraryItemUi from './ui/LibraryItemUi'
import { LibraryElementTypes } from '../../../../enums/libraryElementTypes'
import { useTranslation } from 'react-i18next'
import { useDrag } from 'react-dnd'

type LibraryElementProps = {
  type: keyof typeof LibraryElementTypes
}

const LibraryElement = ({ type }: LibraryElementProps) => {
  const { t } = useTranslation()
  const [, dragRef] = useDrag({
    item: { type },
    type,
  })
  const libraryElement = libraryElements[type]

  return (
    <LibraryItemUi key={type} ref={dragRef}>
      <LibraryItemIconUi className={libraryElements[type].className} />
      {'title' in libraryElement && t(libraryElement.title)}
    </LibraryItemUi>
  )
}

export default LibraryElement
