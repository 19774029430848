import styled from 'styled-components/macro'

interface LayoutUiProps {
  readonly sidebarVisible: boolean
}

const LayoutUi = styled.div<LayoutUiProps>`
  display: grid;
  grid-template-rows: 44px auto;
  grid-template-columns: ${(p: LayoutUiProps) =>
      p.sidebarVisible ? '350px' : '0'} 1fr;
  height: 100vh;
`

export default LayoutUi
