import styled from 'styled-components/macro'
import logo from './assets/logo.png'

const Logo = styled.img.attrs({
  src: logo,
  height: 30,
  alt: 'systeme.io editor',
})`
  justify-self: center;
`

export default Logo
