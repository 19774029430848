import React from 'react'
import { Image as ImageUi, EntityTypes, ImageInterface } from '@sio/ui'
import { generateId } from '../../store/email/utils'
import BaseEntity from '../core/BaseEntity/BaseEntity'
import { useEmail, emailSelectors } from '../../store'

type ImageProps = {
  entity: ImageInterface
  position: number
  parentId: string
}

const Image = ({ entity, position, parentId }: ImageProps) => {
  const file = useEmail(email =>
    emailSelectors.getFileById(email, entity.fileId),
  )
  return (
    <BaseEntity entity={entity} position={position} parentId={parentId}>
      <ImageUi
        src={file ? file.path : `/images/image_placeholder.png`}
        width={entity.width}
        align={entity.align}
        url={entity.url}
      />
    </BaseEntity>
  )
}

export function createImage(): ImageInterface {
  return {
    id: generateId(),
    type: EntityTypes.Image,
    fileId: null,
    align: 'center',
  }
}

export default Image
