import React, { useEffect, useState } from 'react'
import {
  ContentBlock,
  convertFromRaw,
  DraftBlockRenderMap,
  DraftEditorCommand,
  Editor,
  EditorState,
  Modifier,
  RichUtils,
} from 'draft-js'
import { colorUtils, styleTypes } from '@sio/ui'
import * as utils from './utils'
import Menu from './Menu'
import createLinkDecorator from './linkDecorator'
import InlineStyleControls from './Menu/InlineStyleControls'
import Color from './Menu/ColorPicker'
import Link, { LinkType } from './Menu/Link'
import MenuButtonUi from './Menu/ui/MenuButtonUi'
import TextEditorUi from './ui/TextEditorUi'
import FontSize, {
  fontSizes,
  STYLE_KEY as FONT_SIZE_STYLE_KEY,
} from './Menu/FontSize'
import { emailSelectors, useEmail } from '../../../store'
import Replacements from './Menu/Replacements'
import BlockStyleControls from './Menu/BlockStyleControls'
import LinkEditOverlayUi from './ui/LinkEditOverlayUi'

const fontsMap = fontSizes.reduce(
  (acc, fontSize) => ({
    ...acc,
    [FONT_SIZE_STYLE_KEY + fontSize]: { fontSize },
  }),
  {},
)

const customStyleMap = {
  ...fontsMap,
}

type TextEditorProps = {
  isEditing: boolean
  rawContentState: string
  linkColor?: string
  update: (value: string) => void
  padding: styleTypes.PaddingType
  textAlign: styleTypes.TextAlign
  lineHeight?: number
  fontFamily?: string
  blockStyleFn?: (block: ContentBlock) => any
  blockRenderMap?: DraftBlockRenderMap
}

function TextEditor({
  isEditing,
  rawContentState,
  linkColor,
  update,
  padding,
  textAlign,
  lineHeight,
  fontFamily,
  blockStyleFn,
  blockRenderMap,
}: TextEditorProps) {
  const editor = React.useRef<Editor>(null)
  const [editorState, setEditorState] = useState(
    utils.createStateFromRaw(rawContentState),
  )
  const [linkPrevColor] = useState('')
  const [currentLinkKey, setCurrentLinkKey] = useState('')
  const globalSettings = useEmail(emailSelectors.getSettings)
  const linkSettingsEnabled = Boolean(currentLinkKey)

  const focusEditor = React.useCallback(() => {
    if (editor.current) {
      editor.current.focus()
    }
  }, [editor])

  useEffect(() => {
    setEditorState(prev =>
      EditorState.set(prev, {
        currentContent: convertFromRaw(JSON.parse(rawContentState)),
      }),
    )
  }, [rawContentState])

  function handleLinkClick(entityKey: string) {
    // const newEditorState =  utils.selectAnchorWord(editorState)
    // setEditorState(newEditorState)
    setCurrentLinkKey(entityKey)
    focusEditor()
  }

  React.useEffect(() => {
    setEditorState(prev =>
      EditorState.set(prev, {
        decorator: createLinkDecorator(handleLinkClick),
      }),
    )
    // eslint-disable-next-line
  }, [currentLinkKey, blockRenderMap])

  function toggleInlineStyle(inlineStyle: string) {
    const newState = RichUtils.toggleInlineStyle(editorState, inlineStyle)
    setEditorState(newState)
  }

  function toggleBlockType(blockType: string) {
    const newEditorState = RichUtils.toggleBlockType(editorState, blockType)
    setEditorState(newEditorState)
  }

  function createLinkAndShowLinkSettings(event: React.SyntheticEvent) {
    event.preventDefault()
    const entityKey = utils.getSelectionEntityKeyOrNull(editorState)

    if (
      entityKey &&
      editorState.getCurrentContent().getEntity(entityKey).getType() === 'LINK'
    ) {
      setCurrentLinkKey(entityKey)
    } else {
      const selectedEditorState = utils.selectCurrentWord(editorState)
      const createLinkResult = utils.createLink(selectedEditorState)
      setEditorState(createLinkResult.editorState)
      setCurrentLinkKey(createLinkResult.linkKey)
    }
  }

  function updateLink(data: LinkType) {
    const newContentState = editorState
      .getCurrentContent()
      .mergeEntityData(currentLinkKey, data)

    const newEditorState = EditorState.push(
      editorState,
      newContentState,
      'change-block-data',
    )
    setEditorState(newEditorState)
    save()
  }

  function closeLinkSettings() {
    removeLinkIfNoUrl()
  }

  function removeLinkIfNoUrl() {
    const linkData = utils.getEntityData(editorState, currentLinkKey)
    if (!linkData.url) {
      removeCurrentLink()
    }
    setCurrentLinkKey('')
  }

  function closeLinkSettingsAndStopPropagation(e: React.SyntheticEvent) {
    e.stopPropagation()
    removeLinkIfNoUrl()
  }

  function handleBlur() {
    if (!currentLinkKey) {
      save()
    }
  }

  function save() {
    const changedContentState = editorState.getCurrentContent()
    const initialContentState = convertFromRaw(JSON.parse(rawContentState))
    const equals = initialContentState
      .getBlockMap()
      .equals(changedContentState.getBlockMap())
    if (!equals) {
      update(utils.getRawContentState(editorState))
    }
  }

  function removeCurrentLink() {
    let updatedEditorState = utils.removeLink(editorState, currentLinkKey)
    if (updatedEditorState) {
      // restore prev color
      updatedEditorState = RichUtils.toggleInlineStyle(
        updatedEditorState,
        linkPrevColor,
      )
      setEditorState(updatedEditorState)
    }
  }

  function removeCurrentLinkAndCloseSettings() {
    removeCurrentLink()
    setCurrentLinkKey('')
  }

  function changeColor(color: string) {
    const updatedState = utils.toggleInlineStyle(
      editorState,
      color,
      colorUtils.isRgba,
    )
    setEditorState(updatedState)
    // save method would take old state
    update(utils.getRawContentState(updatedState))
  }

  function changeFontSize(fontSize: string) {
    const updatedState = utils.toggleInlineStyle(editorState, fontSize, value =>
      value.startsWith(FONT_SIZE_STYLE_KEY),
    )
    setEditorState(updatedState)
    // save method would take old state
    update(utils.getRawContentState(updatedState))
  }

  function applyReplacement(replacement: string) {
    const newContentState = Modifier.replaceText(
      editorState.getCurrentContent(),
      editorState.getSelection(),
      replacement,
      editorState.getCurrentInlineStyle(),
      utils.getSelectionEntityKeyOrNull(editorState),
    )

    const newEditorState = EditorState.push(
      editorState,
      newContentState,
      'change-inline-style',
    )
    // we should keep styles
    setEditorState(newEditorState)
    // save method would take old state
    update(utils.getRawContentState(newEditorState))
  }

  function handleKeyCommand(
    command: DraftEditorCommand,
    editorState: EditorState,
  ) {
    const newState = RichUtils.handleKeyCommand(editorState, command)

    if (newState) {
      setEditorState(newState)
      return 'handled'
    }

    return 'not-handled'
  }

  return (
    <>
      {isEditing && (
        <Menu calculateWidth={linkSettingsEnabled}>
          {currentLinkKey ? (
            <Link
              remove={removeCurrentLinkAndCloseSettings}
              close={closeLinkSettings}
              currentLink={utils.getEntityData(editorState, currentLinkKey)}
              update={updateLink}
            />
          ) : (
            <>
              <InlineStyleControls
                currentStyle={editorState.getCurrentInlineStyle()}
                toggleStyle={toggleInlineStyle}
              />
              <BlockStyleControls
                editorState={editorState}
                toggleStyle={toggleBlockType}
              />
              <FontSize
                toggleFontSize={changeFontSize}
                fontSize={
                  utils.getCurrentFontSize(editorState) ||
                  globalSettings.textFontSize
                }
              />
              <MenuButtonUi onClick={createLinkAndShowLinkSettings}>
                <span className="fas fa-link" />
              </MenuButtonUi>
              <Color
                color={utils.getCurrentColor(editorState)}
                onChange={changeColor}
              />
              <Replacements onChange={applyReplacement} />
            </>
          )}
        </Menu>
      )}
      <TextEditorUi
        onClick={focusEditor}
        isEditing={isEditing}
        fontSize={globalSettings.textFontSize}
        paddingTop={padding.paddingTop}
        paddingRight={padding.paddingRight}
        paddingBottom={padding.paddingBottom}
        paddingLeft={padding.paddingLeft}
        textAlign={textAlign}
        lineHeight={lineHeight}
        fontFamily={fontFamily}
        onBlur={handleBlur}
        linkColor={linkColor}
      >
        <Editor
          ref={editor}
          customStyleMap={customStyleMap}
          customStyleFn={utils.applyCustomStyle}
          editorState={editorState}
          onChange={setEditorState}
          handleKeyCommand={handleKeyCommand}
          blockStyleFn={blockStyleFn}
          blockRenderMap={blockRenderMap}
          stripPastedStyles
        />
        {currentLinkKey && (
          <LinkEditOverlayUi onClick={closeLinkSettingsAndStopPropagation} />
        )}
      </TextEditorUi>
    </>
  )
}

export default TextEditor
