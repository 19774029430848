import styled from 'styled-components/macro'
import HeaderButtonUi from './HeaderButtonUi'

type HeaderBlueButtonUiProps = {
  active?: boolean
  blue?: boolean
}

const HeaderBlueButtonUi = styled(HeaderButtonUi)<HeaderBlueButtonUiProps>`
  color: #fff;
  background-color: var(--default-blue-color);
  &:hover {
    background-color: var(--default-blue-color-hover);
  }
`

export default HeaderBlueButtonUi
