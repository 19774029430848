import styled, { css } from 'styled-components/macro'

type BaseEntityProps = {
  stateColor: string | null
  isSomethingDragging: boolean
  isEditing: boolean
  marginTop?: number
  marginRight?: number
  marginBottom?: number
  marginLeft?: number
}

const BaseEntityUi = styled.td<BaseEntityProps>`
  position: relative;
  ${p =>
    p.stateColor &&
    css`
      outline: 1px solid ${p.stateColor};
    `};
  ${p =>
    p.isSomethingDragging &&
    css`
      outline: 1px dashed #ccc;
    `}
  ${p =>
    p.isEditing &&
    css`
      box-shadow: 0 0 0 2px var(--default-blue-color);
    `};
`

export default BaseEntityUi
