export const mimeTypesVariants = {
  image: 'image',
  video: 'video',
  audio: 'audio',
  all: 'all',
}
export const mimeTypes = {
  [mimeTypesVariants.image]: ['image/jpg', 'image/gif', 'image/png'],
  [mimeTypesVariants.video]: [
    'video/mp4',
    'video/ogg',
    'video/webm',
    'video/quicktime',
    'video/x-ms-wmv',
    'video/x-flv',
    'video/x-msvideo',
    'video/3gpp',
    'video/3gpp2',
    'video/mpeg',
  ],
  [mimeTypesVariants.audio]: [
    'audio/basic',
    'audio/L24',
    'audio/mp4',
    'audio/aac',
    'audio/mpeg',
    'audio/ogg',
    'audio/vorbis',
    'audio/x-ms-wma',
    'audio/x-ms-wax',
    'audio/vnd.rn-realaudio',
    'audio/vnd.wave',
    'audio/webpackCommon',
    'audio/mp3',
    'audio/wav',
    'audio/wave',
    'audio/x-wav',
    'audio/flac',
    'audio/x-flac',
  ],
  [mimeTypesVariants.all]: [
    'image/*',
    'video/*',
    'audio/*',
    'application/*',
    'text/*',
  ],
}
