import styled, { css } from 'styled-components/macro'

type OverlayUiProps = {
  zIndex?: number
  fixed?: boolean
}

const OverlayUi = styled.div<OverlayUiProps>`
  width: 100%;
  height: 100%;
  min-height: 100vh;
  position: ${p => (p.fixed ? 'fixed' : 'absolute')};
  left: 0;
  top: 0;
  ${p =>
    p.zIndex &&
    css`
      z-index: ${p.zIndex};
    `}
`

export default OverlayUi
