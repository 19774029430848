import styled from 'styled-components/macro'

type LibraryItemUiProps = {
  isDragging?: boolean
}

const LibraryItemUi = styled.div<LibraryItemUiProps>`
  transition: opacity 0.25s ease-in;
  opacity: ${({ isDragging }) => (isDragging ? 0.5 : 1)};
  background-color: ${({ isDragging }) => (isDragging ? '#ebedf0' : '#ffffff')};
  width: 80px;
  height: 100px;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  border-radius: 3px;
  border: solid 1px #ebedf0;
  text-align: center;
  cursor: move;
  margin: 10px 20px 0 0;
  > span {
    opacity: ${({ isDragging }) => (isDragging ? 0 : 1)};
  }
  &:nth-of-type(3n) {
    margin-right: 0;
  }
`

export default LibraryItemUi
