import styled from 'styled-components/macro'

const HeaderUi = styled.div`
  display: grid;
  grid-template-columns: 50px auto minmax(230px, max-content);
  align-items: center;
  grid-column-start: 1;
  grid-column-end: 3;
  position: relative;
  z-index: 40;
  background: #142d63;
  color: rgb(238, 238, 238);
  font-size: 14px;
  box-shadow: 0 2px 2px 0 rgba(46, 81, 98, 0.18);
`

export default HeaderUi
