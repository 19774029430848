import React from 'react'
import {
  EntityTypes,
  HorizontalLine as HorizontalLineUi,
  HorizontalLineInterface,
} from '@sio/ui'
import BaseEntity from '../core/BaseEntity/BaseEntity'
import { generateId } from '../../store/email/utils'
import { BorderStyleEnum } from '../../enums/borderStyles'

type HorizontalLineProps = {
  entity: HorizontalLineInterface
  position: number
  parentId: string
}

const HorizontalLine = ({
  entity,
  position,
  parentId,
}: HorizontalLineProps) => {
  return (
    <BaseEntity entity={entity} position={position} parentId={parentId}>
      <HorizontalLineUi
        borderWidth={entity.borderWidth}
        color={entity.color}
        style={entity.style}
        padding={entity.padding}
      />
    </BaseEntity>
  )
}

export function createHorizontalLine(): HorizontalLineInterface {
  return {
    id: generateId(),
    type: EntityTypes.HorizontalLine,
    color: 'rgba(0,0,0,1)',
    borderWidth: 1,
    style: BorderStyleEnum.solid,
    padding: {
      paddingTop: 5,
      paddingRight: 0,
      paddingBottom: 5,
      paddingLeft: 0,
    },
  }
}

export default HorizontalLine
