import React from 'react'
import Immutable from 'immutable'
import { DefaultDraftBlockRenderMap } from 'draft-js'
import { EntityTypes, BulletListInterface } from '@sio/ui'
import BaseEntity from '../core/BaseEntity/BaseEntity'
import { generateId } from '../../store/email/utils'
import TextEditor from '../core/TextEditor'
import { managementSelectors, useManagement } from '../../store'
import * as emailActions from '../../store/email/actions'
import { useDispatch } from 'react-redux'

type BulletListProps = {
  entity: BulletListInterface
  position: number
  parentId: string
}

type ListProps = {
  children?: React.ReactChildren
  listStyleType: string
  markerColor: string
}

function List({ children, listStyleType, markerColor }: ListProps) {
  return (
    <ul
      style={{ textAlign: 'left', listStyleType, color: markerColor }}
      type={listStyleType}
    >
      {children}
    </ul>
  )
}

const BulletList = ({ entity, position, parentId }: BulletListProps) => {
  const dispatch = useDispatch()
  const isEditing = useManagement(state =>
    managementSelectors.isEditing(state, entity.id),
  )

  const blockRenderMap = Immutable.Map({
    'unordered-list-item': {
      element: 'li',
      wrapper: (
        <List
          listStyleType={entity.listStyleType}
          markerColor={entity.markerColor}
        />
      ),
    },
  })

  function updateText(rawContentState: string) {
    dispatch(
      emailActions.updateEntity({
        ...entity,
        rawContentState,
      }),
    )
  }

  const extendedBlockRenderMap = DefaultDraftBlockRenderMap.merge(
    blockRenderMap,
  )

  return (
    <BaseEntity entity={entity} position={position} parentId={parentId}>
      <TextEditor
        blockRenderMap={extendedBlockRenderMap}
        update={updateText}
        isEditing={isEditing}
        rawContentState={entity.rawContentState}
        padding={entity.padding}
        textAlign="left"
      />
    </BaseEntity>
  )
}

export function createBulletList(): BulletListInterface {
  return {
    id: generateId(),
    type: EntityTypes.BulletList,
    rawContentState:
      '{"entityMap":{},"blocks":[{"key":"7gjsu",' +
      '"text":"entities.bullet_list.default_text_first","type":"unordered-list-item",' +
      '"depth":0,"inlineStyleRanges":[{"offset":0,"length":39,"style":"rgba(0, 0, 0, 1)"}],"entityRanges":[],' +
      '"data":{}},{"key":"a8v7j","text":"entities.bullet_list.default_text_second","type":"unordered-list-item",' +
      '"depth":0,"inlineStyleRanges":[{"offset":0,"length":40,"style":"rgba(0, 0, 0, 1)"}],' +
      '"entityRanges":[],"data":{}}]}',
    padding: {
      paddingTop: 15,
      paddingRight: 15,
      paddingBottom: 15,
      paddingLeft: 15,
    },
    markerColor: 'rgba(0, 0, 0, 1)',
    listStyleType: 'disc',
  }
}

export default BulletList
