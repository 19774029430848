import React from 'react'
import RangeUi from './ui/RangeUi'
import RangeInputUi from './ui/RangeInputUi'
import RangeTextInputUi from './ui/RangeTextInputUi'
import Option from '../Option'

type RangeProps = {
  label: string
  min?: number
  max?: number
  value?: number
  change: (value: number) => void
  greyThumb?: boolean
}

function Range({
  label,
  min = 0,
  max = 100,
  value = 0,
  change,
  greyThumb = false,
}: RangeProps) {
  function setNumberValue(event: React.ChangeEvent<HTMLInputElement>) {
    change(Number(event.target.value))
  }

  return (
    <Option label={label}>
      <RangeUi>
        <RangeInputUi
          greyThumb={greyThumb}
          min={min}
          max={max}
          value={value}
          onChange={setNumberValue}
        />
        <RangeTextInputUi value={value} onChange={setNumberValue} />
      </RangeUi>
    </Option>
  )
}

export default Range
