import styled from 'styled-components/macro'

const PopupLibraryItemUi = styled.div`
  width: 100px;
  height: 120px;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  border-radius: 3px;
  border: solid 1px #ebedf0;
  text-align: center;
  margin: 10px 20px 0 0;
  cursor: pointer;
  &:hover {
    box-shadow: 0 0 2px 0 var(--default-blue-color);
  }
  &:nth-of-type(3n) {
    margin-right: 0;
  }
`

export default PopupLibraryItemUi
