import React from 'react'
import Option from '../../components/Option'
import AlignButtonUi from './ui/AlignButtonUi'
import { styleTypes } from '@sio/ui'

type AlignProps = {
  align: styleTypes.AlignType
  update: (value: styleTypes.AlignType) => void
  allowJustify?: boolean
}

function Align({ align, update }: AlignProps) {
  return (
    <Option label="settings_options.text_align.label">
      <AlignButtonUi active={align === 'left'} onClick={() => update('left')}>
        <span className="fas fa-align-left" />
      </AlignButtonUi>
      <AlignButtonUi
        active={align === 'center'}
        onClick={() => update('center')}
      >
        <span className="fas fa-align-center" />
      </AlignButtonUi>
      <AlignButtonUi active={align === 'right'} onClick={() => update('right')}>
        <span className="fas fa-align-right" />
      </AlignButtonUi>
    </Option>
  )
}

export default Align
