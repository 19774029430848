import React from 'react'
import { useDispatch } from 'react-redux'
import { ImageInterface, fileTypes } from '@sio/ui'
import FileUpload from '../options/FileUpload'
import { emailSelectors, useEmail, emailActions } from '../../../../../store'
import { useUpdateProp } from '../hooks/useUpdaters'
import Range from '../components/Range'
import Align from '../options/Align'
import TextField from '../components/TextField'

type ImageSettingsProps = {
  entity: ImageInterface
}

function ImageSettings({ entity }: ImageSettingsProps) {
  const dispatch = useDispatch()
  const updateProp = useUpdateProp(entity)
  const settings = useEmail(emailSelectors.getSettings)
  const file = useEmail(email =>
    emailSelectors.getFileById(email, entity.fileId),
  )

  function removeFile() {
    if (file) {
      dispatch(emailActions.removeEmailFile(file))
      updateProp('fileId')(null)
    }
  }

  function updateFile(file: fileTypes.File) {
    dispatch(emailActions.addEmailFile(file))
    dispatch(
      emailActions.updateEntity<ImageInterface>({
        ...entity,
        fileId: file.id,
        width: detectWidth(`entity-${entity.id}`),
      }),
    )
  }

  function detectWidth(id: string) {
    const imageWrapper = document.getElementById(id)
    return imageWrapper ? imageWrapper.getBoundingClientRect().width : 0
  }

  return (
    <>
      <FileUpload
        path={file ? file.path : ''}
        label="entity_settings.image.upload_file.label"
        updateFile={updateFile}
        removeFile={removeFile}
      />
      <TextField
        label="entity_settings.image_url.label"
        update={updateProp('url')}
        value={entity.url}
      />
      <Range
        label="entity_settings.image_size.label"
        value={entity.width}
        change={updateProp('width')}
        min={5}
        max={settings.maxWidth}
      />
      <Align align={entity.align} update={updateProp('align')} />
    </>
  )
}

export default ImageSettings
