import React from 'react'
import { useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { Settings } from '@sio/ui'
import SettingsContainerUi from './ui/SettingsContainerUi'
import SettingsTitleUi from './ui/SettingsTitleUi'
import {
  useOverlayActionsContext,
  useOverlayVisibilityContext,
} from './SettingsOverlayContext'
import ColorPicker from './options/ColorPicker'
import { emailSelectors, emailActions, useEmail } from '../../../../store'
import MaxWidth from './options/MaxWidth'
import Overlay from '../../Overlay'
import FontSize from './options/FontSize'

export default function GlobalSettings() {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const settings = useEmail(emailSelectors.getSettings)
  const isOverlayVisible = useOverlayVisibilityContext()
  const overlayActions = useOverlayActionsContext()

  function updateSetting<K extends keyof Settings>(setting: K) {
    return function (value: Settings[K]) {
      dispatch(
        emailActions.updateGlobalSettings({
          ...settings,
          [setting]: value,
        }),
      )
    }
  }

  function hideOverlay() {
    overlayActions?.close()
  }

  return (
    <>
      <SettingsTitleUi>{t('global_settings.title')}</SettingsTitleUi>
      <SettingsContainerUi>
        <FontSize
          update={updateSetting('textFontSize')}
          value={settings.textFontSize}
        />
        <ColorPicker
          label="global_settings.background_color.label"
          color={settings.backgroundColor}
          update={updateSetting('backgroundColor')}
        />
        <ColorPicker
          label="global_settings.content_background_color.label"
          color={settings.contentBackgroundColor}
          update={updateSetting('contentBackgroundColor')}
        />
        <ColorPicker
          label="global_settings.link_color.label"
          color={settings.linkColor}
          update={updateSetting('linkColor')}
        />
        <MaxWidth
          label="global_settings.max_width.label"
          maxWidth={settings.maxWidth}
          update={updateSetting('maxWidth')}
        />
        {isOverlayVisible && <Overlay onClick={hideOverlay} />}
      </SettingsContainerUi>
    </>
  )
}
