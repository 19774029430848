import styled from 'styled-components/macro'

type NumberControlButtonUiProps = {
  position: 'left' | 'right'
}

const NumberControlButtonUi = styled.button<NumberControlButtonUiProps>`
  white-space: nowrap;
  padding: 7px 11px;
  line-height: 18px;
  font-size: 18px;
  cursor: pointer;
  color: var(--settings-text-color);
  &:hover {
    color: var(--default-blue-color);
  }
  transition: color 100ms ease-in-out;
  border: none;
  border-top-left-radius: ${p => (p.position === 'left' ? 3 : 0)}px;
  border-bottom-left-radius: ${p => (p.position === 'left' ? 3 : 0)}px;
  border-top-right-radius: ${p => (p.position === 'right' ? 3 : 0)}px;
  border-bottom-right-radius: ${p => (p.position === 'right' ? 3 : 0)}px;
  border-right-width: ${p => (p.position === 'right' ? 1 : 0)}px;
  border-left-width: ${p => (p.position === 'right' ? 0 : 1)}px;
  vertical-align: middle;
  outline: none;
`

export default NumberControlButtonUi
