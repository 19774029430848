import React from 'react'
import { useTranslation } from 'react-i18next'
import Skeleton from 'react-loading-skeleton'
import { managementSelectors, useTypedSelector } from '../../../../store'
import LibraryUi from './ui/LibraryUi'
import LibraryItemUi from './ui/LibraryItemUi'
import LibraryItemTitleUi from './ui/LibraryItemTitleUi'
import LibraryElement from './LibraryElement'
import { LibraryElementTypes } from '../../../../enums/libraryElementTypes'
import LibraryGroupUi from './ui/LibraryGroupUi'
import LibraryItemListUi from './ui/LibraryItemListUi'
import LibraryGroupTitleUi from './ui/LibraryGroupTitleUi'

type LibraryProps = {}

const Library = (props: LibraryProps) => {
  const { t } = useTranslation()
  const isFetching = useTypedSelector(({ management }) =>
    managementSelectors.isFetching(management),
  )

  if (isFetching) {
    return (
      <LibraryUi>
        {Array.from(Array(4).keys())
          .map(index => `${index}`)
          .map(key => (
            <LibraryItemUi key={key}>
              <Skeleton circle width={50} height={50} />
              <LibraryItemTitleUi>
                <Skeleton width={60} height={10} />
              </LibraryItemTitleUi>
            </LibraryItemUi>
          ))}
      </LibraryUi>
    )
  }

  return (
    <LibraryUi>
      <LibraryGroupUi>
        <LibraryGroupTitleUi>
          {t('core.library.groups.content.title')}
        </LibraryGroupTitleUi>
        <LibraryItemListUi>
          <LibraryElement type={LibraryElementTypes.LibraryImage} />
          <LibraryElement type={LibraryElementTypes.LibraryText} />
          {/*<LibraryElement type={LibraryElementTypes.LibraryBulletList} />*/}
          <LibraryElement type={LibraryElementTypes.LibraryButton} />
          <LibraryElement type={LibraryElementTypes.LibraryHorizontalLine} />
        </LibraryItemListUi>
      </LibraryGroupUi>
      <LibraryGroupUi>
        <LibraryGroupTitleUi>
          {t('core.library.groups.layout.title')}
        </LibraryGroupTitleUi>
        <LibraryItemListUi>
          <LibraryElement type={LibraryElementTypes.LibraryLayout_12} />
          <LibraryElement type={LibraryElementTypes.LibraryLayout_6_6} />
          <LibraryElement type={LibraryElementTypes.LibraryLayout_4_4_4} />
          <LibraryElement type={LibraryElementTypes.LibraryLayout_3_3_3_3} />
        </LibraryItemListUi>
      </LibraryGroupUi>
    </LibraryUi>
  )
}

export default Library
