import React from 'react'
import { styleTypes } from '@sio/ui'
import NumberField from '../../components/NumberField'
import Option from '../../components/Option'
import MarginRowUi from './ui/MarginRowUi'

type MarginKeyType = keyof styleTypes.MarginType

export type MarginUpdater = (key: MarginKeyType) => (value: number) => void

export type MarginsUpdater = (
  key1: MarginKeyType,
  key2: MarginKeyType,
  key3: MarginKeyType,
  key4: MarginKeyType,
) => (value: number) => void

type MarginProps = {
  margin: styleTypes.MarginType
  update: MarginUpdater
  updateMargins: MarginsUpdater
}

function allEqual(arr: any[]) {
  return arr.every(v => v === arr[0])
}

function Margin({ margin, update, updateMargins }: MarginProps) {
  const allPropsEqual = allEqual(Object.values(margin))

  return (
    <Option
      label="settings_options.margin.label"
      more
      moreChecked={!allPropsEqual}
    >
      {checked =>
        checked ? (
          <>
            <MarginRowUi>
              <NumberField
                label="settings_options.margin.top.label"
                value={margin.marginTop}
                update={update('marginTop')}
              />
              <NumberField
                label="settings_options.margin.right.label"
                value={margin.marginRight}
                update={update('marginRight')}
              />
            </MarginRowUi>
            <MarginRowUi>
              <NumberField
                label="settings_options.margin.bottom.label"
                value={margin.marginBottom}
                update={update('marginBottom')}
              />
              <NumberField
                label="settings_options.margin.left.label"
                value={margin.marginLeft}
                update={update('marginLeft')}
              />
            </MarginRowUi>
          </>
        ) : (
          <NumberField
            value={margin.marginLeft}
            update={updateMargins(
              'marginTop',
              'marginRight',
              'marginBottom',
              'marginLeft',
            )}
          />
        )
      }
    </Option>
  )
}

export default Margin
