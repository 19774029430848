import React from 'react'
import { TextInterface } from '@sio/ui'
import Padding, { PaddingsUpdater, PaddingUpdater } from '../options/Padding'
import TextAlign from '../options/TextAlign'
import Spacing from '../options/Spacing'
import FontFamily from '../options/FontFamily'
import {
  useUpdateNestedProp,
  useUpdateNestedProps,
  useUpdateProp,
} from '../hooks/useUpdaters'
// import ListStyleType from '../options/ListStyleType'
// import ColorPicker from '../options/ColorPicker'

type TextSettingsProps = {
  entity: TextInterface
}

const TextSettings = ({ entity }: TextSettingsProps) => {
  const updateProp = useUpdateProp(entity)
  const updateNestedProp = useUpdateNestedProp(entity)
  const updateNestedProps = useUpdateNestedProps(entity)
  return (
    <>
      <FontFamily update={updateProp('fontFamily')} value={entity.fontFamily} />
      <Spacing
        lineHeight={entity.lineHeight}
        update={updateProp('lineHeight')}
      />
      <TextAlign
        align={entity.textAlign}
        update={updateProp('textAlign')}
        allowJustify
      />
      {/*<ListStyleType*/}
      {/*  value={entity.listStyleType}*/}
      {/*  update={updateProp('listStyleType')}*/}
      {/*/>*/}
      {/*<ColorPicker*/}
      {/*  color={entity.markerColor}*/}
      {/*  update={updateProp('markerColor')}*/}
      {/*  label="entity_settings.text.marker_color.label"*/}
      {/*/>*/}
      <Padding
        padding={entity.padding}
        update={updateNestedProp('padding') as PaddingUpdater}
        updatePaddings={updateNestedProps('padding') as PaddingsUpdater}
      />
    </>
  )
}

export default TextSettings
