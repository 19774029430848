import styled from 'styled-components/macro'

const OptionLabelUi = styled.div`
  margin-bottom: 10px;
  font-size: 13px;
  font-weight: bold;
  display: flex;
  align-items: center;
  justify-content: space-between;
  align-self: stretch;
  color: var(--settings-label-color);
`

export default OptionLabelUi
