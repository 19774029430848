import React from 'react'
import { Column as ColumnUi, EntityTypes, ColumnInterface } from '@sio/ui'
import ChildrenEntities from '../core/ChildrenEntities'
import { generateId } from '../../store/email/utils'

type ColumnProps = {
  entity: ColumnInterface
}

const Column = ({ entity }: ColumnProps) => {
  return (
    <ColumnUi size={entity.size} padding={entity.padding}>
      <ChildrenEntities parentEntity={entity} />
    </ColumnUi>
  )
}

export function createColumn(size: number = 12): ColumnInterface {
  return {
    id: generateId(),
    childIds: [],
    type: EntityTypes.Column,
    size: size,
    padding: {
      paddingTop: 0,
      paddingRight: 0,
      paddingBottom: 0,
      paddingLeft: 0,
    },
    backgroundColor: 'rgba(0,0,0,0)',
  }
}

export default Column
