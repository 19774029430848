import React, { useState } from 'react'
import { SketchPicker, RGBColor, ColorResult } from 'react-color'
import { colorUtils } from '@sio/ui'
import ColorPickerUi from './ui/ColorPickerUi'
import ColorPreviewUi from './ui/ColorPreviewUi'
import ColorPreviewInnerUi from './ui/ColorPreviewInnerUi'
import ColorMenuButtonUi from './ui/ColorMenuButtonUi'
import PickerPopupUi from './ui/PickerPopupUi'
import OverlayUi from '../../../Overlay'

type ColorProps = {
  color?: string
  onChange: (color: string) => void
}

const defaultColor = 'rgba(0, 0, 0, 0)'

function Color({ color = defaultColor, onChange }: ColorProps) {
  const colorObj = colorUtils.parseToObject(color)
  const [isPickerVisible, setPickerVisible] = useState(false)

  function showPicker() {
    setPickerVisible(true)
  }

  function closePicker() {
    setPickerVisible(false)
  }

  function getPrevColor(): RGBColor {
    return colorUtils.parseToObject(color || defaultColor)
  }

  function hasRgbChanged(color: RGBColor) {
    const { r, g, b } = getPrevColor()
    return color.r !== r || color.g !== g || color.b !== b
  }

  function handleChangeComplete(color: ColorResult) {
    const rgb = { ...color.rgb }
    const prevA = getPrevColor().a

    if (hasRgbChanged(rgb) && prevA === 0) {
      rgb.a = 1
    }
    onChange(colorUtils.getColorAsString(rgb))
  }

  return (
    <ColorMenuButtonUi>
      <ColorPickerUi>
        <ColorPreviewUi>
          <ColorPreviewInnerUi color={color} onClick={showPicker} />
        </ColorPreviewUi>
        {isPickerVisible && (
          <>
            <PickerPopupUi>
              <SketchPicker
                color={colorObj}
                disableAlpha
                onChangeComplete={handleChangeComplete}
              />
            </PickerPopupUi>
            <OverlayUi onClick={closePicker} fixed />
          </>
        )}
      </ColorPickerUi>
    </ColorMenuButtonUi>
  )
}

export default Color
