import styled from 'styled-components/macro'

const MenuConfirmButtonUi = styled.button`
  position: relative;
  background-color: transparent;
  color: #fff;
  font-size: 15px;
  border: none;
  outline: none;
  border-radius: 3px;
  cursor: pointer;
  width: 26px;
  transition: font-size 0.2s ease;
  &:nth-of-type(2n) {
    margin-left: 2px;
  }
  &:disabled {
    font-size: 14px;
    opacity: 0.5;
    cursor: default;
    pointer-events: none;
  }
`

export default MenuConfirmButtonUi
