import React from 'react'
import { EntityInterfaceWithChildIds } from '@sio/ui'
import { emailSelectors, useTypedSelector } from '../../../store'
import createEntityComponent from '../../../utils/createEntityComponent'
import DropBoxEntity from './DropBoxEntity'
import DropBoxEmpty from './DropBoxEmpty'

type ChildrenEntitiesPropsType = {
  parentEntity: EntityInterfaceWithChildIds
}

function ChildrenEntities({ parentEntity }: ChildrenEntitiesPropsType) {
  const entities = useTypedSelector(state =>
    emailSelectors.getChildrenEntities(state.email, parentEntity.childIds),
  )

  if (entities.length === 0) {
    return <DropBoxEmpty parentEntity={parentEntity} />
  }

  return (
    <>
      {entities.map((entity, index) => (
        <DropBoxEntity
          parentEntity={parentEntity}
          position={index}
          key={entity.id}
        >
          {createEntityComponent(entity, index, parentEntity.id)}
        </DropBoxEntity>
      ))}
    </>
  )
}

export default ChildrenEntities
