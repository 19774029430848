import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useEmail, emailSelectors } from '../../../store'
import * as emailActions from '../../../store/email/actions'
import { useDispatch } from 'react-redux'
import HeaderBlueButtonUi from './ui/HeaderBlueButtonUi'
import HeaderButtonLoaderUi from './ui/HeaderButtonLoaderUi'
import * as api from '../../../api/emailApi'
import { useIsTemplateContext } from '../../../context/isTemplateContext'
import { InternalError } from '../../../errors/InternalError'
import { BadRequest } from '../../../errors/BadRequest'
import { NotFound } from '../../../errors/NotFound'
import { EntitiesTypes } from '@sio/ui'
import { removeEmptyImageEntities } from '../../../utils/removeEmptyImageEntities'
import { useUnsavedData } from '../../../hooks/use-unsaved-data'
import { useRollbar } from '@rollbar/react'

function SaveButton() {
  const { t } = useTranslation()
  const isTemplate = useIsTemplateContext()
  const settings = useEmail(emailSelectors.getSettings)
  const entities = useEmail(emailSelectors.getEntities)
  const files = useEmail(emailSelectors.getFiles)
  const id = useEmail(emailSelectors.getId)
  const [loading, setLoading] = useState(false)
  const dispatch = useDispatch()
  const [disabled, setDisabled] = useState(false)

  const rollbar = useRollbar()

  const { EmailUnsavedModal, deleteDbRecord } = useUnsavedData({})

  async function submit() {
    try {
      setLoading(true)
      setDisabled(true)
      // TODO: eventually remove removeEmptyImageEntities when we can run migrations
      const filteredEntities = removeEmptyImageEntities(entities)
      const usedFileIds = filteredEntities
        .filter(entity => Number.isInteger(entity.fileId))
        .map(entity => Number(entity.fileId))
      const usedFiles = files.filter(file => usedFileIds.includes(file.id))
      await api.save(
        {
          id: id,
          content: { settings, entities: filteredEntities },
          files: usedFiles,
        },
        isTemplate,
      )
      await deleteDbRecord()
      dispatch(emailActions.updateHash(filteredEntities as EntitiesTypes[]))
      setLoading(false)
    } catch (error) {
      if (error instanceof InternalError) {
        rollbar.error('Internal server error')
        alert('Something went wrong. Please contact support')
        return
      } else if (error instanceof NotFound) {
        alert('Page not found')
        window.location.assign(process.env.REACT_APP_API_HOST)
        // handleError(error)
      } else if (error instanceof BadRequest) {
        console.log(error.messages)
      }
    } finally {
      setDisabled(false)
      setLoading(false)
    }
  }

  return (
    <>
      <HeaderBlueButtonUi
        onClick={submit}
        disabled={!Boolean(id) || disabled}
        title={t('core.header.save_changes')}
      >
        {loading && <HeaderButtonLoaderUi />}
        {t('core.header.save_changes')}
      </HeaderBlueButtonUi>
      {EmailUnsavedModal}
    </>
  )
}

export default SaveButton
