import React from 'react'
import { useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { EntityInterfaceWithChildIds } from '@sio/ui'
import * as managementActions from '../../../store/management/actions'
import DropBoxUi from './ui/DropBoxUi'
import DropBoxEmptyPlaceholderUi from './ui/DropBoxEmptyPlaceholderUi'
import useDropBox from './useDropBox'

type DropBoxEmptyProps = {
  parentEntity: EntityInterfaceWithChildIds
}

const DropBoxEmpty = ({ parentEntity }: DropBoxEmptyProps) => {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const { canDrop, connect } = useDropBox(parentEntity)

  const showPopupLibrary = (event: React.SyntheticEvent) => {
    event.stopPropagation()
    dispatch(managementActions.setInsertToForPopupLibrary(parentEntity.id))
  }

  return (
    <DropBoxUi ref={connect} canDrop={canDrop}>
      <tbody>
        <tr>
          <DropBoxEmptyPlaceholderUi
            onClick={showPopupLibrary}
            canDrop={canDrop}
          >
            {t('core.empty_dropbox_text')}
          </DropBoxEmptyPlaceholderUi>
        </tr>
      </tbody>
    </DropBoxUi>
  )
}

export default DropBoxEmpty
