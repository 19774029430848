import React, { useState } from 'react'
import OptionUi from './ui/OptionUi'
import OptionLabelUi from './ui/OptionLabelUi'
import { useTranslation } from 'react-i18next'
import OptionValueUi from './ui/OptionValueUi'
import More from './More'

type OptionProps = {
  children: ((checked: boolean) => React.ReactNode) | React.ReactNode
  label: string
  more?: boolean
  moreChecked?: boolean
}

const Option = ({
  children,
  label,
  more,
  moreChecked = false,
}: OptionProps) => {
  const { t } = useTranslation()
  const [checked, setChecked] = useState(moreChecked)
  function toggleMore() {
    setChecked(!checked)
  }

  return (
    <OptionUi>
      <OptionLabelUi>
        {t(label)}
        {more && <More checked={checked} toggle={toggleMore} />}
      </OptionLabelUi>
      <OptionValueUi>
        {typeof children === 'function' ? children(checked) : children}
      </OptionValueUi>
    </OptionUi>
  )
}

export default Option
