import styled from 'styled-components/macro'

const MenuConfirmButtonHaveChangesUi = styled.div`
  position: absolute;
  top: 0;
  right: 2px;
  background-color: red;
  height: 5px;
  width: 5px;
  border-radius: 3px;
`

export default MenuConfirmButtonHaveChangesUi
