import React from 'react'
import PopupLibraryItemListUi from './ui/PopupLibraryItemListUi'
import PopupLibraryItem from './PopupLibraryItem'
import { LibraryElementTypes } from '../../../enums/libraryElementTypes'

type LayoutElementsLibraryProps = {
  addEntity: (
    type: LibraryElementTypes,
  ) => (event: React.SyntheticEvent) => void
}

const LayoutElementsLibrary = ({ addEntity }: LayoutElementsLibraryProps) => {
  return (
    <PopupLibraryItemListUi>
      <PopupLibraryItem
        type={LibraryElementTypes.LibraryLayout_12}
        handleClick={addEntity(LibraryElementTypes.LibraryLayout_12)}
      />
      <PopupLibraryItem
        type={LibraryElementTypes.LibraryLayout_6_6}
        handleClick={addEntity(LibraryElementTypes.LibraryLayout_6_6)}
      />
      <PopupLibraryItem
        type={LibraryElementTypes.LibraryLayout_4_4_4}
        handleClick={addEntity(LibraryElementTypes.LibraryLayout_4_4_4)}
      />
    </PopupLibraryItemListUi>
  )
}

export default LayoutElementsLibrary
