import React from 'react'
import ReactModal from 'react-modal'
import ModalHeaderUi from './ui/ModalHeaderUi'
import ModalClose from './ModalClose'
import ModalBodyUi from './ui/ModalBodyUi'

if (process.env.NODE_ENV !== 'test') ReactModal.setAppElement('#root')

const customStyles = {
  overlay: {
    zIndex: 200,
  },
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    boxShadow: '0 0 3px 0px #ccc',
    border: 'none',
  },
}

type ModalProps = {
  maxWidth?: number | '100%'
  height?: number | '100%' | 'auto'
  closeVisible: boolean
  isOpen: boolean
  header: string
  handleClose: () => void
  children: React.ReactNode
}

export default function Modal({
  closeVisible,
  isOpen,
  header,
  handleClose,
  children,
  maxWidth = 400,
  height = 'auto',
}: ModalProps) {
  return (
    <RawModal
      isOpen={isOpen}
      maxWidth={maxWidth}
      height={height}
      header={header}
      handleClose={handleClose}
    >
      <ModalHeaderUi>{header}</ModalHeaderUi>
      {closeVisible && <ModalClose handleClose={handleClose} />}
      <ModalBodyUi>{children}</ModalBodyUi>
    </RawModal>
  )
}

function RawModal({
  isOpen,
  header,
  handleClose,
  children,
  maxWidth = 400,
  height = 'auto',
}: Omit<ModalProps, 'closeVisible'>) {
  return (
    <ReactModal
      isOpen={isOpen}
      style={{
        overlay: {
          ...customStyles.overlay,
        },
        content: {
          ...customStyles.content,
          maxWidth,
          height,
        },
      }}
      contentLabel={header}
      onRequestClose={handleClose}
    >
      {/*@ts-ignore*/}
      {children}
    </ReactModal>
  )
}

export { ModalHeaderUi, ModalClose, RawModal }
