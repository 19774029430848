import styled, { css } from 'styled-components/macro'

const RangeTextInputUi = styled.input.attrs({
  type: 'number',
})`
  width: 60px;
  height: 40px;
  float: right;
  text-align: center;
  padding: 0 5px;
  outline: none;
  border: solid 1px #ebedf0;
  font-size: 13px;
  border-radius: 3px;
  font-weight: bold;
  color: #5c687e;
  transition: all .25s ease;
   -moz-appearance:textfield;
  &::-webkit-inner-spin-button ${css`
     {
      appearance: none;
    }
  `}
  &::-webkit-textfield-decoration-container ${css`
     {
      margin-left: 0;
      transition: all 0.25s ease;
    }
  `}
  &:hover {
      -moz-appearance: unset;
      &::-webkit-textfield-decoration-container ${css`
         {
          margin-left: -10px;
          transition: all 0.25s ease;
        }
      `}
      
    &::-webkit-inner-spin-button ${css`
       {
        appearance: -webkit-inner-spin-button;
        height: 27px;
        position: absolute;
        top: 6px;
        right: 5px;
        bottom: 0;
        cursor: pointer;
      }
    `}
  }
`

export default RangeTextInputUi
