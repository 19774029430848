import React from 'react'
import { HorizontalLineInterface } from '@sio/ui'
import {
  useUpdateNestedProp,
  useUpdateNestedProps,
  useUpdateProp,
} from '../hooks/useUpdaters'
import BorderRow from '../options/Border/BorderRow'
import Padding, { PaddingsUpdater, PaddingUpdater } from '../options/Padding'

type HorizontalLineSettingsProps = {
  entity: HorizontalLineInterface
}

const HorizontalLineSettings = ({ entity }: HorizontalLineSettingsProps) => {
  const updateProp = useUpdateProp(entity)
  const updateNestedProp = useUpdateNestedProp(entity)
  const updateNestedProps = useUpdateNestedProps(entity)
  return (
    <>
      <BorderRow
        color={entity.color}
        style={entity.style}
        width={entity.borderWidth}
        updateWidth={updateProp('borderWidth')}
        updateStyle={updateProp('style')}
        updateColor={updateProp('color')}
      />
      <Padding
        padding={entity.padding}
        update={updateNestedProp('padding') as PaddingUpdater}
        updatePaddings={updateNestedProps('padding') as PaddingsUpdater}
      />
    </>
  )
}

export default HorizontalLineSettings
