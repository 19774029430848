import React from 'react'
import { Button as ButtonUi, EntityTypes, ButtonInterface } from '@sio/ui'
import BaseEntity from '../core/BaseEntity/BaseEntity'
import { generateId } from '../../store/email/utils'
import { BorderStyleEnum } from '../../enums/borderStyles'

type ButtonProps = {
  entity: ButtonInterface
  position: number
  parentId: string
}

const Button = ({ entity, position, parentId }: ButtonProps) => {
  return (
    <BaseEntity entity={entity} position={position} parentId={parentId}>
      <ButtonUi
        backgroundColor={entity.backgroundColor}
        url={entity.url}
        text={entity.text}
        color={entity.color}
        borderWidth={entity.borderWidth}
        borderStyle={entity.borderStyle}
        borderColor={entity.borderColor}
        borderRadius={entity.borderRadius}
        padding={entity.padding}
        margin={entity.margin}
        align={entity.align}
        fontSize={entity.fontSize}
        lineHeight={entity.lineHeight}
        fontFamily={entity.fontFamily}
        fontWeightAndStyle={entity.fontWeightAndStyle}
      />
    </BaseEntity>
  )
}

export function createButton(): ButtonInterface {
  return {
    id: generateId(),
    type: EntityTypes.Button,
    backgroundColor: 'rgba(20, 158, 252, 1)',
    color: 'rgba(255, 255, 255, 1)',
    align: 'center',
    text: 'button text',
    lineHeight: 1.3,
    fontSize: 14,
    url: '',
    borderRadius: 0,
    borderWidth: {
      borderTopWidth: 0,
      borderRightWidth: 0,
      borderBottomWidth: 0,
      borderLeftWidth: 0,
    },
    borderColor: {
      borderTopColor: 'rgba(0, 0, 0, 0)',
      borderRightColor: 'rgba(0, 0, 0, 0)',
      borderBottomColor: 'rgba(0, 0, 0, 0)',
      borderLeftColor: 'rgba(0, 0, 0, 0)',
    },
    borderStyle: {
      borderTopStyle: BorderStyleEnum.none,
      borderRightStyle: BorderStyleEnum.none,
      borderBottomStyle: BorderStyleEnum.none,
      borderLeftStyle: BorderStyleEnum.none,
    },
    padding: {
      paddingTop: 15,
      paddingRight: 15,
      paddingBottom: 15,
      paddingLeft: 15,
    },
    margin: {
      marginTop: 0,
      marginRight: 0,
      marginBottom: 20,
      marginLeft: 0,
    },
  }
}

export default Button
