import styled, { css } from 'styled-components/macro'

type AddEntityUiProps = {
  position: 'top' | 'bottom'
  backgroundColor: string
}

const AddEntityUi = styled.div<AddEntityUiProps>`
  position: absolute;
  color: #ffffff;
  padding: 2px 4px 0;
  border-radius: 15px;
  z-index: 100;
  left: calc(50% - 5px);
  cursor: pointer;
  background-color: ${p => p.backgroundColor};
  ${p =>
    p.position === 'top' &&
    css`
      top: -10px;
    `}
  ${p =>
    p.position === 'bottom' &&
    css`
      bottom: -10px;
    `}
`

export default AddEntityUi
