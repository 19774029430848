import React from 'react'
import Option from '../../components/Option'
import NumberField from '../../components/NumberField'

type BorderRadiusProps = {
  borderRadius: number
  update: (value: number) => void
}

function BorderRadius({ borderRadius, update }: BorderRadiusProps) {
  return (
    <Option label="settings_options.border_radius.label">
      <NumberField value={borderRadius} update={update} />
    </Option>
  )
}

export default BorderRadius
