import styled from 'styled-components/macro'

const ColorRemoverWrapperUi = styled.div`
  width: 30px;
  margin-left: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
`

export default ColorRemoverWrapperUi
