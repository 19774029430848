import styled from 'styled-components/macro'

type EntityMoverOverlayUiProps = {
  visible: boolean
}

const EntityMoverOverlayUi = styled.div<EntityMoverOverlayUiProps>`
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-left: ${p => (p.visible ? '40px' : '0')};
  top: 50%;
  transform: translateY(-50%);
  width: ${p => (p.visible ? '100px' : '0')};
  transition: width, padding-left 200ms ease;
  height: 40px;
  z-index: 30;
`

export default EntityMoverOverlayUi
