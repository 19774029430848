import {
  EntitiesTypes,
  SectionInterface,
  Email,
  Settings,
  fileTypes,
  BaseEntityInterface,
} from '@sio/ui'
import { ContactField } from '@sio/ui/build/types/emailTypes'

export const ADD_SECTION = 'ADD_SECTION'
export const ADD_ENTITY = 'ADD_ENTITY'
export const ADD_ENTITIES = 'ADD_ENTITIES'
export const MOVE_ENTITY = 'MOVE_ENTITY'
export const UPDATE_ENTITY = 'UPDATE_ENTITY'
export const REMOVE_ENTITY = 'REMOVE_ENTITY'
export const COPY_ENTITY = 'COPY_ENTITY'
export const SET_ENTITIES = 'SET_ENTITIES'
export const LOAD_EMAIL = 'LOAD_EMAIL'
export const LOAD_CONTACT_FIELDS = 'LOAD_CONTACT_FIELDS'
export const UPDATE_GLOBAL_SETTINGS = 'UPDATE_SETTINGS'
export const ADD_EMAIL_FILE = 'ADD_EMAIL_FILE'
export const REMOVE_EMAIL_FILE = 'REMOVE_EMAIL_FILE'
export const SET_EMAIL_FILES = 'SET_EMAIL_FILES'
export const REMOVE_POSSIBLE_FILE_USAGE = 'REMOVE_POSSIBLE_FILE_USAGE'
export const UPDATE_HASH = 'UPDATE_HASH'

interface AddSectionPayload {
  entity: SectionInterface
  parentId: string
}

export interface AddEntityAction {
  type: typeof ADD_ENTITY
  payload: {
    entity: EntitiesTypes
    parentId: string
    position: number
  }
}

export interface UpdateHashAction {
  type: typeof UPDATE_HASH
  payload: {
    entities: EntitiesTypes[]
  }
}

export interface AddEntitiesAction {
  type: typeof ADD_ENTITIES
  payload: {
    entities: EntitiesTypes[]
    ascendantId: string
    parentId: string
    position: number
  }
}

export interface RemoveEntityAction {
  type: typeof REMOVE_ENTITY
  payload: BaseEntityInterface
}

export interface CopyEntityAction {
  type: typeof COPY_ENTITY
  payload: {
    entityId: string
  }
}

export interface MoveEntityAction {
  type: typeof MOVE_ENTITY
  payload: {
    entityId: string
    toId: string
    position: number
  }
}

export interface SetEntitiesAction {
  type: typeof SET_ENTITIES
  payload: {
    entities: BaseEntityInterface[]
  }
}

export interface AddSectionAction {
  type: typeof ADD_SECTION
  payload: AddSectionPayload
}

export interface UpdateEntityAction<T> {
  type: typeof UPDATE_ENTITY
  payload: T
}

export interface LoadEmailAction {
  type: typeof LOAD_EMAIL
  payload: Email
}

export interface LoadContactFieldsAction {
  type: typeof LOAD_CONTACT_FIELDS
  payload: ContactField[]
}

export interface UpdateGlobalSettingsAction {
  type: typeof UPDATE_GLOBAL_SETTINGS
  payload: Settings
}

export interface AddEmailFileAction {
  type: typeof ADD_EMAIL_FILE
  payload: fileTypes.File
}

export interface RemoveEmailFileAction {
  type: typeof REMOVE_EMAIL_FILE
  payload: fileTypes.File
}

export interface SetEmailFilesAction {
  type: typeof SET_EMAIL_FILES
  payload: fileTypes.File[]
}

export interface RemovePossibleFileIdUsageAction {
  type: typeof REMOVE_POSSIBLE_FILE_USAGE
  payload: fileTypes.File
}

export type EmailActionTypes =
  | AddSectionAction
  | LoadEmailAction
  | AddEntityAction
  | UpdateEntityAction<EntitiesTypes>
  | MoveEntityAction
  | RemoveEntityAction
  | AddEntitiesAction
  | CopyEntityAction
  | UpdateGlobalSettingsAction
  | AddEmailFileAction
  | RemoveEmailFileAction
  | RemovePossibleFileIdUsageAction
  | UpdateHashAction
  | LoadContactFieldsAction
  | SetEntitiesAction
  | SetEmailFilesAction
