import styled from 'styled-components/macro'

const DeviceSwitcherUi = styled.div`
  align-items: center;
  justify-content: flex-end;
  display: flex;
  margin-right: 10px;
`

export default DeviceSwitcherUi
