import { Configuration } from 'rollbar'
import { partialMatchIgnoredMessages } from './constants'

export const checkIgnore: Configuration['checkIgnore'] = (_, args) => {
  const message = (args[1] as { message: string }).message
  return partialMatchIgnoredMessages.some(partialMatchIgnoreMessage =>
    checkIsPartialMatchStringMatched(message, partialMatchIgnoreMessage),
  )
}

const checkIsPartialMatchStringMatched = (
  strFromProps: string,
  partialMatchStr: string[],
) => {
  let str = strFromProps
  for (const partOfMessage of partialMatchStr) {
    if (str.includes(partOfMessage)) {
      str = removeMathchedPartialStr(str, partOfMessage)
    } else {
      return false
    }
  }
  return true
}

const removeMathchedPartialStr = (str: string, matchedPartialStr: string) => {
  const startIndex = str.indexOf(matchedPartialStr)
  const removePositionUntil = startIndex + matchedPartialStr.length
  return str.slice(removePositionUntil)
}
