import { createSection } from '../components/entities/Section'
import { LibraryElementTypes } from '../enums/libraryElementTypes'
import { createText } from '../components/entities/Text'
import { createImage } from '../components/entities/Image'
import { createButton } from '../components/entities/Button'
import { createBulletList } from '../components/entities/BulletList'
import { createHorizontalLine } from '../components/entities/HorizontalLine'

export default function createEntity(type: LibraryElementTypes) {
  switch (type) {
    case LibraryElementTypes.LibraryLayout_12:
      return createSection()
    case LibraryElementTypes.LibraryImage:
      return createImage()
    case LibraryElementTypes.LibraryText:
      return createText()
    case LibraryElementTypes.LibraryButton:
      return createButton()
    case LibraryElementTypes.LibraryBulletList:
      return createBulletList()
    case LibraryElementTypes.LibraryHorizontalLine:
      return createHorizontalLine()
    default:
      throw new Error(`Entity creator for type ${type} was not found`)
  }
}
