import React from 'react'
import EntityMenuItemUi from './ui/EntityMenuItemUi'

type EntityMenuItemProps = {
  className: string
  onClick: (event: React.SyntheticEvent) => void
  backgroundColor: string
}

const EntityMenuItem = ({
  className,
  onClick,
  backgroundColor,
}: EntityMenuItemProps) => {
  return (
    <EntityMenuItemUi backgroundColor={backgroundColor}>
      <span className={className} onClick={onClick} />
    </EntityMenuItemUi>
  )
}

export default EntityMenuItem
