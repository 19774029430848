import styled from 'styled-components/macro'

const LanguageSwitcherUi = styled.div`
  margin-left: 10px;
  display: flex;
  align-items: center;
  overflow-x: scroll;
  &::-webkit-scrollbar {
    width: 6px;
    height: 6px;
  }
  &::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
  }
  &::-webkit-scrollbar-thumb {
    background-color: rgba(204, 213, 225, 1);
    border-radius: 10px;
  }
`

export default LanguageSwitcherUi
