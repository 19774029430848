import {
  SET_EDITING_ENTITY_ID,
  SET_FETCHED,
  SET_GLOBAL_SETTINGS_EDITING,
  SET_INSERT_TO_FOR_POPUP_LIBRARY,
  SET_IS_DESKTOP,
  SetEditingEntityIdAction,
  SetFetchedAction,
  SetGlobalSettingsEditingAction,
  SetInsertToForPopupLibraryAction,
  TOGGLE_GLOBAL_SETTINGS_EDITING,
  ToggleGlobalSettingsEditingAction,
} from './actionTypes'

export function setEditingEntityId(
  entityId: string | null,
): SetEditingEntityIdAction {
  return {
    type: SET_EDITING_ENTITY_ID,
    payload: entityId,
  }
}

export function setGlobalSettingsEditing(
  val: boolean,
): SetGlobalSettingsEditingAction {
  return {
    type: SET_GLOBAL_SETTINGS_EDITING,
    payload: val,
  }
}

export function toggleGlobalSettingsEditing(): ToggleGlobalSettingsEditingAction {
  return {
    type: TOGGLE_GLOBAL_SETTINGS_EDITING,
  }
}

export function setFetched(): SetFetchedAction {
  return {
    type: SET_FETCHED,
  }
}

export function setInsertToForPopupLibrary(
  entityId: string | null,
  position: number = 0,
): SetInsertToForPopupLibraryAction {
  return {
    type: SET_INSERT_TO_FOR_POPUP_LIBRARY,
    payload: {
      entityId,
      position,
    },
  }
}

export function setIsDesktop(value: boolean) {
  return {
    type: SET_IS_DESKTOP,
    payload: value,
  }
}
