export const partialMatchIgnoredMessages: Array<Array<string>> = [
  // Dexie internal errors
  [
    'UnknownError Error creating or migrating Records table in database',
    'UnknownError: Error creating or migrating Records table in database',
  ],
  ['UnknownError Internal error.', 'UnknownError: Internal error.'],
  [
    'InvalidStateError A mutation operation was attempted on a database that did not allow mutations.',
    'InvalidStateError: A mutation operation was attempted on a database that did not allow mutations.',
  ],
  [
    'UnknownError Error creating Records table (',
    'UnknownError Error creating Records table (',
    'UnknownError: Error creating Records table (',
    ') - database or disk is full',
  ],
  [
    'DatabaseClosedError: QuotaExceededError Encountered full disk while opening backing store for indexedDB.open.',
    'QuotaExceededError: Encountered full disk while opening backing store for indexedDB.open.',
  ],
]

export const ignoredMessages = [
  'ResizeObserver loop completed with undelivered notifications.',
  'ResizeObserver loop limit exceeded',
]
