import React, { useState } from 'react'
import { ErrorBoundary } from 'react-error-boundary'
import { SkeletonTheme } from 'react-loading-skeleton'
import './App.css'
import Layout from './components/core/Layout'
import Header from './components/core/Header'
import Sidebar from './components/core/Sidebar'
import Content from './components/core/Content'
import PopupLibrary from './components/core/PopupLibrary'
import { IsTemplateContext } from './context/isTemplateContext'
import { ExitUrlContext } from './context/ExitUrlContext'
import {
  ErrorBoundary as RollbarErrorBoundary,
  Provider as RollbarProvider,
} from '@rollbar/react'
import rollbarConfig from './rollbar'

function ErrorFallback({ error, componentStack, resetErrorBoundary }: any) {
  return (
    <div role="alert">
      <p>Something went wrong:</p>
      <pre>{error.message}</pre>
      <pre>{componentStack}</pre>
      <button onClick={resetErrorBoundary}>Try again</button>
    </div>
  )
}

export type AppProps = {
  id: number
  isTemplate: boolean
}

function App({ id, isTemplate }: AppProps) {
  const [exitUrl, setExitUrl] = useState('')

  if (!id) {
    window.location.assign(process.env.REACT_APP_API_HOST)
    return <div>Not found</div>
  }

  return (
    <RollbarProvider config={rollbarConfig}>
      <RollbarErrorBoundary>
        <SkeletonTheme baseColor="#ccc" highlightColor="#f5f5f5">
          <IsTemplateContext.Provider value={isTemplate}>
            <ExitUrlContext.Provider value={exitUrl}>
              <ErrorBoundary FallbackComponent={ErrorFallback}>
                <Layout>
                  <Header />
                  <Sidebar />
                  <Content
                    isTemplate={isTemplate}
                    id={id}
                    setExitUrl={setExitUrl}
                  />
                  <PopupLibrary />
                </Layout>
              </ErrorBoundary>
            </ExitUrlContext.Provider>
          </IsTemplateContext.Provider>
        </SkeletonTheme>
      </RollbarErrorBoundary>
    </RollbarProvider>
  )
}

export default App
