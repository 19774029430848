import styled from 'styled-components/macro'

type HeaderButtonUiProps = {
  active?: boolean
}

const HeaderButtonUi = styled.button<HeaderButtonUiProps>`
  margin-right: 2px;
  margin-left: 2px;
  font-size: var(--default-font-size);
  color: var(--default-text-color);
  background-color: #fff;
  border: 1px solid transparent;
  border-radius: 4px;
  text-align: center;
  padding: 8px 10px;
  cursor: pointer;
  outline: none;
  transition: background-color 0.25s ease;
  font-weight: ${p => (p.active ? '600' : '400')};
  &:hover {
    background-color: rgba(255, 255, 255, 0.8);
  }
  &:disabled {
    opacity: 0.5;
    cursor: default;
    pointer-events: none;
  }
`

export default HeaderButtonUi
