import React from 'react'
import styled from 'styled-components'
import Skeleton from 'react-loading-skeleton'

const ImageWithContent = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
`

const SkeletonContent = () => {
  return (
    <div style={{ width: '50%', margin: 'auto', padding: 20 }}>
      <ImageWithContent>
        <div style={{ width: '29%' }}>
          <Skeleton height={50} />
        </div>
        <div style={{ width: '70%' }}>
          <Skeleton height={50} />
        </div>
      </ImageWithContent>
      <Skeleton count={4} />
    </div>
  )
}

export default SkeletonContent
