import { combineReducers, createStore, Store } from 'redux'
import { shallowEqual, TypedUseSelectorHook, useSelector } from 'react-redux'
import { composeWithDevTools } from 'redux-devtools-extension'
import { EmailState } from '@sio/ui'
import { emailReducer, selectors as emailSelectors } from './email/reducers'
import * as emailActions from './email/actions'
import * as managementActions from './management/actions'
import * as filesActions from './files/actions'
import {
  managementReducer,
  selectors as managementSelectors,
} from './management/reducer'
import { ManagementState } from './management/type'
import {
  filesReducer,
  FilesState,
  selectors as filesSelectors,
} from './files/reducer'

export interface RootState {
  readonly email: EmailState
  readonly management: ManagementState
  readonly files: FilesState
}

const rootReducer = combineReducers({
  email: emailReducer,
  management: managementReducer,
  files: filesReducer,
})

export function configureStore(): Store<RootState> {
  return process.env.NODE_ENV !== 'production'
    ? createStore(rootReducer, {}, composeWithDevTools())
    : createStore(rootReducer)
}

export const useTypedSelector: TypedUseSelectorHook<RootState> = useSelector

export function useEmail<T>(selector: (emailState: EmailState) => T): T {
  return useTypedSelector(state => selector(state.email), shallowEqual)
}

export function useManagement<T>(
  selector: (emailState: ManagementState) => T,
): T {
  return useTypedSelector(state => selector(state.management), shallowEqual)
}

export function useFiles<T>(selector: (emailState: FilesState) => T): T {
  return useTypedSelector(state => selector(state.files), shallowEqual)
}

export {
  emailSelectors,
  managementSelectors,
  emailActions,
  managementActions,
  filesSelectors,
  filesActions,
}
