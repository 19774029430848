import { v4 as uuid } from 'uuid'
import {
  BaseEntityInterface,
  BaseEntityWithChildIds,
  EntityTypes,
} from '@sio/ui'

export const generateId = () => uuid()

export function getAllDescendants(
  entities: BaseEntityInterface[],
  entityId: string,
): BaseEntityInterface[] {
  const entity = getEntity(entities, entityId)
  const initialValue = [] as BaseEntityInterface[]

  if (!entity.childIds) {
    return initialValue
  }

  return entity.childIds.reduce(
    (acc, childId) => [
      ...acc,
      getEntity(entities, childId),
      ...getAllDescendants(entities, childId),
    ],
    initialValue,
  )
}

export function getAllDescendantsIds(
  entities: BaseEntityInterface[],
  entityId: string,
): string[] {
  const entity = getEntity(entities, entityId)
  const initialValue: string[] = []

  if (!entity.childIds) {
    return initialValue
  }

  return entity.childIds.reduce(
    (acc, entityId) => [
      ...acc,
      entityId,
      ...getAllDescendantsIds(entities, entityId),
    ],
    initialValue,
  )
}

export function insertChildId(
  childIds: string[] = [],
  childId: string,
  position: number,
) {
  return [...childIds.slice(0, position), childId, ...childIds.slice(position)]
}

export function copyEntityAndItsDescendants(
  entities: BaseEntityInterface[],
  entityId: string,
): { [index: string]: BaseEntityInterface } {
  const descendants = getAllDescendants(entities, entityId)
  const sourceEntity = getEntity(entities, entityId)

  return [sourceEntity].concat(descendants).reduceRight(
    (acc, descendant) => ({
      ...acc,
      [descendant.id]: {
        ...copyEntity(descendant),
        ...(descendant.childIds
          ? {
              childIds: descendant.childIds.map(childId => acc[childId].id),
            }
          : {}),
      },
    }),
    {} as { [index: string]: BaseEntityInterface },
  )
}

export function copyEntity(entity: BaseEntityInterface): BaseEntityInterface {
  const newEntity = {
    ...entity,
    id: generateId(),
  }

  if (entity.childIds) {
    newEntity.childIds = [...entity.childIds]
  }

  return newEntity
}

export function getEntity(
  entities: BaseEntityInterface[],
  entityId: string,
): BaseEntityInterface {
  const entity = entities.find(e => e.id === entityId)
  if (!entity) {
    throw new Error(`Entity not found by id ${entityId}`)
  }
  return entity
}

export function getBodyEntity(
  entities: BaseEntityInterface[],
): BaseEntityInterface {
  const entity = entities.find(e => e.type === EntityTypes.Body)
  if (!entity) {
    throw new Error(`Body Entity not found`)
  }
  return entity
}

export function getParentEntity(
  entities: BaseEntityInterface[],
  entityId: string,
): BaseEntityWithChildIds {
  const parentEntity = entities.find(
    e => e.childIds && e.childIds.includes(entityId),
  )
  if (!parentEntity) {
    throw new Error(`Parent entity not found by childId = ${entityId}`)
  }

  return parentEntity as BaseEntityWithChildIds
}

export function updateEntity(
  entities: BaseEntityInterface[],
  updatedEntity: BaseEntityInterface,
) {
  return entities.filter(e => e.id !== updatedEntity.id).concat([updatedEntity])
}
