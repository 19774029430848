import React from 'react'
import HeaderUi from './ui/HeaderUi'
import Logo from './Logo'
import OpenSettings from './OpenSettings'
// import UndoRedo from './UndoRedo'
import HeaderControlsGroupUi from './ui/HeaderControlsGroupUi'
import HeaderActionsUi from './ui/HeaderActionsUi'
import SaveButton from './SaveButton'
import ExitButton from './ExitButton'

const Header = () => {
  return (
    <HeaderUi>
      <Logo />
      <HeaderControlsGroupUi>
        {/*<UndoRedo />*/}
        <OpenSettings />
      </HeaderControlsGroupUi>
      <HeaderActionsUi>
        <SaveButton />
        <ExitButton />
      </HeaderActionsUi>
    </HeaderUi>
  )
}

export default Header
