import styled from 'styled-components/macro'

const MenuLinkUi = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 0 5px;
  flex: 1;
`

export default MenuLinkUi
