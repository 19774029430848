import React from 'react'
import { useDispatch } from 'react-redux'
import DeviceSwitcherUi from './ui/DeviceSwitcherUi'
import DeviceButtonUi from './ui/DeviceButtonUi'
import {
  managementSelectors,
  useManagement,
  managementActions,
} from '../../../../store'

function DeviceSwitcher() {
  const isDesktop = useManagement(managementSelectors.isDesktop)
  const dispatch = useDispatch()

  return (
    <DeviceSwitcherUi>
      <DeviceButtonUi
        active={isDesktop}
        onClick={() => dispatch(managementActions.setIsDesktop(true))}
      >
        <i className="fas fa-desktop" />
      </DeviceButtonUi>
      <DeviceButtonUi
        active={!isDesktop}
        onClick={() => dispatch(managementActions.setIsDesktop(false))}
      >
        <i className="fas fa-mobile-alt" />
      </DeviceButtonUi>
    </DeviceSwitcherUi>
  )
}

export default DeviceSwitcher
