import React, { useEffect, useState } from 'react'
import { ColorResult, RGBColor, SketchPicker } from 'react-color'
import { colorUtils } from '@sio/ui'
import ColorPreviewUi from './ui/ColorPreviewUi'
import SmallColorPickerUi from './ui/ColorPickerUi'
import ColorPreviewInnerUi from './ui/ColorPreviewInnerUi'
import {
  useOverlayActionsContext,
  useOverlayVisibilityContext,
} from '../../SettingsOverlayContext'
import PickerPopupUi from './ui/PickerPopupUi'
import ColorHexUi from './ui/ColorHexUi'

type SmallColorPickerProps = {
  color: string
  update: (value: string) => void
}

function SmallColorPicker({ color, update }: SmallColorPickerProps) {
  const colorObj = colorUtils.parseToObject(color)
  const isOverlayVisible = useOverlayVisibilityContext()
  const overlayActions = useOverlayActionsContext()
  const [prevIsOverlayVisible, setPrevOverlayVisible] = useState<
    boolean | null
  >(null)
  const [isPickerVisible, setPickerVisible] = useState(false)

  useEffect(() => {
    if (prevIsOverlayVisible === true && !isOverlayVisible) {
      setPickerVisible(false)
    }

    if (isOverlayVisible && prevIsOverlayVisible === null) {
      setPrevOverlayVisible(true)
    }
  }, [prevIsOverlayVisible, isOverlayVisible])

  const showPicker = () => {
    overlayActions?.show()
    setPickerVisible(true)
  }

  const isRgbChanged = (newColor: RGBColor) => {
    const { r, g, b } = colorObj
    return newColor.r !== r || newColor.g !== g || newColor.b !== b
  }

  const handleChangeComplete = (newColor: ColorResult) => {
    const rgb = { ...newColor.rgb }
    const prevA = colorObj.a

    if (isRgbChanged(rgb) && prevA === 0) {
      rgb.a = 1
    }
    update(colorUtils.getColorAsString(rgb))
  }

  return (
    <SmallColorPickerUi>
      <ColorPreviewUi>
        <ColorPreviewInnerUi color={color} onClick={showPicker} />
      </ColorPreviewUi>
      <ColorHexUi>{colorUtils.RGBAToHexA(color)}</ColorHexUi>
      {isPickerVisible && isOverlayVisible && (
        <PickerPopupUi>
          <SketchPicker
            color={colorObj}
            disableAlpha
            onChangeComplete={handleChangeComplete}
          />
        </PickerPopupUi>
      )}
    </SmallColorPickerUi>
  )
}

export default SmallColorPicker
