import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import LanguageSwitcherItemUi from './ui/LanguageSwitcherItemUi'
import LanguageSwitcherUi from './ui/LanguageSwitcherUi'

enum Locales {
  En = 'en',
  Fr = 'fr',
  Es = 'es',
  It = 'it',
  Pt = 'pt',
  Ja = 'ja',
  Ar = 'ar',
  Tr = 'tr',
  Zh = 'zh',
  Nl = 'nl',
  Ru = 'ru',
  De = 'de',

  SV = 'sv',
  RO = 'ro',
  CS = 'cs',
  HU = 'hu',
  SK = 'sk',

  DA = 'da',
  ID = 'id',
  PL = 'pl',
  EL = 'el',
  SR = 'sr',
  NO = 'no',
  TH = 'th',
  SL = 'sl',
  UK = 'uk',
  SQ = 'sq',
  HI = 'hi',
}

// export const localeNames = {
//   [localeValues.en]: 'english',
//   [localeValues.fr]: 'french',
//   [localeValues.es]: 'spanish',
//   [localeValues.it]: 'italian',
//   [localeValues.pt]: 'portuguese',
// }

type LanguageSwitcherProps = {
  locales: string[]
}

function LanguageSwitcher({ locales }: LanguageSwitcherProps) {
  const [visible, setVisible] = useState(false)
  useEffect(() => {
    const searchParams = new URLSearchParams(document.location.search)
    const debug = searchParams.get('s.io_debug')
    if (debug) {
      setVisible(true)
    }
  }, [])

  const { i18n } = useTranslation()
  const changeLanguage = (lng: string) => () => {
    i18n.changeLanguage(lng)
  }

  if (!visible) {
    return <LanguageSwitcherUi />
  }
  return (
    <LanguageSwitcherUi>
      {locales.map(locale => (
        <LanguageSwitcherItemUi
          key={locale}
          onClick={changeLanguage(locale)}
          isActive={false}
        >
          {locale}
        </LanguageSwitcherItemUi>
      ))}
    </LanguageSwitcherUi>
  )
}

LanguageSwitcher.defaultProps = {
  locales: Object.values(Locales),
}

export default LanguageSwitcher
