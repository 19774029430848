import React from 'react'
import Select from 'react-select'
import Option from '../../components/Option'
import {
  fontsKeys,
  fontLabels,
  fontsValues,
  findFontKeyByValue,
} from '@sio/ui/build/utils/fontUtils'

const options = Object.keys(fontsKeys).map(fontKey => ({
  value: fontsValues[fontKey],
  label: fontLabels[fontKey],
}))

type FontFamilyProps = {
  label?: string
  update: (value: string) => void
  value?: string
}

const getFontOption = (fontKey: string) => ({
  value: fontsValues[fontKey],
  label: fontLabels[fontKey],
})

function FontFamily({
  label = 'settings_options.font_family.label',
  update,
  value,
}: FontFamilyProps) {
  const fontKey = value && findFontKeyByValue(value)

  return (
    <Option label={label}>
      <Select
        value={fontKey ? getFontOption(fontKey) : undefined}
        options={options}
        onChange={option => {
          if (option) {
            update(option.value)
          }
        }}
        styles={{
          singleValue: (styles, { data }) => ({
            ...styles,
            fontFamily: data.value,
          }),
          option: (styles, { data }) => ({
            ...styles,
            fontFamily: data.value,
          }),
        }}
      />
    </Option>
  )
}

export default FontFamily
