import { BaseEntityInterface, EntityTypes, ImageInterface } from '@sio/ui'

export const removeEmptyImageEntities = (entities: BaseEntityInterface[]) => {
  const removedIds: string[] = []
  return entities
    .filter(entity => {
      if (entity.type === EntityTypes.Image) {
        const imageEntity = entity as ImageInterface
        if (typeof imageEntity.width === 'number' && imageEntity.width < 5) {
          removedIds.push(imageEntity.id)
          return false
        }
      }
      return true
    })
    .map(entity => {
      if (entity.childIds) {
        entity.childIds = entity.childIds.filter(id => !removedIds.includes(id))
      }
      return entity
    })
}
