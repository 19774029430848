import React from 'react'
import PopupLibraryItem from './PopupLibraryItem'
import { LibraryElementTypes } from '../../../enums/libraryElementTypes'
import PopupLibraryItemListUi from './ui/PopupLibraryItemListUi'

type SimpleElementsLibraryProps = {
  addEntity: (
    type: LibraryElementTypes,
  ) => (event: React.SyntheticEvent) => void
}

const SimpleElementsLibrary = ({ addEntity }: SimpleElementsLibraryProps) => {
  return (
    <PopupLibraryItemListUi>
      <PopupLibraryItem
        type={LibraryElementTypes.LibraryImage}
        handleClick={addEntity(LibraryElementTypes.LibraryImage)}
      />
      <PopupLibraryItem
        type={LibraryElementTypes.LibraryText}
        handleClick={addEntity(LibraryElementTypes.LibraryText)}
      />
      <PopupLibraryItem
        type={LibraryElementTypes.LibraryButton}
        handleClick={addEntity(LibraryElementTypes.LibraryButton)}
      />
      <PopupLibraryItem
        type={LibraryElementTypes.LibraryHorizontalLine}
        handleClick={addEntity(LibraryElementTypes.LibraryHorizontalLine)}
      />
    </PopupLibraryItemListUi>
  )
}

export default SimpleElementsLibrary
