import styled from 'styled-components/macro'

const OptionUi = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  justify-content: flex-start;
  align-items: start;
  padding: 15px 20px;
  border-bottom: 1px solid #dee3ec;
`

export default OptionUi
