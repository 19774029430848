import styled from 'styled-components/macro'

type EntityMoverUiProps = {
  backgroundColor: string
}

const EntityMoverUi = styled.div<EntityMoverUiProps>`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 5px;
  cursor: move;
  z-index: 40;
  display: flex;
  background-color: ${p => p.backgroundColor};
  padding: 5px;
  border-radius: 15px;
  > span {
    color: #ffffff;
  }
`

export default EntityMoverUi
