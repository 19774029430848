import React, { useEffect } from 'react'
import { ButtonInterface } from '@sio/ui'
import ColorPicker from '../options/ColorPicker'
import Border, { BorderBatchUpdater, BorderUpdater } from '../options/Border'
import BorderRadius from '../options/BorderRadius/Single'
import Align from '../options/Align'
import Padding, { PaddingsUpdater, PaddingUpdater } from '../options/Padding'
import Margin, { MarginsUpdater, MarginUpdater } from '../options/Margin'
import {
  useUpdateNestedProp,
  useUpdateNestedProps,
  useUpdateProp,
} from '../hooks/useUpdaters'
import TextField from '../components/TextField'
import FontFamily from '../options/FontFamily'
import Spacing from '../options/Spacing'
import FontSize from '../options/FontSize'
import FontWeightAndStyle from '../options/FontWeightAndStyle'

type ButtonSettingsProps = {
  entity: ButtonInterface
}

const ButtonSettings = ({ entity }: ButtonSettingsProps) => {
  const updateProp = useUpdateProp(entity)
  const updateNestedProp = useUpdateNestedProp(entity)
  const updateNestedProps = useUpdateNestedProps(entity)

  useEffect(() => {
    const width = detectWidth(entity.id)
    updateProp('outlookWidth')(width)
  }, [entity.text, entity.padding, entity.fontSize, entity.id, updateProp])

  function detectWidth(id: string) {
    const imageWrapper = document.querySelector(`#entity-${id} a`)
    return imageWrapper ? imageWrapper.getBoundingClientRect().width : 0
  }

  return (
    <>
      <TextField
        label="entity_settings.button.text.label"
        update={updateProp('text')}
        value={entity.text}
      />
      <TextField
        label="entity_settings.button.url.label"
        update={updateProp('url')}
        value={entity.url}
      />
      <FontFamily update={updateProp('fontFamily')} value={entity.fontFamily} />
      <FontWeightAndStyle
        update={updateProp('fontWeightAndStyle')}
        value={entity.fontWeightAndStyle}
      />
      <FontSize update={updateProp('fontSize')} value={entity.fontSize} />
      <ColorPicker
        color={entity.color}
        label="global_settings.color.text"
        update={updateProp('color')}
      />
      <Align align={entity.align} update={updateProp('align')} />
      <Spacing
        lineHeight={entity.lineHeight}
        update={updateProp('lineHeight')}
      />
      <ColorPicker
        color={entity.backgroundColor}
        label="entity_settings.section.background_color.label"
        update={updateProp('backgroundColor')}
      />
      <Border
        style={entity.borderStyle}
        width={entity.borderWidth}
        color={entity.borderColor}
        update={updateNestedProp as BorderUpdater}
        batchUpdate={updateNestedProps as BorderBatchUpdater}
      />
      <BorderRadius
        borderRadius={entity.borderRadius}
        update={updateProp('borderRadius')}
      />
      <Padding
        padding={entity.padding}
        update={updateNestedProp('padding') as PaddingUpdater}
        updatePaddings={updateNestedProps('padding') as PaddingsUpdater}
      />
      <Margin
        margin={entity.margin}
        update={updateNestedProp('margin') as MarginUpdater}
        updateMargins={updateNestedProps('margin') as MarginsUpdater}
      />
    </>
  )
}

export default ButtonSettings
