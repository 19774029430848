import React from 'react'
import {
  BaseEntityInterface,
  BulletListInterface,
  ButtonInterface,
  ColumnInterface,
  EntityTypes,
  HorizontalLineInterface,
  ImageInterface,
  SectionInterface,
  TextInterface,
} from '@sio/ui'
import Section from '../components/entities/Section'
import Text from '../components/entities/Text'
import Image from '../components/entities/Image'
import Column from '../components/entities/Column'
import Button from '../components/entities/Button'
import BulletList from '../components/entities/BulletList'
import HorizontalLine from '../components/entities/HorizontalLine'

export default function createEntityComponent(
  entity: BaseEntityInterface,
  position: number,
  parentId: string,
) {
  switch (entity.type) {
    case EntityTypes.Image:
      return (
        <Image
          entity={entity as ImageInterface}
          key={entity.id}
          position={position}
          parentId={parentId}
        />
      )
    case EntityTypes.Section:
      return (
        <Section
          entity={entity as SectionInterface}
          key={entity.id}
          position={position}
          parentId={parentId}
        />
      )
    case EntityTypes.Text:
      return (
        <Text
          entity={entity as TextInterface}
          key={entity.id}
          position={position}
          parentId={parentId}
        />
      )
    case EntityTypes.Column:
      return <Column entity={entity as ColumnInterface} key={entity.id} />
    case EntityTypes.Button:
      return (
        <Button
          entity={entity as ButtonInterface}
          key={entity.id}
          position={position}
          parentId={parentId}
        />
      )
    case EntityTypes.BulletList:
      return (
        <BulletList
          entity={entity as BulletListInterface}
          key={entity.id}
          position={position}
          parentId={parentId}
        />
      )
    case EntityTypes.HorizontalLine:
      return (
        <HorizontalLine
          entity={entity as HorizontalLineInterface}
          key={entity.id}
          position={position}
          parentId={parentId}
        />
      )
    default:
      throw new Error(`Not found ${entity.type}`)
  }
}
