import React, { useRef } from 'react'
import { useDispatch } from 'react-redux'
import Body from '../../entities/Body'
import * as managementActions from '../../../store/management/actions'
import ContentUi from './ui/ContentUi'
import { managementSelectors, useManagement } from '../../../store'
import SkeletonContent from './SkeletonContent'
import MobilePhoneUi from './ui/MobilePhoneUi'
import DeviceWorkAreaUi from './ui/DeviceWorkAreaUi'
import ContentOverlay from '../ContentOverlay'
import ScrollContainerUi from './ui/ScrollContainerUi'
import {
  useFetchEmail,
  UseFetchEmailInterface,
} from '../../../hooks/use-fetch-email'

const Content = (emailProps: UseFetchEmailInterface) => {
  const contentRef = useRef<HTMLDivElement>(null!)
  const dispatch = useDispatch()
  const isFetching = useManagement(managementSelectors.isFetching)
  const isMobile = useManagement(managementSelectors.isMobile)
  const isSomethingEditing = useManagement(
    managementSelectors.isSomethingEditing,
  )

  useFetchEmail(emailProps)

  function disableMobileSettings(event: React.SyntheticEvent) {
    if (isMobile && isSomethingEditing && contentRef.current === event.target) {
      dispatch(managementActions.setEditingEntityId(null))
    }
  }

  return (
    <ContentUi ref={contentRef} onClick={disableMobileSettings}>
      <MobilePhoneUi isVisible={isMobile}>
        {isFetching ? (
          <SkeletonContent />
        ) : (
          <DeviceWorkAreaUi isMobile={isMobile}>
            <ScrollContainerUi>
              <Body />
            </ScrollContainerUi>
            <ContentOverlay />
          </DeviceWorkAreaUi>
        )}
      </MobilePhoneUi>
    </ContentUi>
  )
}

export default Content
