import axios from 'axios'
import { BadRequest } from '../errors/BadRequest'
import { NotFound } from '../errors/NotFound'
import { InternalError } from '../errors/InternalError'
import { MissingContentLengthError } from '../errors/MissingContentLengthError'

export const request = axios.create({
  baseURL: process.env.REACT_APP_API_HOST + '/dashboard/email-editor/api',
  withCredentials: true,
  headers: { 'X-Requested-With': 'XMLHttpRequest' },
})

export const fileUploadHttpClient = axios.create({
  baseURL: process.env.REACT_APP_API_HOST + '/dashboard/editor/api',
  withCredentials: true,
  headers: { 'X-Requested-With': 'XMLHttpRequest' },
})

// request.interceptors.response.use(({ data }: AxiosResponse) => data)
const fileUploadHttpClientErrorHandler = (error: any) => {
  if (error.response) {
    if (error.response.status === 400) {
      throw new BadRequest(error.response.data, error.response)
    } else if (error.response.status === 411) {
      throw new MissingContentLengthError(error.response)
    } else if (error.response.status === 401) {
      const loginWindow = window.open('', 'preview')
      if (loginWindow) {
        loginWindow.location.href = error.response.data.location
      } else {
        window.open(error.response.data.location, '_blank')
      }
    } else if (error.response.status === 404) {
      throw new NotFound()
    } else if (error.response.status === 500) {
      throw new InternalError()
    }
  }
}

request.interceptors.response.use(
  response => response,
  error => {
    if (error.response) {
      if (error.response.status === 400) {
        throw new BadRequest(error.response.data, error.response)
      } else if (error.response.status === 401) {
        const loginWindow = window.open('', 'preview')
        if (loginWindow) {
          loginWindow.location.href = error.response.data.location
        } else {
          window.open(error.response.data.location, '_blank')
        }
      } else if (error.response.status === 404) {
        throw new NotFound()
      } else if (error.response.status === 500) {
        throw new InternalError()
      }
    }
  },
)

fileUploadHttpClient.interceptors.response.use(
  response => response,
  fileUploadHttpClientErrorHandler,
)
// const mock = new MockAdapter(request, { delayResponse: 1000 })
//
// mock.onGet('/email/123/edit').reply(200, {
//   id: 123,
//   content: {
//     settings: {
//       maxWidth: 600,
//       backgroundColor: 'rgba(255, 255, 255, 0)',
//       contentBackgroundColor: 'rgba(255, 255, 255, 0)',
//       linkColor: 'rgba(255, 255, 255, 0)',
//       textFontSize: 14,
//       textColor: 'rgba(255, 255, 255, 0)',
//     },
//     entities: [
//       {
//         id: 'body-1',
//         type: EntityTypes.Body,
//         childIds: ['section-1', 'section-2', 'section-3'],
//       },
//       {
//         id: 'section-1',
//         type: EntityTypes.Section,
//         childIds: ['column-1'],
//         backgroundColor: 'rgb(145,127,127)',
//         padding: {
//           paddingTop: 10,
//           paddingRight: 10,
//           paddingBottom: 10,
//           paddingLeft: 10,
//         },
//       },
//       {
//         id: 'column-1',
//         type: EntityTypes.Column,
//         childIds: ['text-1', 'text-2'],
//         size: 12,
//         padding: {
//           paddingTop: 10,
//           paddingRight: 10,
//           paddingBottom: 10,
//           paddingLeft: 10,
//         },
//         backgroundColor: 'rgba(0, 0, 0, 0)',
//       },
//       {
//         id: 'text-1',
//         type: EntityTypes.Text,
//         rawContentState:
//           '{"entityMap":{},"blocks":[{"key":"7gjsu",' +
//           '"text":"Lorem ipsum dolor sit amet, consectetur adipisicing elit. Autem dolore, alias, ' +
//           'numquam enim ab voluptate id quam harum ducimus cupiditate similique quisquam et deserunt, ' +
//           'recusandae.","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}}]}',
//         fontSize: 14,
//         padding: {
//           paddingTop: 15,
//           paddingRight: 15,
//           paddingBottom: 15,
//           paddingLeft: 15,
//         },
//         marginTop: 0,
//         marginRight: 0,
//         marginBottom: 0,
//         marginLeft: 0,
//         textAlign: 'center',
//       },
//       {
//         id: 'text-2',
//         type: EntityTypes.Text,
//         rawContentState:
//           '{"entityMap":{},"blocks":[{"key":"7gjsu",' +
//           '"text":"Lorem ipsum dolor sit amet, consectetur adipisicing elit. Autem dolore, alias, ' +
//           'numquam enim ab voluptate id quam harum ducimus cupiditate similique quisquam et deserunt, ' +
//           'recusandae.","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}}]}',
//         fontSize: 14,
//         padding: {
//           paddingTop: 15,
//           paddingRight: 15,
//           paddingBottom: 15,
//           paddingLeft: 15,
//         },
//         marginTop: 0,
//         marginRight: 0,
//         marginBottom: 0,
//         marginLeft: 0,
//         textAlign: 'justify',
//       },
//       {
//         id: 'section-2',
//         type: EntityTypes.Section,
//         childIds: ['column-2', 'column-3'],
//         backgroundColor: 'rgb(47,134,179)',
//         padding: {
//           paddingTop: 10,
//           paddingRight: 10,
//           paddingBottom: 10,
//           paddingLeft: 10,
//         },
//       },
//       {
//         id: 'column-2',
//         type: EntityTypes.Column,
//         childIds: ['text-3'],
//         size: 6,
//         padding: {
//           paddingTop: 10,
//           paddingRight: 10,
//           paddingBottom: 10,
//           paddingLeft: 10,
//         },
//         backgroundColor: 'rgba(0, 0, 0, 0)',
//       },
//       {
//         id: 'column-3',
//         type: EntityTypes.Column,
//         childIds: ['text-4', 'button-1'],
//         size: 6,
//         padding: {
//           paddingTop: 10,
//           paddingRight: 10,
//           paddingBottom: 10,
//           paddingLeft: 10,
//         },
//         backgroundColor: 'rgba(0, 0, 0, 0)',
//       },
//       {
//         id: 'text-3',
//         type: EntityTypes.Text,
//         rawContentState:
//           '{"entityMap":{},"blocks":[{"key":"7gjsu",' +
//           '"text":"Lorem ipsum dolor sit amet, consectetur adipisicing elit. Autem dolore, alias, ' +
//           'numquam enim ab voluptate id quam harum ducimus cupiditate similique quisquam et deserunt, ' +
//           'recusandae.","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}}]}',
//         fontSize: 14,
//         padding: {
//           paddingTop: 15,
//           paddingRight: 15,
//           paddingBottom: 15,
//           paddingLeft: 15,
//         },
//         marginTop: 0,
//         marginRight: 0,
//         marginBottom: 0,
//         marginLeft: 0,
//         textAlign: 'left',
//       },
//       {
//         id: 'text-4',
//         type: EntityTypes.Text,
//         rawContentState:
//           '{"entityMap":{},"blocks":[{"key":"7gjsu",' +
//           '"text":"Lorem ipsum dolor sit amet, consectetur adipisicing elit. Autem dolore, alias, ' +
//           'numquam enim ab voluptate id quam harum ducimus cupiditate similique quisquam et deserunt, ' +
//           'recusandae.","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}}]}',
//         fontSize: 14,
//         padding: {
//           paddingTop: 15,
//           paddingRight: 15,
//           paddingBottom: 15,
//           paddingLeft: 15,
//         },
//         marginTop: 0,
//         marginRight: 0,
//         marginBottom: 0,
//         marginLeft: 0,
//         textAlign: 'right',
//       },
//       {
//         id: 'button-1',
//         type: EntityTypes.Button,
//         text: 'I am a button!',
//         backgroundColor: 'rgb(1,185,241)',
//         borderWidth: {
//           borderTopWidth: 0,
//           borderRightWidth: 0,
//           borderBottomWidth: 0,
//           borderLeftWidth: 0,
//         },
//         borderColor: {
//           borderTopColor: 'rgba(0, 0, 0, 0)',
//           borderRightColor: 'rgba(0, 0, 0, 0)',
//           borderBottomColor: 'rgba(0, 0, 0, 0)',
//           borderLeftColor: 'rgba(0, 0, 0, 0)',
//         },
//         borderStyle: {
//           borderTopStyle: BorderStyleEnum.none,
//           borderRightStyle: BorderStyleEnum.none,
//           borderBottomStyle: BorderStyleEnum.none,
//           borderLeftStyle: BorderStyleEnum.none,
//         },
//         borderRadius: {
//           borderTopLeftRadius: 0,
//           borderTopRightRadius: 0,
//           borderBottomRightRadius: 0,
//           borderBottomLeftRadius: 0,
//         },
//         color: 'rgba(255, 255, 255, 1)',
//         padding: {
//           paddingTop: 15,
//           paddingRight: 15,
//           paddingBottom: 15,
//           paddingLeft: 15,
//         },
//         margin: {
//           marginTop: 0,
//           marginRight: 0,
//           marginBottom: 20,
//           marginLeft: 0,
//         },
//         align: 'center',
//       },
//       {
//         id: 'section-3',
//         type: EntityTypes.Section,
//         childIds: ['column-4'],
//         backgroundColor: 'rgb(255,255,255, 0)',
//         padding: {
//           paddingTop: 10,
//           paddingRight: 10,
//           paddingBottom: 10,
//           paddingLeft: 10,
//         },
//       },
//       {
//         id: 'column-4',
//         type: EntityTypes.Column,
//         childIds: ['image-1'],
//         size: 12,
//         padding: {
//           paddingTop: 10,
//           paddingRight: 10,
//           paddingBottom: 10,
//           paddingLeft: 10,
//         },
//         backgroundColor: 'rgba(0, 0, 0, 0)',
//       },
//       {
//         id: 'image-1',
//         type: EntityTypes.Image,
//         fileId: 1,
//       },
//     ],
//   },
//   files: [
//     {
//       id: 1,
//       size: 40000,
//       path: '/images/first_image.jpeg',
//       name: 'first_image.jpg',
//       createdAt: '2020-10-19T22:13:57+00:00',
//     },
//   ],
// })
//
// mock.onGet(/\/files\/lists\/user\/*/).reply(() => {
//   console.log(`--mocked files request--`)
//   return [
//     200,
//     {
//       files: Array.from(Array(9).keys()).map(id => ({
//         id: id + 1,
//         size: 40000,
//         path: '/images/first_image.jpeg',
//         name: 'first_image.jpeg',
//         createdAt: `2020-1${id}-19T22:13:57+00:00`,
//       })),
//       total: 100,
//     },
//   ]
// })
//
// mock.onPost('/files/list').reply(() => {
//   console.log('upload')
//   return [
//     200,
//     {
//       id: 11,
//       size: 40000,
//       path: '/images/first_image.jpeg',
//       name: 'first_image.jpeg',
//       createdAt: `2020-14-19T22:13:57+00:00`,
//     },
//   ]
// })
