import React from 'react'
import Option from '../../components/Option'
import TextAlignButtonUi from './ui/TextAlignButtonUi'
import { styleTypes } from '@sio/ui'

type TextAlignProps = {
  align: styleTypes.TextAlign
  update: (value: styleTypes.TextAlign) => void
  allowJustify?: boolean
}

function TextAlign({ align, update, allowJustify = false }: TextAlignProps) {
  return (
    <Option label="settings_options.text_align.label">
      <TextAlignButtonUi
        active={align === 'left'}
        onClick={() => update('left')}
      >
        <span className="fas fa-align-left" />
      </TextAlignButtonUi>
      <TextAlignButtonUi
        active={align === 'center'}
        onClick={() => update('center')}
      >
        <span className="fas fa-align-center" />
      </TextAlignButtonUi>
      {allowJustify && (
        <TextAlignButtonUi
          active={align === 'justify'}
          onClick={() => update('justify')}
        >
          <span className="fas fa-align-justify" />
        </TextAlignButtonUi>
      )}
      <TextAlignButtonUi
        active={align === 'right'}
        onClick={() => update('right')}
      >
        <span className="fas fa-align-right" />
      </TextAlignButtonUi>
    </Option>
  )
}

export default TextAlign
