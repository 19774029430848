import { emailActions, emailSelectors, useEmail } from '../store'
import React, { useEffect, useRef, useState } from 'react'
import database from '../db'
import { EmailsInterface } from '../db/tables/emails'
import { useDispatch } from 'react-redux'
import { deepCompare } from '../utils/deepCompare'
import ModalConfirmation from '../components/core/Modal/ModalConfirmation'

export const useUnsavedData = ({ notExecute }: { notExecute?: boolean }) => {
  const emailId = useEmail(emailSelectors.getId)
  const entities = useEmail(emailSelectors.getEntities)
  const files = useEmail(emailSelectors.getFiles)
  const settings = useEmail(emailSelectors.getSettings)
  const timerRef = useRef<NodeJS.Timeout | null>(null)
  const [needToShowModal, setNeedToShowModal] = useState(true)
  const [isOpen, setIsOpen] = useState(false)
  const dispatch = useDispatch()
  const [entitiesDb, setEntitiesDb] = useState<EmailsInterface['entities']>()
  const [filesDb, setFilesDb] = useState<EmailsInterface['files']>()
  const [settingsDb, setSettingsDb] = useState<EmailsInterface['settings']>()
  const [isStateReady, setIsStateReady] = useState(false)

  useEffect(() => {
    if (isStateReady || !emailId || notExecute) return
    setEntitiesDb(entities)
    setSettingsDb(settings)
    setFilesDb(files)
    setIsStateReady(true)
  }, [emailId, entities, files, isStateReady, notExecute, settings])

  useEffect(() => {
    if (!emailId || !needToShowModal || notExecute) return
    const checkDbRecord = async () => {
      const existingRecord = await database.emails
        .where('systeme_email_id')
        .equals(emailId)
        .first()
      if (!existingRecord) return
      const state = { entities, files, settings }
      const stateFromDb = {
        entities: existingRecord.entities,
        files: existingRecord.files,
        settings: existingRecord.settings,
      }
      if (!deepCompare(state, stateFromDb)) {
        setEntitiesDb(existingRecord.entities)
        setFilesDb(existingRecord.files)
        setSettingsDb(existingRecord.settings)
        setIsOpen(true)
      }
    }
    checkDbRecord()
    setNeedToShowModal(false)
  }, [emailId, entities, files, needToShowModal, notExecute, settings])

  useEffect(() => {
    if (!emailId || isOpen || !isStateReady || notExecute) return
    if (
      deepCompare(entities, entitiesDb) &&
      deepCompare(files, filesDb) &&
      deepCompare(settings, settingsDb)
    )
      return
    if (timerRef.current) {
      clearTimeout(timerRef.current)
    }
    timerRef.current = setTimeout(async () => {
      const existingRecord = await database.emails
        .where('systeme_email_id')
        .equals(emailId)
        .first()

      const addRecord = async () => {
        if (existingRecord) {
          await database.emails.put({
            ...existingRecord,
            entities,
            files,
            settings,
          })
        } else {
          const newRecord: EmailsInterface = {
            systeme_email_id: emailId,
            entities,
            files,
            settings,
          }
          await database.emails.add(newRecord)
        }
      }
      try {
        await addRecord()
      } catch {
        // No space in indexedDB
        const firstRecord = database.emails.offset(0)
        if (firstRecord) {
          await firstRecord.delete()
          await addRecord()
        }
      }
    }, 3000)
    return () => clearTimeout(timerRef.current || 0)
  }, [
    emailId,
    entities,
    entitiesDb,
    files,
    filesDb,
    isOpen,
    isStateReady,
    notExecute,
    settings,
    settingsDb,
  ])

  const onPasteEmail = () => {
    if (entitiesDb) {
      dispatch(emailActions.setEntities(entitiesDb))
    }
    if (filesDb) {
      dispatch(emailActions.setEmailFiles(filesDb))
    }
    if (settingsDb) {
      dispatch(emailActions.updateGlobalSettings(settingsDb))
    }
    setIsOpen(false)
  }

  const deleteDbRecord = async () => {
    const existingRecord = database.emails
      .where('systeme_email_id')
      .equals(emailId)
    if (existingRecord) {
      await existingRecord.delete()
    }
    setEntitiesDb(entities)
    setFilesDb(files)
    setSettingsDb(settings)
  }

  const onClose = () => {
    deleteDbRecord()
    setIsOpen(false)
  }

  const EmailUnsavedModal = (
    <ModalConfirmation
      title={'core.unsaved_changes.confirmation'}
      isOpen={isOpen}
      handleClose={onClose}
      handleExit={onClose}
      handleSave={onPasteEmail}
    />
  )
  return { EmailUnsavedModal, deleteDbRecord }
}
