import React, { useCallback, useRef } from 'react'
import { ConnectDropTarget } from 'react-dnd'

export default function useDropRef(
  connectDrop: ConnectDropTarget,
  onDragOver: (ev: React.DragEvent) => any,
) {
  const ref = useRef<HTMLElement | null>(null)
  const setRef = useCallback(
    node => {
      if (ref.current) {
        // Make sure to cleanup any events/references added to the last instance
        // @ts-ignore
        ref.current.removeEventListener('dragover', onDragOver, false)
      }
      if (node) {
        // Check if a node is actually passed. Otherwise node would be null.
        // You can now do what you need to, addEventListeners, measure, etc.
        connectDrop(node)
        node.addEventListener('dragover', onDragOver, false)
      }

      // Save a reference to the node
      ref.current = node
    },
    [connectDrop, onDragOver],
  )

  return [setRef]
}
