import styled from 'styled-components/macro'

const FileUploadInputUi = styled.input`
  width: 100%;
  position: absolute;
  left: 0;
  padding-right: 70px;
  outline: none;
  float: left;
  height: 42px;
  font-size: 14px;
  border-radius: 3px;
  color: var(--default-text-color);
  border: solid 1px #ebedf0;
  text-indent: 10px;
  text-overflow: ellipsis;
`

export default FileUploadInputUi
