import { pathToRegexp } from 'path-to-regexp'

export default function getIdFromUrl() {
  const checkPattern = (uri: string) =>
    pathToRegexp(uri, []).exec(window.location.pathname)
  const mailingIdMatch = checkPattern('/mailing/:id/edit')
  let id
  if (mailingIdMatch && mailingIdMatch[1] && Number.isInteger(+mailingIdMatch[1])) {
    id = Number(mailingIdMatch[1])
  } else {
    const templateEmailIdMatch = checkPattern('/email/template/:id/edit')
    if (
      templateEmailIdMatch &&
      templateEmailIdMatch[1] &&
      Number.isInteger(+templateEmailIdMatch[1])
    ) {
      id = Number(templateEmailIdMatch[1])
    }
  }

  return id
}
