import styled from 'styled-components/macro'

const NumberInputUi = styled.input.attrs({
  type: 'text',
})`
  color: var(--default-text-color);
  font-size: 14px;
  background-color: var(--settings-option-item-background-color);
  border: none;
  padding: 6px;
  display: inline-block;
  width: 40px;
  margin-bottom: 0;
  line-height: 18px;
  text-align: center;
  vertical-align: middle;
  height: 32px;
  outline-color: var(--default-blue-color);
`

export default NumberInputUi
