import { Table } from 'dexie'
import { BaseEntityInterface, fileTypes, Settings } from '@sio/ui'

export interface EmailsInterface {
  id?: number
  systeme_email_id: number
  entities: BaseEntityInterface[]
  files: fileTypes.File[]
  settings: Settings
}

export type EmailsTableType = {
  emails: Table<EmailsInterface>
}

export const emailsSchema = {
  emails: '++id, systeme_email_id, entities, files, settings',
}
