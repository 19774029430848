import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { fileTypes } from '@sio/ui'
import {
  FileManager as SioFileManager,
  FileManagerHeader as SioFileManagerHeader,
} from '@sio/file-manager'
import * as filesApi from '../../../api/filesApi'
import { RawModal, ModalClose } from '../Modal'
import { filesActions, emailActions } from '../../../store'
import { useIsTemplateContext } from '../../../context/isTemplateContext'
import { BadRequest } from 'editor/src/errors/BadRequest'
import { InternalError } from 'editor/src/errors/InternalError'
import { NetworkError } from 'editor/src/errors/NetworkError'
import { MissingContentLengthError } from 'editor/src/errors/MissingContentLengthError'
import { mimeTypes, validateMimeTypes } from '@sio/file-manager'
import { useRollbar } from '@rollbar/react'

type FileManagerProps = {
  close: () => void
  chooseFile: (file: fileTypes.File) => void
  mimeTypesGroup: string
}

const FileManager = ({
  mimeTypesGroup,
  close,
  chooseFile,
}: FileManagerProps) => {
  const isTemplate = useIsTemplateContext()
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const [files, setFiles] = useState<fileTypes.File[]>([])
  const [totalFiles, setTotalFiles] = useState(0)
  // const [/*loaded,*/ setLoaded] = useState(0)
  const [errors, setErrors] = useState<string[]>([])
  function progressHandler({ total }: ProgressEvent) {
    // setTotal(total)
    // setLoaded(loaded)
  }

  const rollbar = useRollbar()

  const acceptableMimeTypes = mimeTypes[mimeTypesGroup].join(',')

  const fetchFilesByPage = React.useCallback(
    async (page?: number) => {
      const { data } = await filesApi.fetchFiles(
        isTemplate,
        mimeTypesGroup,
        page,
      )
      setTotalFiles(data.total)
      // dispatch(filesActions.loadFiles(data.files))
      setFiles(data.files)
    },
    [isTemplate, mimeTypesGroup],
  )

  useEffect(() => {
    ;(async function () {
      try {
        if (files.length === 0) {
          await fetchFilesByPage()
        }
      } catch (error) {
        // todo need a generic way to handle these errors
      }
    })()
  }, [fetchFilesByPage, files.length])

  async function removeFile(removableFile: fileTypes.File) {
    try {
      await filesApi.removeFile(removableFile.id)
      setFiles(files => files.filter(file => file.id !== removableFile.id))
      dispatch(filesActions.removeFile(removableFile))
      dispatch(emailActions.removePossibleFileUsage(removableFile))
    } catch (error) {
      if (error instanceof NetworkError) {
        setErrors([t('core.errors.no_connection')])
        //do nothing
      } else if (error instanceof InternalError) {
        rollbar.error('Internal server error')
        alert(t('core.errors.something_went_wrong'))
        return
      }
    }
  }

  async function uploadFile(file: File) {
    // debug https://app.rollbar.com/a/systemeio/fix/item/Email-editor/720
    if (!file) {
      rollbar.debug('call upload file without a file')
      return
    }
    // debug https://app.rollbar.com/a/systemeio/fix/item/Email-editor/720
    if (!file.type) {
      rollbar.debug('No file type', {
        file: JSON.stringify(file),
      })
      return
    }

    if (validateMimeTypes(mimeTypesGroup, file.type)) {
      setErrors([])
    } else {
      setErrors([t('core_file_manager.upload.file_type_is_not_allowed')])
      return
    }
    try {
      const {
        data: { uploadOptions, id },
      } = await filesApi.validateAndGetUploadOptions(file, isTemplate)

      await filesApi.uploadFile(file, uploadOptions, progressHandler)
      const { data } = await filesApi.activateFile(id)
      setFiles([data, ...files.slice(0, -1)])
      setTotalFiles(totalFiles + 1)
    } catch (error) {
      if (error instanceof BadRequest) {
        if (error.response.data.mimeType) {
          setErrors([error.response.data.mimeType])
        } else {
          rollbar.error('Upload to S3 failed 400', {
            data: error.response.data,
          })
          setErrors([t('core.file_manager.upload.network_error')])
        }
      } else if (error instanceof NetworkError) {
        setErrors([t('core.errors.no_connection')])
        //do nothing
      } else if (error instanceof InternalError) {
        rollbar.error('Internal server error')
        alert('Something went wrong. Please contact support')
        return
      } else if (error instanceof MissingContentLengthError) {
        if (error.response.data) {
          rollbar.error('Upload to S3 failed 411', {
            data: error.response.data,
          })
          setErrors([t('core.file_manager.upload.blocked')])
        }
      }
    }
  }

  return (
    <RawModal
      header={t('core.file_manager.title')}
      handleClose={close}
      maxWidth={'100%'}
      height="100%"
      isOpen
    >
      <SioFileManager
        files={files}
        chooseFile={chooseFile}
        total={totalFiles}
        onChangePage={fetchFilesByPage}
        onRemoveFile={removeFile}
      >
        <SioFileManagerHeader
          errors={errors}
          accept={acceptableMimeTypes}
          onFileUpload={uploadFile}
        >
          <ModalClose handleClose={close} />
        </SioFileManagerHeader>
      </SioFileManager>
    </RawModal>
  )
}

export default FileManager
