import styled from 'styled-components/macro'

const MenuLinkInputUi = styled.input`
  background-color: rgba(255, 255, 255, 0.1);
  border-radius: 3px;
  color: #fff;
  &::placeholder {
    color: #fff;
  }
  text-indent: 5px;
  width: 170px;
  font-size: 13px;
  outline: none;
  border: none;
  height: 21px;
  margin-left: 2px;
`

export default MenuLinkInputUi
