import {
  ADD_EMAIL_FILE,
  ADD_ENTITIES,
  ADD_ENTITY,
  ADD_SECTION,
  COPY_ENTITY,
  EmailActionTypes,
  LOAD_CONTACT_FIELDS,
  LOAD_EMAIL,
  MOVE_ENTITY,
  REMOVE_EMAIL_FILE,
  REMOVE_ENTITY,
  REMOVE_POSSIBLE_FILE_USAGE,
  SET_EMAIL_FILES,
  SET_ENTITIES,
  UPDATE_ENTITY,
  UPDATE_GLOBAL_SETTINGS,
  UPDATE_HASH,
} from './actionTypes'
import {
  BaseEntityInterface,
  EmailState,
  EntityTypes,
  fileTypes,
  Settings,
} from '@sio/ui'
import * as utils from './utils'
import md5 from 'md5'

const initialState: EmailState = {
  id: undefined,
  entities: [],
  settings: {},
  files: [],
  hash: '',
  contactFields: [],
}

function moveChildId(
  childIds: string[] = [],
  oldPosition: number,
  position: number,
) {
  const element = childIds[oldPosition]
  childIds.splice(oldPosition, 1)
  childIds.splice(position, 0, element)
  return childIds
}

function removeChildId(childIds: string[], entityId: string) {
  return childIds.filter(childId => childId !== entityId)
}

function addEntity(
  entities: BaseEntityInterface[],
  newEntity: BaseEntityInterface,
  parentId: string,
  position: number,
): BaseEntityInterface[] {
  return entities.reduce(
    (acc, entity) => {
      if (entity.id === parentId) {
        const parentEntity = {
          ...entity,
          childIds: utils.insertChildId(
            entity.childIds,
            newEntity.id,
            position,
          ),
        }
        return [...acc, parentEntity]
      }

      return [...acc, entity]
    },
    [newEntity],
  )
}

function addEntities(
  entities: BaseEntityInterface[],
  newEntities: BaseEntityInterface[],
  ascendantId: string,
  parentId: string,
  position: number,
): BaseEntityInterface[] {
  return entities
    .reduce((acc, entity) => {
      if (entity.id === parentId && entity.childIds) {
        const parentEntity = {
          ...entity,
          childIds: utils.insertChildId(entity.childIds, ascendantId, position),
        }
        return [...acc, parentEntity]
      }

      return [...acc, entity]
    }, [] as BaseEntityInterface[])
    .concat(newEntities)
}

function moveEntity(
  entities: BaseEntityInterface[],
  movableEntityId: string,
  toId: string,
  position: number,
): BaseEntityInterface[] {
  return entities.reduce((acc, entity) => {
    if (!entity.childIds) {
      return [...acc, entity]
    }

    // move inside current entity, just changing a position
    if (entity.childIds.includes(movableEntityId) && entity.id === toId) {
      return [
        ...acc,
        {
          ...entity,
          childIds: moveChildId(
            entity.childIds,
            entity.childIds.indexOf(movableEntityId),
            position,
          ),
        },
      ]
    }

    if (entity.childIds.includes(movableEntityId)) {
      const oldParentEntity = {
        ...entity,
        childIds: removeChildId(entity.childIds, movableEntityId),
      }

      return [...acc, oldParentEntity]
    }

    if (entity.id === toId) {
      const newEntity = {
        ...entity,
        childIds: utils.insertChildId(
          entity.childIds,
          movableEntityId,
          position,
        ),
      }

      return [...acc, newEntity]
    }

    return [...acc, entity]
  }, [] as BaseEntityInterface[])
}

function removeEntity(
  entities: BaseEntityInterface[],
  removableEntityId: string,
) {
  const descendantsIds = utils.getAllDescendantsIds(entities, removableEntityId)

  return entities
    .filter(entity => !descendantsIds.includes(entity.id))
    .filter(entity => entity.id !== removableEntityId)
    .map(entity => {
      if (entity.childIds && entity.childIds.includes(removableEntityId)) {
        return {
          ...entity,
          childIds: removeChildId(entity.childIds, removableEntityId),
        }
      }
      return entity
    })
}

function addEmailFile(files: fileTypes.File[], newFile: fileTypes.File) {
  const exists = files.find(file => file.id === newFile.id)

  if (exists) {
    return files
  }

  return [...files, newFile]
}

function removeFile(files: fileTypes.File[], removableFileId: number) {
  return files.filter(file => file.id !== removableFileId)
}

function removePossibleFileUsage(
  entities: BaseEntityInterface[],
  file: fileTypes.File,
): BaseEntityInterface[] {
  return entities.map(entity => {
    if (entity.fileId && entity.fileId === file.id) {
      return {
        ...entity,
        fileId: null,
      }
    }
    return entity
  })
}

function removeFilesOfEntityAndItsDescendants(
  files: fileTypes.File[],
  entities: BaseEntityInterface[],
  entity: BaseEntityInterface,
) {
  // let updatedFiles = files
  // if (entity.fileId) {
  //   updatedFiles = removeFile(files, entity.fileId)
  // }
  //
  // descendants.forEach(descendantEntity => {
  //   if (descendantEntity.fileId) {
  //     updatedFiles = removeFile(updatedFiles, descendantEntity.fileId)
  //   }
  // })
  const descendants = utils.getAllDescendants(entities, entity.id)

  return [entity]
    .concat(descendants)
    .reduce(
      (acc, descendant) =>
        descendant.fileId ? removeFile(acc, descendant.fileId) : acc,
      files,
    )
}

function copyEntity(
  entities: BaseEntityInterface[],
  entityId: string,
): BaseEntityInterface[] {
  const indexedByOldIdsCopyEntities = utils.copyEntityAndItsDescendants(
    entities,
    entityId,
  )

  return entities
    .map(entity => {
      if (!entity.childIds) {
        return entity
      }

      const index = entity.childIds.indexOf(entityId)
      if (index > -1) {
        return {
          ...entity,
          childIds: utils.insertChildId(
            entity.childIds,
            indexedByOldIdsCopyEntities[entityId].id,
            index + 1,
          ),
        }
      }

      return entity
    })
    .concat(Object.values(indexedByOldIdsCopyEntities))
}

export function emailReducer(
  state = initialState,
  action: EmailActionTypes,
): EmailState {
  switch (action.type) {
    case ADD_ENTITY:
      return {
        ...state,
        entities: addEntity(
          state.entities,
          action.payload.entity,
          action.payload.parentId,
          action.payload.position,
        ),
      }
    case ADD_ENTITIES:
      return {
        ...state,
        entities: addEntities(
          state.entities,
          action.payload.entities,
          action.payload.ascendantId,
          action.payload.parentId,
          action.payload.position,
        ),
      }
    case SET_ENTITIES:
      return { ...state, entities: action.payload.entities }
    case ADD_SECTION:
      return { ...state, entities: [...state.entities, action.payload.entity] }
    case LOAD_EMAIL:
      return {
        ...state,
        id: action.payload.id,
        entities: action.payload.content.entities,
        settings: action.payload.content.settings,
        files: action.payload.files,
        hash: md5(JSON.stringify(action.payload.content.entities)),
      }
    case UPDATE_HASH:
      return {
        ...state,
        hash: md5(JSON.stringify(action.payload.entities)),
      }
    case LOAD_CONTACT_FIELDS:
      return {
        ...state,
        contactFields: action.payload,
      }
    case UPDATE_ENTITY:
      return {
        ...state,
        entities: utils.updateEntity(state.entities, action.payload),
      }
    case MOVE_ENTITY:
      return {
        ...state,
        entities: moveEntity(
          state.entities,
          action.payload.entityId,
          action.payload.toId,
          action.payload.position,
        ),
      }
    case REMOVE_ENTITY:
      return {
        ...state,
        entities: removeEntity(state.entities, action.payload.id),
        files: removeFilesOfEntityAndItsDescendants(
          state.files,
          state.entities,
          action.payload,
        ),
      }
    case COPY_ENTITY:
      return {
        ...state,
        entities: copyEntity(state.entities, action.payload.entityId),
      }
    case UPDATE_GLOBAL_SETTINGS:
      return {
        ...state,
        settings: action.payload,
      }
    case ADD_EMAIL_FILE:
      return {
        ...state,
        files: addEmailFile(state.files, action.payload),
      }
    case REMOVE_EMAIL_FILE:
      return {
        ...state,
        files: removeFile(state.files, action.payload.id),
      }
    case REMOVE_POSSIBLE_FILE_USAGE:
      return {
        ...state,
        files: removeFile(state.files, action.payload.id),
        entities: removePossibleFileUsage(state.entities, action.payload),
      }
    case SET_EMAIL_FILES:
      return { ...state, files: action.payload }
    default:
      return state
  }
}

function getRootEntity(state: EmailState) {
  return state.entities.find(entity => entity.type === EntityTypes.Body)
}

function getEntityById(state: EmailState, entityId: string) {
  return state.entities.find(entity => entity.id === entityId)
}

function getChildrenEntities(
  state: EmailState,
  childIds: string[],
): BaseEntityInterface[] {
  const childrenEntities: BaseEntityInterface[] = []
  childIds.forEach(childId => {
    const childEntity = state.entities.find(e => e.id === childId)
    if (childEntity) {
      childrenEntities.push(childEntity)
    }
  })
  return childrenEntities
}

function getSettings(state: EmailState) {
  return state.settings as Settings
}

function getLinkColor(state: EmailState) {
  return state.settings.linkColor
}

function getEntities(state: EmailState) {
  return state.entities
}

function getId(state: EmailState) {
  return state.id as number
}

function getFiles(state: EmailState) {
  return state.files
}

function getFileById(state: EmailState, fileId: number | null) {
  return state.files.find(file => file.id === fileId)
}

function isEmailModified(state: EmailState) {
  return md5(JSON.stringify(state.entities)) !== state.hash
}

function getContactFields(state: EmailState) {
  return state.contactFields
}

export const selectors = {
  getId,
  getRootEntity,
  getChildrenEntities,
  getEntityById,
  getSettings,
  getEntities,
  getFiles,
  getFileById,
  getLinkColor,
  isEmailModified,
  getContactFields,
}
