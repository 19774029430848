import React from 'react'
import { EditorState } from 'draft-js'
import StyleControls from './StyleControls'

const BLOCK_TYPES = [
  { label: 'UL', style: 'unordered-list-item' },
  { label: 'OL', style: 'ordered-list-item' },
]

type BlockStyleControlsProps = {
  editorState: EditorState
  toggleStyle: (value: any) => void
}

function BlockStyleControls({
  editorState,
  toggleStyle,
}: BlockStyleControlsProps) {
  const selection = editorState.getSelection()
  const blockType = editorState
    .getCurrentContent()
    .getBlockForKey(selection.getStartKey())
    .getType()

  return (
    <StyleControls
      checkActive={style => blockType === style}
      handleClick={toggleStyle}
      controls={BLOCK_TYPES}
    />
  )
}

export default BlockStyleControls
