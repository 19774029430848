import React from 'react'
import Select, { ValueType } from 'react-select'
import { ReplacementsEnum, replacementsTitles } from './ReplacementsEnum'
import { useTranslation } from 'react-i18next'
import { useEmail, emailSelectors } from '../../../../../store'

type ReplacementsProps = {
  onChange: (value: string) => void
}

const makeOption = (value: string, label: string) => ({
  value: `{${value}}`,
  label,
})

const customStyles = {
  input: (styles: any) => ({ ...styles, color: '#ffffff' }),
  placeholder: (styles: any) => ({ ...styles, color: '#ffffff' }),
  container: (styles: any) => ({ ...styles, width: 200 }),
  singleValue: (styles: any) => ({
    ...styles,
    color: '#ffffff',
    marginLeft: 0,

    // padding: '3px 5px',
    // borderRadius: '3px',
    // backgroundColor: 'rgba(0,0,0,0.1)',
  }),
  dropdownIndicator: (styles: any) => {
    const { padding, ...restStyles } = styles
    delete restStyles[':hover']
    return { ...restStyles, color: '#ffffff' }
  },
  indicatorSeparator: (styles: any) => ({ ...styles, display: 'none' }),
  menu: (styles: any) => {
    const { marginTop, borderRadius, ...restStyles } = styles
    return restStyles
  },
  menuList: (styles: any) => {
    const { paddingTop, paddingBottom, ...restStyles } = styles
    return restStyles
  },
  control: (styles: any) => {
    const {
      backgroundColor,
      minHeight,
      borderWidth,
      borderStyle,
      boxShadow,
      ...restStyles
    } = styles

    return { ...restStyles, cursor: 'pointer' }
  },
  option: (styles: any, state: any) => ({
    ...styles,
    color: !state.isSelected ? '#ffffff' : 'var(--default-text-color)',
    backgroundColor: state.isSelected ? '#ffffff' : 'var(--default-blue-color)',
    '&:hover': {
      backgroundColor: '#ffffff',
      color: 'var(--default-text-color)',
    },
  }),
  indicatorsContainer: (styles: any) => ({ ...styles, padding: '3px' }),
}

type Option = { label: string; value: string }

function Replacements({ onChange }: ReplacementsProps) {
  const { t } = useTranslation()
  const contactFields = useEmail(emailSelectors.getContactFields)
  const defaultOptions = Object.values(ReplacementsEnum).map(replacement =>
    makeOption(replacement, t(replacementsTitles[replacement])),
  )
  const contactFieldsOptions = contactFields.map(contactField =>
    makeOption(contactField.slug, contactField.name),
  )

  function handleChange(option: ValueType<Option, boolean>) {
    onChange((option as Option).value)
  }

  return (
    <Select
      styles={customStyles}
      value={makeOption(
        '',
        t('components.core.text_editor.replacements_menu.placeholder'),
      )}
      options={[...contactFieldsOptions, ...defaultOptions]}
      onChange={handleChange}
      placeholder={t(
        'components.core.text_editor.replacements_menu.placeholder',
      )}
    />
  )
}

export default Replacements
