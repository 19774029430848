import styled from 'styled-components/macro'

type EntityMenuItemUiProps = {
  backgroundColor: string
}

const EntityMenuItemUi = styled.div<EntityMenuItemUiProps>`
  padding: 5px;
  margin-right: 5px;
  cursor: pointer;
  height: 100%;
  display: flex;
  align-items: center;
  position: relative;
  z-index: 1;
  color: #fff;
  background-color: ${p => p.backgroundColor};
  border-radius: 15px;
  //font-family: var(--font-family);
  //&:hover {
  //  background-color: rgba(0, 0, 0, 0.1);
  //}
  //&:hover .tooltip-text {
  //  visibility: visible;
  //  opacity: 1;
  //}
`

export default EntityMenuItemUi
