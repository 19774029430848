import React from 'react'
import SelectUi from './ui/SelectUi'

type SelectProps = {
  choices: { [key: string]: string }
  update: (value: any) => void
  value: string
}

function Select({ update, choices, value }: SelectProps) {
  return (
    <SelectUi onChange={e => update(e.target.value)} value={value}>
      {Object.keys(choices).map(key => (
        <option key={key} value={key}>
          {choices[key]}
        </option>
      ))}
    </SelectUi>
  )
}

export default Select
