import React from 'react'
import { useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'
import {
  ButtonInterface,
  EntityTypes,
  HorizontalLineInterface,
  SectionInterface,
  TextInterface,
  ImageInterface,
  BulletListInterface,
} from '@sio/ui'
import * as managementActions from '../../../../store/management/actions'
import SectionSettings from './entities/SectionSettings'
import { emailSelectors, useEmail } from '../../../../store'
import TextSettings from './entities/TextSettings'
import SettingsTitleUi from './ui/SettingsTitleUi'
import SettingsBackUi from './ui/SettingsBackUi'
import SettingsContainerUi from './ui/SettingsContainerUi'
import ButtonSettings from './entities/ButtonSettings'
import ImageSettings from './entities/ImageSettings'
import {
  useOverlayActionsContext,
  useOverlayVisibilityContext,
} from './SettingsOverlayContext'
import Overlay from '../../Overlay'
import HorizontalLineSettings from './entities/HorizontalLineSettings'
import BulletListSettings from './entities/BulletListSettings'

type EntitySettingsProps = {
  entityId: string
}

const EntitySettings = ({ entityId }: EntitySettingsProps) => {
  const entity = useEmail(email =>
    emailSelectors.getEntityById(email, entityId),
  )

  const isOverlayVisible = useOverlayVisibilityContext()
  const overlayActions = useOverlayActionsContext()
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const closeSettings = () =>
    dispatch(managementActions.setEditingEntityId(null))

  const hideOverlay = () => {
    overlayActions?.close()
  }

  if (!entity) {
    return null
  }

  let entityComponent

  switch (entity.type) {
    case EntityTypes.Section:
      const section = entity as SectionInterface
      entityComponent = <SectionSettings entity={section} />
      break
    case EntityTypes.Text:
      const text = entity as TextInterface
      entityComponent = <TextSettings entity={text} />
      break
    case EntityTypes.Button:
      const button = entity as ButtonInterface
      entityComponent = <ButtonSettings entity={button} />
      break
    case EntityTypes.HorizontalLine:
      const horizontalLine = entity as HorizontalLineInterface
      entityComponent = <HorizontalLineSettings entity={horizontalLine} />
      break
    case EntityTypes.Image:
      const image = entity as ImageInterface
      entityComponent = <ImageSettings entity={image} />
      break
    case EntityTypes.BulletList:
      const bulletList = entity as BulletListInterface
      entityComponent = <BulletListSettings entity={bulletList} />
      break
    default:
      entityComponent = <div>{entity.type}</div>
  }

  return (
    <>
      <SettingsTitleUi>
        <SettingsBackUi className="fas fa-arrow-left" onClick={closeSettings} />
        {t(`entity_settings.${entity.type.toLowerCase()}.title`)}
      </SettingsTitleUi>

      <SettingsContainerUi>
        {entityComponent}
        {isOverlayVisible && <Overlay onClick={hideOverlay} />}
      </SettingsContainerUi>
    </>
  )
}

export default EntitySettings
