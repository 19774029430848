import {
  BaseEntityInterface,
  Email,
  EntitiesTypes,
  fileTypes,
  Settings,
} from '@sio/ui'
import { ContactField } from '@sio/ui/build/types/emailTypes'
import {
  ADD_EMAIL_FILE,
  ADD_ENTITIES,
  ADD_ENTITY,
  AddEmailFileAction,
  AddEntitiesAction,
  AddEntityAction,
  COPY_ENTITY,
  LOAD_CONTACT_FIELDS,
  LOAD_EMAIL,
  LoadContactFieldsAction,
  LoadEmailAction,
  MOVE_ENTITY,
  REMOVE_EMAIL_FILE,
  REMOVE_ENTITY,
  REMOVE_POSSIBLE_FILE_USAGE,
  RemoveEmailFileAction,
  RemoveEntityAction,
  RemovePossibleFileIdUsageAction,
  SET_EMAIL_FILES,
  SET_ENTITIES,
  SetEmailFilesAction,
  SetEntitiesAction,
  UPDATE_ENTITY,
  UPDATE_GLOBAL_SETTINGS,
  UPDATE_HASH,
  UpdateEntityAction,
  UpdateGlobalSettingsAction,
  UpdateHashAction,
} from './actionTypes'

export function addEntity(
  entity: EntitiesTypes,
  parentId: string,
  position: number,
): AddEntityAction {
  return {
    type: ADD_ENTITY,
    payload: { entity, parentId, position },
  }
}

export function updateHash(entities: EntitiesTypes[]): UpdateHashAction {
  return {
    type: UPDATE_HASH,
    payload: { entities },
  }
}

export function addEntities(
  entities: EntitiesTypes[],
  ascendantId: string,
  parentId: string,
  position: number,
): AddEntitiesAction {
  return {
    type: ADD_ENTITIES,
    payload: {
      entities,
      ascendantId,
      parentId,
      position,
    },
  }
}

export function moveEntity(entityId: string, toId: string, position: number) {
  return {
    type: MOVE_ENTITY,
    payload: {
      entityId,
      toId,
      position,
    },
  }
}

export function removeEntity(entity: BaseEntityInterface): RemoveEntityAction {
  return {
    type: REMOVE_ENTITY,
    payload: entity,
  }
}

export function copyEntity(entityId: string) {
  return {
    type: COPY_ENTITY,
    payload: { entityId },
  }
}

export function updateEntity<T>(entity: T): UpdateEntityAction<T> {
  return {
    type: UPDATE_ENTITY,
    payload: entity,
  }
}

export function setEntities(
  entities: BaseEntityInterface[],
): SetEntitiesAction {
  return { type: SET_ENTITIES, payload: { entities } }
}

export function loadEmail(email: Email): LoadEmailAction {
  return {
    type: LOAD_EMAIL,
    payload: email,
  }
}

export function loadContactFields(
  contactFields: ContactField[],
): LoadContactFieldsAction {
  return {
    type: LOAD_CONTACT_FIELDS,
    payload: contactFields,
  }
}

export function updateGlobalSettings(
  settings: Settings,
): UpdateGlobalSettingsAction {
  return {
    type: UPDATE_GLOBAL_SETTINGS,
    payload: settings,
  }
}

export function addEmailFile(file: fileTypes.File): AddEmailFileAction {
  return {
    type: ADD_EMAIL_FILE,
    payload: file,
  }
}

export function removeEmailFile(file: fileTypes.File): RemoveEmailFileAction {
  return {
    type: REMOVE_EMAIL_FILE,
    payload: file,
  }
}

export function setEmailFiles(files: fileTypes.File[]): SetEmailFilesAction {
  return { type: SET_EMAIL_FILES, payload: files }
}

export function removePossibleFileUsage(
  file: fileTypes.File,
): RemovePossibleFileIdUsageAction {
  return {
    type: REMOVE_POSSIBLE_FILE_USAGE,
    payload: file,
  }
}
