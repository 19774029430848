import React from 'react'
import MoreUi from './ui/MoreUi'
import Checkbox from '../Checkbox'
import { useTranslation } from 'react-i18next'

type MoreProps = {
  checked: boolean
  toggle: (event: React.ChangeEvent) => void
  label?: string
}

function More({
  checked,
  toggle,
  label = 'settings_options.more.label',
}: MoreProps) {
  const { t } = useTranslation()
  return (
    <MoreUi htmlFor="checkbox">
      <span>{t(label)}</span>
      <Checkbox checked={checked} toggle={toggle} />
    </MoreUi>
  )
}

export default More
