import React from 'react'
import { useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'
import * as managementActions from '../../../store/management/actions'
import HeaderButtonUi from './ui/HeaderButtonUi'
import { useTypedSelector, managementSelectors } from '../../../store'

type OpenSettingsProps = {}

const OpenSettings = (props: OpenSettingsProps) => {
  const { t } = useTranslation()
  const isSettingsEditing = useTypedSelector(state =>
    managementSelectors.isGlobalSettingsEditing(state.management),
  )
  const dispatch = useDispatch()
  function toggleSettings() {
    dispatch(managementActions.toggleGlobalSettingsEditing())
  }
  return (
    <HeaderButtonUi onClick={toggleSettings} active={isSettingsEditing}>
      {t('core.header.settings.title')}
    </HeaderButtonUi>
  )
}

export default OpenSettings
