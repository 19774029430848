import React from 'react'
import CheckboxWrapperUi from './ui/CheckboxWrapperUi'
import { CheckBoxLabel, CheckboxUi } from './ui/CheckboxUi'

type CheckboxProps = {
  checked?: boolean
  toggle: (event: React.ChangeEvent) => void
}

function Checkbox({ checked = false, toggle }: CheckboxProps) {
  return (
    <CheckboxWrapperUi>
      <CheckboxUi id="checkbox" checked={checked} onChange={toggle} />
      <CheckBoxLabel htmlFor="checkbox" />
    </CheckboxWrapperUi>
  )
}

export default Checkbox
