import { ColumnInterface, SectionInterface } from '@sio/ui'
import { LibraryElementTypes } from '../enums/libraryElementTypes'
import { createColumn } from '../components/entities/Column'
import { createSection } from '../components/entities/Section'

type CreateLayoutReturnType = {
  ascendantId: string
  entities: (SectionInterface | ColumnInterface)[]
}

function createLayout_12() {
  const section = createSection()
  const column = createColumn(12)
  section.childIds = [column.id]

  return {
    ascendantId: section.id,
    entities: [section, column],
  }
}

function createLayout_6_6(): CreateLayoutReturnType {
  const section = createSection()
  const column1 = createColumn(6)
  const column2 = createColumn(6)
  section.childIds = [column1.id, column2.id]

  return {
    ascendantId: section.id,
    entities: [section, column1, column2],
  }
}

function createLayout_4_4_4(): CreateLayoutReturnType {
  const section = createSection()
  const column1 = createColumn(4)
  const column2 = createColumn(4)
  const column3 = createColumn(4)
  section.childIds = [column1.id, column2.id, column3.id]

  return {
    ascendantId: section.id,
    entities: [section, column1, column2, column3],
  }
}

function createLayout_3_3_3_3(): CreateLayoutReturnType {
  const section = createSection()
  const column1 = createColumn(3)
  const column2 = createColumn(3)
  const column3 = createColumn(3)
  const column4 = createColumn(3)
  section.childIds = [column1.id, column2.id, column3.id, column4.id]

  return {
    ascendantId: section.id,
    entities: [section, column1, column2, column3, column4],
  }
}

export default function createLayout(type: LibraryElementTypes) {
  switch (type) {
    case LibraryElementTypes.LibraryLayout_3_3_3_3:
      return createLayout_3_3_3_3()
    case LibraryElementTypes.LibraryLayout_4_4_4:
      return createLayout_4_4_4()
    case LibraryElementTypes.LibraryLayout_6_6:
      return createLayout_6_6()
    case LibraryElementTypes.LibraryLayout_12:
      return createLayout_12()
    default:
      throw new Error(`Entity layout creator for type ${type} was not found`)
  }
}
