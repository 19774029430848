import styled from 'styled-components/macro'

const ScrollContainerUi = styled.div`
  overflow-y: scroll;
  height: 100%;
  scroll-behavior: smooth;
  font-family: initial;
`

export default ScrollContainerUi
