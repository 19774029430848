import React from 'react'
import Range from '../../components/Range'

type FontSizeProps = {
  label?: string
  update: (value: number) => void
  value: number
}

function FontSize({
  label = 'settings_options.font_size.label',
  update,
  value,
}: FontSizeProps) {
  return <Range label={label} change={update} value={value} min={5} />
}

export default FontSize
